import type { IdType, TableInstance } from 'react-table';
import type { CardProps } from './Card';

import * as React from 'react';
import { styled } from '@mend/mui-theme';
import { MenuItem, TextField } from '@mui/material';

const sortDirections = ['ASC', 'DESC'] as const;

type SortSelectProps<T extends Record<string, unknown>> = {
  instance: TableInstance<T>;
  /**
   * Required when the `Header` property of a column is not a string in order
   * to avoid weird rendering issues. Also used when we want to change the
   * display name of the header even if `Header` is a string.
   */
  sortLabels?: Record<IdType<T>, string>;
};

function SortSelect<T extends Record<string, unknown>>({
  instance: {
    columns,
    setSortBy,
    state: { sortBy },
  },
  sortLabels,
}: SortSelectProps<T>) {
  if (process.env.NODE_ENV !== 'production') {
    /**
     * We can disable the eslint rule here since the code will be stripped out
     * in the prod build and we don't want to spam the console on every render.
     */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      columns.forEach((column) => {
        if (typeof column.Header !== 'string' && !sortLabels?.[column.id]) {
          console.warn(
            `Could not render the sort by options for column \`${column.id}\` because its \`Header\` property is not a string, make sure to provide a \`sortLabels\` prop containing a key of \`${column.id}\` and a proper display value.`
          );
        }
      });
    }, [columns, sortLabels]);
  }

  const currentSort =
    sortBy[0] !== undefined
      ? `${sortBy[0].id} ${sortBy[0].desc ? 'DESC' : 'ASC'}`
      : '';

  const sortableColumns = columns.filter(
    (column) =>
      column.canSort &&
      (typeof column.Header === 'string'
        ? true
        : Boolean(sortLabels?.[column.id]))
  );

  return (
    <TextField
      select
      fullWidth
      size="small"
      label="Sort by"
      disabled={sortableColumns.length === 0}
      value={currentSort}
      onChange={(event) => {
        const [columnId, direction] = event.target.value.split(' ') as [
          IdType<T>,
          (typeof sortDirections)[number],
        ];
        setSortBy([{ id: columnId, desc: direction === 'DESC' }]);
      }}
    >
      {sortableColumns.map((column) =>
        sortDirections.map((direction) => {
          const value = `${column.id} ${direction}`;
          return (
            <MenuItem key={value} value={value}>
              {sortLabels?.[column.id] ?? column.render('Header')} {direction}
            </MenuItem>
          );
        })
      )}
    </TextField>
  );
}

const StyledList = styled('ol')(({ theme }) => ({
  margin: 0,
  marginTop: theme.spacing(2),
  padding: 0,
  listStyleType: 'none',
}));

const StyledListItem = styled('li')(({ theme }) => ({
  '&:not(:first-of-type)': {
    marginTop: theme.spacing(2),
  },
}));

export type ListProps<
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
> = SortSelectProps<T> &
  Pick<CardProps<T, P>, 'getCellRendererProps'> & {
    Card: (props: CardProps<T>) => JSX.Element;
  };

export default function List<
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
>({
  instance,
  sortLabels,
  getCellRendererProps,
  Card,
}: ListProps<T, P>): JSX.Element {
  const {
    page,
    rows,
    prepareRow,
    state: { sortBy },
  } = instance;

  const hasSorting = sortBy !== undefined;
  const actualRows = page !== undefined ? page : rows;

  return (
    <>
      {hasSorting && (
        <SortSelect<T> instance={instance} sortLabels={sortLabels} />
      )}

      <StyledList>
        {actualRows.map((row) => {
          prepareRow(row);
          const { key: rowKey } = row.getRowProps();
          return (
            <StyledListItem key={rowKey}>
              <Card
                row={row}
                getCellRendererProps={(cell) => ({
                  ...getCellRendererProps?.(cell),
                  isInCard: true,
                })}
              />
            </StyledListItem>
          );
        })}
      </StyledList>
    </>
  );
}
