import { Button, Typography } from '@mui/material';

import { config } from '#config.ts';
import * as Modal from './../Modal';

type SupportProps = {
  onClose: () => void;
};

export default function Support({ onClose }: SupportProps): JSX.Element {
  const handleContinueClick = () => {
    window.open(`${config.PORTAL_BASE_URL}/chat`, 'support');
    onClose();
  };

  return (
    <Modal.Root
      open
      onClose={onClose}
      maxWidth="sm"
      aria-labelledby="support-modal-title"
    >
      <Modal.Header>
        <Modal.Title id="support-modal-title">
          Opening Tech Support Chat
        </Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <Typography>
          You are about to open our tech support chat in a new tab. To return to
          this page, just come back to this tab after finishing your chat.
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Modal.DismissButton />
        <Button type="button" color="primary" onClick={handleContinueClick}>
          Continue to Tech Support
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}
