import type { DataControlsProps } from './DataControls';
import type { ListProps } from './List';
import type { SharedTableProps } from './Table';

import * as React from 'react';

import useElement from '#hooks/element';
import DataControls from './DataControls';
import List from './List';
import Table from './Table';

type ListDataTableProps<
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
> = Omit<DataControlsProps<T>, 'containerRef' | 'children' | 'forList'> &
  Omit<
    SharedTableProps<T, P>,
    'tableRef' | 'truncatedColumnsIds' | 'SubRowComponent'
  > &
  Pick<ListProps<T, P>, 'sortLabels' | 'Card'>;

export default function ListDataTable<
  T extends Record<string, unknown>,
  P extends Record<string, unknown> = Record<string, never>,
>({
  instance,
  status = 'resolved',
  headerAlignmentMapper,
  getHeaderRowProps,
  getHeaderCellProps,
  getRowProps,
  getCellProps,
  getCellRendererProps,
  sortLabels,
  Card,
  ...props
}: ListDataTableProps<T, P>): JSX.Element {
  const [containerElement, containerCallbackRef] = useElement<HTMLDivElement>();
  const [tableElement, tableCallbackRef] = useElement<HTMLTableElement>();
  const [tableTruncatesAt, setTableTruncatesAt] = React.useState<number | null>(
    null
  );

  const isSuccess = status === 'resolved' || status === 'success';

  React.useEffect(() => {
    if (!isSuccess || !containerElement || !tableTruncatesAt) return;
    const observer = new ResizeObserver(([container]) => {
      if (!container) return;
      if (container.contentRect.width > tableTruncatesAt) {
        setTableTruncatesAt(null);
      }
    });
    observer.observe(containerElement);
    return () => observer.disconnect();
  }, [isSuccess, containerElement, tableTruncatesAt]);

  React.useEffect(() => {
    if (!isSuccess || !containerElement || !tableElement) return;
    const observer = new ResizeObserver(([table]) => {
      if (!table) return;
      const containerWidth = containerElement.getBoundingClientRect().width;
      const tableWidth = table.contentRect.width;
      if (tableWidth > containerWidth) {
        setTableTruncatesAt(containerWidth);
      }
    });
    observer.observe(tableElement);
    return () => observer.disconnect();
  }, [isSuccess, containerElement, tableElement]);

  const renderAsList = tableTruncatesAt !== null;

  return (
    <DataControls
      containerRef={containerCallbackRef}
      instance={instance}
      status={status}
      forList={renderAsList}
      {...props}
    >
      {renderAsList ? (
        <List<T, P>
          instance={instance}
          sortLabels={sortLabels}
          getCellRendererProps={getCellRendererProps}
          Card={Card}
        />
      ) : (
        <Table<T, P>
          tableRef={tableCallbackRef}
          instance={instance}
          getHeaderRowProps={getHeaderRowProps}
          getHeaderCellProps={getHeaderCellProps}
          getRowProps={getRowProps}
          getCellProps={getCellProps}
          getCellRendererProps={getCellRendererProps}
          headerAlignmentMapper={headerAlignmentMapper}
        />
      )}
    </DataControls>
  );
}
