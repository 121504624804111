import type { Appointment } from '#types/appointment.ts';

import { assign, fromPromise, raise, setup } from 'xstate';

import {
  getAppointment,
  upsertAppointmentNote,
} from '#services/api/appointment.ts';

type Note = Parameters<typeof upsertAppointmentNote>[1];

function getSingleNoteFromAppointmentNotes(note: Note | undefined): Note {
  if (!note) {
    return { content: '' };
  }

  return {
    id: note.id,
    content: note.content,
  };
}

export const notesMachine = setup({
  types: {
    input: {} as {
      appointmentId: number;
      note: Note | undefined;
    },
    context: {} as {
      appointmentId: number;
      /**
       * The local copy of the appointment's notes.
       */
      note: Note;
    },
    events: {} as
      | { type: 'note.changed'; value: string }
      | { type: 'appointment.updated'; appointment: Appointment },
  },
  actions: {
    updateAppointmentData: () => void 0,
    updateLocalNote: assign(({ context, event }) => ({
      note:
        event.type === 'note.changed'
          ? { ...context.note, content: event.value }
          : context.note,
    })),
  },
  actors: {
    fetchAppointment: fromPromise<Appointment, { appointmentId: number }>(
      ({ input }) => getAppointment(input.appointmentId)
    ),
    saveNote: fromPromise<Appointment, { appointmentId: number; note: Note }>(
      ({ input }) => upsertAppointmentNote(input.appointmentId, input.note)
    ),
  },
  delays: {
    fetchAppointment: 25_000,
    saveNotes: 2_000,
  },
}).createMachine({
  context: ({ input }) => ({
    appointmentId: input.appointmentId,
    note: getSingleNoteFromAppointmentNotes(input.note),
  }),
  initial: 'pending',
  on: {
    'appointment.updated': {
      actions: 'updateAppointmentData',
    },
  },
  states: {
    pending: {
      on: {
        'note.changed': {
          actions: 'updateLocalNote',
          target: 'typing',
        },
      },
      after: {
        fetchAppointment: 'fetchingAppointment',
      },
    },
    fetchingAppointment: {
      invoke: {
        src: 'fetchAppointment',
        input: ({ context }) => ({
          appointmentId: context.appointmentId,
        }),
        onDone: {
          actions: [
            assign(({ event }) => ({
              note: getSingleNoteFromAppointmentNotes(event.output.notes?.[0]),
            })),
            raise(({ event }) => ({
              type: 'appointment.updated',
              appointment: event.output,
            })),
          ],
          target: 'pending',
        },
        onError: {
          actions: () => {
            // TODO - Implement UI feedback
            console.error('Failed to fetch appointment.');
          },
          target: 'pending',
        },
      },
    },
    typing: {
      on: {
        'note.changed': {
          actions: 'updateLocalNote',
          target: 'typing',
          reenter: true,
        },
      },
      after: {
        saveNotes: 'savingNotes',
      },
    },
    /**
     * TODO: When transitioning from this state to `typing` and the appointment
     * doesn't have any initial notes, we should block the user from making
     * further changes until we've saved the initial note.
     */
    savingNotes: {
      invoke: {
        src: 'saveNote',
        input: ({ context }) => ({
          appointmentId: context.appointmentId,
          note: context.note,
        }),
        onDone: {
          actions: [
            assign(({ event }) => ({
              note: getSingleNoteFromAppointmentNotes(event.output.notes?.[0]),
            })),
            raise(({ event }) => ({
              type: 'appointment.updated',
              appointment: event.output,
            })),
          ],
          target: 'pending',
        },
        onError: {
          actions: () => {
            // TODO - Implement UI feedback
            console.error('Failed to save notes.');
          },
          target: 'pending',
        },
      },
    },
  },
});
