import type { EventMap } from '#types/pusher.ts';

import { useEffect, useLayoutEffect, useRef } from 'react';

import { usePusherService } from '#hooks/pusher-service.ts';

export function usePusherOrgStaffChannel<T extends keyof EventMap>(
  event: T,
  callback: (message: EventMap[T]) => void
) {
  const { orgStaffChannel } = usePusherService();

  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    if (!orgStaffChannel) {
      return;
    }

    function handler(message: EventMap[T]) {
      callbackRef.current(message);
    }

    orgStaffChannel.bind(event, handler);

    return () => {
      orgStaffChannel.unbind(event, handler);
    };
  }, [event, orgStaffChannel]);
}
