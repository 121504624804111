import { config } from '#config';

/**
 * No user should ever be able to land in the home page directly
 * for the initial release.
 */
export function loader() {
  window.location.replace(`${config.PORTAL_BASE_URL}/dashboard`);
  return null;
}
