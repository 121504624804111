import type { SxProps } from '@mui/material';
import type { CountryCode, PhoneNumber } from 'libphonenumber-js/min';
import type { Countries } from '#utils/countries.ts';

import * as React from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  FormGroup,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import examples from 'libphonenumber-js/examples.mobile.json';
import {
  getCountryCallingCode,
  getExampleNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js/min';
import ReactCountryFlag from 'react-country-flag';

import useAnchorElement from '#hooks/anchor-element.ts';
import { countries } from '#utils/countries.ts';

interface PhoneInputProps {
  labelText?: string;
  value?: string;
  permittedCountries?: CountryCode[];
  onPhoneNumberChanged: (phoneNumber: PhoneNumber | null) => void;
  sx?: SxProps;
}

const flagStyles = {
  borderRadius: '2px',
  height: '16px',
  width: '25px',
};

/**
 * Filters the passed in countries to only include the permitted countries.
 */
function filterCountries(
  permittedCountries?: CountryCode[],
  allCountries: Countries = countries
): Countries {
  let result = allCountries;

  if (permittedCountries && permittedCountries.length > 0) {
    result = Object.keys(allCountries).reduce((acc, key) => {
      if (permittedCountries.includes(key as CountryCode)) {
        acc[key as CountryCode] = countries[key as CountryCode];
      }
      return acc;
    }, {} as Countries);
  }

  return result;
}

export function PhoneInput({
  labelText = 'Phone Number',
  value = '',
  permittedCountries,
  onPhoneNumberChanged,
  sx,
}: PhoneInputProps) {
  const { anchor, setAnchor, unsetAnchor, open } = useAnchorElement();
  const [country, setCountry] = React.useState<CountryCode | null>(
    () => parsePhoneNumberFromString(value, 'US')?.country ?? 'US'
  );
  const [inputValue, setInputValue] = React.useState(
    () => parsePhoneNumberFromString(value, 'US')?.formatNational() ?? value
  );
  const [isValid, setIsValid] = React.useState(() => {
    // Consider valid until value is provided
    if (!value) {
      return true;
    }

    // Strip all non-numeric characters and the leading plus sign out of the number
    const cleaned = value.replace(/[^0-9+]/g, '');
    // Attempt to parse phone number from the cleaned string
    const updatedNumber = parsePhoneNumberFromString(cleaned, 'US') ?? null;

    return isValidNumber(updatedNumber);
  });

  const availableCountries = filterCountries(permittedCountries, countries);
  const availableCountryCodes = Object.keys(
    availableCountries
  ) as CountryCode[];

  const isValidNumber = (phoneNumber: PhoneNumber | null) => {
    return !!(
      phoneNumber &&
      phoneNumber.isValid() &&
      phoneNumber?.country &&
      availableCountryCodes.includes(phoneNumber.country)
    );
  };

  const onPhoneInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatePhoneNumber(event.target.value);
  };

  const onCountryChange = (countryData: CountryCode) => {
    setCountry(countryData);
    updatePhoneNumber(inputValue, countryData);
  };

  const updatePhoneNumber = (
    value: string,
    countryCode: CountryCode = country ?? 'US'
  ) => {
    // Strip all non-numeric characters and the leading plus sign out of the number
    const cleaned = value.replace(/[^0-9+]/g, '');
    // Attempt to parse phone number from the cleaned string
    const updatedNumber =
      parsePhoneNumberFromString(cleaned, countryCode) ?? null;

    // Determine if the input value is a valid phone number according to the PhoneNumber class
    const valid = isValidNumber(updatedNumber);
    setIsValid(valid);
    if (updatedNumber && country !== updatedNumber?.country) {
      setCountry(updatedNumber?.country ?? null);
    }
    setInputValue(updatedNumber ? updatedNumber.formatNational() : value);
    // Notify parent of the change
    onPhoneNumberChanged(updatedNumber);
  };

  return (
    <FormGroup>
      <TextField
        label={labelText}
        sx={sx}
        fullWidth
        value={inputValue}
        onChange={onPhoneInputChanged}
        error={!isValid}
        helperText={!isValid ? 'Invalid number' : ''}
        // disabled={false} // TODO: Disable while call taking place???
        placeholder={getExampleNumber(
          country ?? 'US',
          examples
        )?.formatNational()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button
                variant="text"
                onClick={setAnchor}
                endIcon={<ArrowDropDown />}
                aria-label="Current phone flag"
                data-current-flag={country || 'ZZ'}
              >
                <ReactCountryFlag
                  countryCode={country || 'ZZ'}
                  svg
                  style={flagStyles}
                />
              </Button>
              <Menu
                id="tel-countries-select"
                anchorEl={anchor}
                open={open}
                onClose={unsetAnchor}
              >
                {availableCountryCodes.map((country) => (
                  <MenuItem
                    key={`tel-${country}`}
                    onClick={() => {
                      onCountryChange(country);
                      unsetAnchor();
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={country}
                      svg
                      style={flagStyles}
                    />
                    <Typography ml={1}>
                      {availableCountries[country]}
                    </Typography>
                    <Typography ml={1} variant="subtitle1" color={grey[500]}>
                      +{getCountryCallingCode(country)}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </InputAdornment>
          ),
        }}
      />
    </FormGroup>
  );
}
