import * as React from 'react';

export default function useElement<T extends HTMLElement = HTMLElement>(): [
  T | null,
  (node: T | null) => void,
] {
  const [element, setElement] = React.useState<T | null>(null);

  const elementCallbackRef = React.useCallback((node: T | null) => {
    setElement(node);
  }, []);

  return [element, elementCallbackRef];
}
