import type { Type } from '#types/appointment.ts';

import { getAppointmentTypes } from '#services/api/appointment.ts';
import { getProperties, getPublicProperties } from '#services/api/properties';
import { getMe, getProviders } from '#services/api/user';
import { getAccessToken } from '#utils/cookies';

/**
 * TODO
 * Since we don't have a way to change the user's data after the initial load
 * (due to the lack of login functionality), we can disable this since the
 * initial page load by looking at the cookie to avoid unnecessary requests.
 * This is temporary until login is implemented.
 */
const hasAccessToken = Boolean(getAccessToken());

export const me = {
  queryKey: ['users', 'me'],
  queryFn: getMe,
  staleTime: Infinity,
  enabled: hasAccessToken,
};

export const properties = (orgId?: string | number) => ({
  queryKey: ['properties', orgId],
  queryFn: () => {
    return hasAccessToken
      ? getProperties().catch(() => getPublicProperties(orgId))
      : getPublicProperties(orgId);
  },
  staleTime: Infinity,
});

export const publicProperties = (orgId?: string | number) => ({
  queryKey: ['public-properties', orgId],
  queryFn: () => getPublicProperties(orgId),
  staleTime: Infinity,
});

export const allProviders = {
  queryKey: ['all-providers'],
  queryFn: () => getProviders({ limit: 10_000, order: 'lastName ASC' }),
  staleTime: Infinity,
  enabled: hasAccessToken, // TODO needed?
};

export function appointmentTypes(type?: Type) {
  return {
    queryKey: ['appointment-type', type],
    queryFn: () => getAppointmentTypes(type),
    staleTime: Infinity,
    enabled: hasAccessToken, // TODO needed?
  };
}
