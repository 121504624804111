import type { Response } from '#types/common';

import { api } from '../http-clients';

// Add properties as needed
type Annotation = {
  id: number;
  type: 'HostURL' | 'JoinURL';
  content: string;
  userId: number | null;
};

export function getZoomAnnotations(
  sessionCode: string | number
): Promise<Annotation[]> {
  return api
    .post<
      Response<{ data: Annotation[] }>
    >('/vendor/zoomMeetingsSDK/getAnnotations', { sessionCode })
    .then((response) => response.data.payload.data);
}
