import { type SxProps } from '@mui/material';

import { type Appointment } from '#types/appointment';
import InformationCard from '../InformationCard';
import DependentTable from './DependentTable';

/* To prevent display name error below*/
const Dependent = (appointment: Appointment) => (
  <DependentTable appointment={appointment} />
);

export default function DependentInformation({
  appointment,
  sx,
}: {
  appointment: Appointment;
  sx?: SxProps;
}): JSX.Element {
  return (
    <InformationCard<Appointment>
      paperSx={sx}
      title="Dependent Information"
      model={appointment}
      items={[
        {
          id: 'ward',
          name: !appointment.wards?.length ? '' : 'Wards',
          accessor: Dependent,
        },
      ]}
    />
  );
}
