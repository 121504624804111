import * as React from 'react';

import useModal from '#hooks/modal';

type Options = {
  isOpen?: boolean;
  timeout?: number;
};

export default function useCopyTooltip({
  isOpen: defaultIsOpen = false,
  timeout = 1_500,
}: Options = {}): Pick<ReturnType<typeof useModal>, 'isOpen' | 'open'> {
  const { isOpen, open, dismiss } = useModal(defaultIsOpen);

  React.useEffect(() => {
    if (!isOpen) return;
    const timeoutId = window.setTimeout(dismiss, timeout);
    return () => window.clearTimeout(timeoutId);
  }, [isOpen, dismiss, timeout]);

  return { isOpen, open };
}
