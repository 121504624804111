import type { AutocompleteProps } from '@mui/material';
import type { Address } from '#services/api/user';
import type { BaseAutocompleteInputProps } from './Autocomplete.types';

import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { matchSorter } from '#lib/match-sorter';
import { me } from '#lib/react-query/queries';
import { getTextFieldProps } from './Autocomplete.utils';

const getOptionLabel = (locationAddress: Address) =>
  locationAddress.locationName ? String(locationAddress.locationName) : 'None';

type LocationsAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<Address, Multiple, DisableClearable, false>,
  | 'loading'
  | 'options'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'isOptionEqualToValue'
  | 'onInputChange'
  | 'renderInput'
  | 'freeSolo'
> &
  BaseAutocompleteInputProps<Address>;

export default function LocationsAutocomplete<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
>({
  inputName = 'location',
  inputLabel = 'Location',
  inputHelperText,
  inputError,
  inputRequired,
  fullWidth = true,
  filterSelectedOptions = true,
  clearOnBlur = false,
  selectOnFocus = true,
  handleHomeEndKeys = true,
  value,
  multiple,
  ...props
}: LocationsAutocompleteProps<Multiple, DisableClearable>): JSX.Element {
  const { data: { org } = {}, isLoading } = useQuery(me);

  return (
    <Autocomplete
      value={value}
      freeSolo={false}
      multiple={multiple}
      fullWidth={fullWidth}
      clearOnBlur={clearOnBlur}
      selectOnFocus={selectOnFocus}
      handleHomeEndKeys={handleHomeEndKeys}
      loading={isLoading}
      options={org?.addresses ?? []}
      getOptionLabel={getOptionLabel}
      filterSelectedOptions={filterSelectedOptions}
      filterOptions={(options, { inputValue }) =>
        matchSorter(options, inputValue, { keys: ['locationName'] })
      }
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {getOptionLabel(option)}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...getTextFieldProps({ params, multiple, inputRequired })}
          name={inputName}
          label={inputLabel}
          helperText={inputHelperText}
          error={inputError}
        />
      )}
      {...props}
    />
  );
}
