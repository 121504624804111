import type { Response } from '#types/common';
import type { Role } from '#utils/roles.ts';

import { api, apiWithoutErrorMessageValidation } from '#services/http-clients';

type Video = {
  id: string;
  /**
   * Ad-hoc visits don't have an appointment id present.
   */
  appointmentId?: string;
  vendorName: string;
  session: string | number;
  orgId: number;
  code: string;
  zak?: string;
  requiresRegistration?: boolean; // Present only in meetings sdk vendor
  attendees?: { name: string; role: Role }[];
  isGroupAppointment: boolean;
};

export function getDetails(sessionId: string): Promise<Video> {
  return api
    .get<Response<{ video: Video }>>(`/video/${sessionId}`)
    .then((response) => response.data.payload.video);
}

/**
 * Get the session details WITHOUT error message validation.
 */
export function unsafeGetDetails(
  sessionId: string
): Promise<Response<{ video: Video }>> {
  return apiWithoutErrorMessageValidation
    .get<Response<{ video: Video }>>(`/video/${sessionId}`)
    .then((response) => response.data);
}

/**
 * Track page load.
 */
export function trackPageLoad(sessionId: string): void {
  apiWithoutErrorMessageValidation
    .post(`/video/${sessionId}/track`)
    .catch((error) => {
      console.error('Failed to track page load:', error);
    });
}

type GetZoomSignaturePayload = {
  data: {
    signature: string;
    clientId: string;
  };
};

export function getZoomSignature(
  meetingNumber: string | number
): Promise<GetZoomSignaturePayload['data']> {
  return api
    .post<Response<GetZoomSignaturePayload>>(
      '/vendor/zoomMeetingsSDK/generateSignature',
      { meetingNumber }
    )
    .then((response) => response.data.payload.data)
    .then((data) => {
      if (!data.signature) throw new Error('Missing `signature`');
      if (!data.clientId) throw new Error('Missing `clientId`');
      return data;
    });
}

type ZoomRegistration = {
  join_url: string;
};

export function registerZoomUser(sessionId: string): Promise<ZoomRegistration> {
  return api
    .post<
      Response<{ data: ZoomRegistration }>
    >('/vendor/zoomMeetingsSDK/registerUser', { sessionCode: sessionId })
    .then((response) => response.data.payload.data);
}
