import type { User } from '#services/api/user';

import { isMatch } from 'date-fns';

import { config } from '#config';

type BaseUser = {
  firstName: string | number;
  lastName: string | number;
};

export function getFullName<T extends BaseUser>(user: T): string {
  return `${user.lastName}, ${user.firstName}`;
}

/**
 * In order to filter by unassigned providers we must use the id of an actual
 * provider (user in the database) used for this purpose whose id doesn't
 * change across environments.
 */
export const unassignedProvider = {
  id: 4268,
  label: 'Unassigned',
} as const;

export type UnassignedProvider = typeof unassignedProvider;

export const isUnassignedProvider = (
  user: User | UnassignedProvider
): user is UnassignedProvider => user.id === unassignedProvider.id;

export function getUserProfileLink(userRole: string, userId: number): string {
  if (!userRole || !userId) {
    return '';
  }

  const rolePaths: Record<string, string> = {
    Provider: '/provider',
    Patient: '/patient',
    Kiosk: '/patient',
  };

  const path = rolePaths[userRole] || '/user';

  return `${config.PORTAL_BASE_URL}${path}/${userId}`;
}

/**
 * Format the birthdate from `yyyy-MM-dd` to `MM/dd/yyyy`. If the format
 * doesn't match, the original value is returned.
 */
export function getFormattedBirthdate(birthdate: User['birthDate']) {
  if (!isMatch(birthdate, 'yyyy-MM-dd')) return birthdate;
  // Safe to use `as [...]` with above `isMatch(...)` validation
  const [year, month, day] = birthdate.split('-') as [string, string, string];
  return `${month}/${day}/${year}`;
}
