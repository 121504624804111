import * as React from 'react';

export default function useAnchorElement(): {
  anchor: HTMLButtonElement | HTMLDivElement | null;
  open: boolean;
  setAnchor: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => void;
  unsetAnchor: () => void;
} {
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);

  const setAnchor = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const unsetAnchor = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return { anchor: anchorEl, open: Boolean(anchorEl), setAnchor, unsetAnchor };
}
