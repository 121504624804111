import type { QueryClient } from '@tanstack/react-query';

import { me } from '#lib/react-query/queries';
import { getAccessToken } from '#utils/cookies';

export const loader = (queryClient: QueryClient) => async () => {
  // `ensureQueryData` ignores the `enabled` property so we have to check
  if (getAccessToken()) {
    // Do nothing if it fails to prevent the root error boundary to show up
    await queryClient.ensureQueryData(me).catch(() => void 0);
  }
  return null;
};
