import type { ButtonProps } from '@mui/material';

import * as React from 'react';
import { ContentCopy } from '@mui/icons-material';
import { Button } from '@mui/material';

import CopyTooltip from '../CopyTooltip/CopyTooltip';
import useCopyTooltip from '../CopyTooltip/use-copy-tooltip';

type CopyButtonProps = Omit<ButtonProps, 'value'> & {
  value: string | number;
  onCopySuccess?: () => void;
  onCopyFailure?: () => void;
};

/**
 * TODO: Should probably be removed in favor of the `AugmentedLink` component.
 */
const CopyButton = React.forwardRef<HTMLButtonElement, CopyButtonProps>(
  function CopyButton(
    { value, onCopySuccess, onCopyFailure, ...props },
    forwardedRef
  ) {
    const { isOpen, open } = useCopyTooltip();

    const copyToClipboard = (): void => {
      void navigator.clipboard
        .writeText(String(value))
        .then(() => {
          open();
          onCopySuccess?.();
        })
        .catch(onCopyFailure);
    };

    return (
      <CopyTooltip open={isOpen}>
        <Button
          ref={forwardedRef}
          size="small"
          variant="text"
          color="info"
          onClick={copyToClipboard}
          startIcon={<ContentCopy />}
          {...props}
        />
      </CopyTooltip>
    );
  }
);

export default CopyButton;
