import type { Channel } from 'pusher-js/with-encryption';
import type { Org, User } from '#services/api/user.ts';
import type {
  PusherConfig,
  PusherConnectionState,
  PusherSubscriptionError,
} from '#types/pusher.ts';

import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Pusher from 'pusher-js/with-encryption';
import { create } from 'zustand';

import { me } from '#lib/react-query/queries.ts';
import { getConfig } from '#services/api/websocket.ts';
import { getAccessToken } from '#utils/cookies.ts';
import { roleGreaterOrEquals } from '#utils/roles.ts';

type PusherState = {
  config: PusherConfig | null;
  pusher: Pusher | null;
  user: User | null;
  org: Org | null;
  orgChannel: Channel | null;
  orgStaffChannel: Channel | null;
  abortController: AbortController | null;
  abortConnect: () => void;
  fetchConfig: () => Promise<void>;
  connectPusher: (config: PusherConfig) => void;
  disconnectPusher: () => void;
  updateUserAndOrg: (user: User | undefined, org: Org | undefined) => void;
  getOrgChannelName: () => string;
  getOrgStaffChannelName: () => string;
};

/** Store for managing the Pusher service */
const usePusherStore = create<PusherState>((set, get) => ({
  config: null,
  pusher: null,
  user: null,
  org: null,
  orgChannel: null,
  orgStaffChannel: null,
  abortController: null,
  abortConnect: () => {
    const { abortController } = get();
    if (abortController) {
      abortController.abort();
      set({ abortController: null });
    }
  },
  fetchConfig: (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { config } = get();
      if (config) {
        return resolve(void 0);
      }

      const { abortConnect } = get();
      abortConnect();

      const abortController = new AbortController();
      set({ abortController });

      getConfig({ signal: abortController.signal })
        .then((config) => {
          set({ config });
          return resolve(void 0);
        })
        .catch((err) => {
          console.log('Failed to fetch Pusher config', err);
          return reject(err);
        });
    });
  },
  connectPusher: (config: PusherConfig): void => {
    const { user, getOrgChannelName, getOrgStaffChannelName } = get();

    const pusher = new Pusher(config.key, {
      cluster: config.cluster,
      authEndpoint: config.auth_url,
      auth: {
        headers: {
          'X-Access-Token': getAccessToken(),
        },
      },
      // TODO: Upgrade our API version of pusher/pusher-php-server to leverage the new
      //       userAuthentication option instead of the deprecated authEndpoint option
      //       as well as the channelAuthorization option for private channels
      // userAuthentication: {
      //   endpoint: config.auth_url,
      //   transport: 'ajax',
      //   headers: {
      //     'X-Access-Token': getAccessToken(),
      //   },
      // },
    });

    // Bind to connection status change events
    pusher.connection
      .bind('state_change', (states: PusherConnectionState) => {
        console.log(
          `Pusher connection state changed: ${states.previous} -> ${states.current}`
        );
      })
      .bind('error', (err: unknown) => {
        console.error(`Pusher connection error encountered:`, err);
      });

    const orgChannelName = getOrgChannelName();
    const orgChannel = pusher
      .subscribe(orgChannelName)
      .bind('pusher:subscription_succeeded', () => {
        console.log(`Subscribed to channel: ${orgChannelName}`);
      })
      .bind('pusher:subscription_error', (err: PusherSubscriptionError) => {
        console.error(`Subscription error:`, err);
      });

    // Conditionally subscribe to org staff channel if user is Staff+
    let orgStaffChannel = null;
    if (roleGreaterOrEquals(user?.role ?? 'Guest', 'Staff')) {
      const orgStaffChannelName = getOrgStaffChannelName();
      orgStaffChannel = pusher
        .subscribe(orgStaffChannelName)
        .bind('pusher:subscription_succeeded', () => {
          console.log(`Subscribed to channel: ${orgStaffChannelName}`);
        })
        .bind('pusher:subscription_error', (err: PusherSubscriptionError) => {
          console.error(`Subscription error:`, err);
        });
    }

    set({ pusher, orgChannel, orgStaffChannel });
  },
  disconnectPusher: () => {
    const { pusher } = get();
    if (pusher) {
      pusher.allChannels().map((channel) => {
        channel.unbind_all();
        channel.unsubscribe();
      });
      pusher.disconnect();
      set({
        pusher: null,
        user: null,
        org: null,
        orgChannel: null,
        orgStaffChannel: null,
      });
    }
  },
  getOrgChannelName: () => {
    const { org } = get();
    if (!org) {
      throw new Error('Org is not set');
    }
    return `private-encrypted-${org.id}`;
  },
  getOrgStaffChannelName: () => {
    const { getOrgChannelName } = get();
    return `${getOrgChannelName()}-staff`;
  },
  updateUserAndOrg: (user: User | undefined, org: Org | undefined) => {
    const { org: existingOrg, disconnectPusher, fetchConfig } = get();
    const hasOrgChanged =
      (org && org.id !== existingOrg?.id) || (!org && existingOrg);
    const isStaffPlus = roleGreaterOrEquals(user?.role ?? 'Guest', 'Staff');

    // Disconnect Pusher if user or org is not set or the org has changed
    if (!user || !org || hasOrgChanged) {
      disconnectPusher();
    }

    // Abort if we don't have a Staff+ User or an Org
    if (!user || !isStaffPlus || !org) {
      return;
    }

    // Abort if Pusher is already connected and the Org hasn't changed
    const { pusher } = get();
    const isConnected = pusher !== null;
    if (isConnected && !hasOrgChanged) {
      return;
    }

    // By this time we've established that we have a Staff+ User and an Org and Pusher is not connected
    set({ user, org });
    fetchConfig()
      .then(() => {
        const { config, connectPusher } = get();
        if (config) {
          connectPusher(config);
        }
      })
      .catch((err) => {
        console.error('Failed to fetch Pusher config', err);
      });
  },
}));

/**
 * Hook for using the Pusher service
 * Usage example:
 * const { service } = usePusherService();
 * service?.getOrgChannel()?.bind('event', (data) => { do stuff... });
 * service?.getOrgStaffChannel()?.bind('event', (data) => { do stuff... });
 */
export const usePusherService = () => {
  const { updateUserAndOrg, orgChannel, orgStaffChannel } = usePusherStore();
  const { data: { user, org } = {} } = useQuery(me);

  useEffect(() => {
    updateUserAndOrg(user, org);
  }, [user, org, updateUserAndOrg]);

  return { orgChannel, orgStaffChannel };
};
