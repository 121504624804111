import type { Response } from '#types/common.ts';
import type { PusherConfig } from '#types/pusher.ts';

import { api } from '#services/http-clients.ts';

export function getConfig({
  signal,
}: {
  signal?: AbortSignal;
} = {}): Promise<PusherConfig> {
  return api
    .get<Response<{ config: PusherConfig }>>('/websocket/config', { signal })
    .then((response) => {
      return response.data.payload.config;
    });
}
