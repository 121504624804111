import { faLightbulbOn } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, Stack, Typography } from '@mui/material';

import FontAwesomeScalableIcon from '#components/FontAwesomeScalableIcon.tsx';
import * as Modal from '#components/Modal';

type JoinInZoomModalProps = {
  userType: 'host' | 'guest';
  joinUrl: string;
  onClose: () => void;
};

export function JoinInZoomModal(props: JoinInZoomModalProps) {
  return (
    <Modal.Root
      open
      onClose={props.onClose}
      maxWidth="sm"
      aria-labelledby="join-via-zoom-app-modal-title"
    >
      <Modal.Header>
        <Modal.Title id="join-via-zoom-app-modal-title">
          Join via Zoom App
        </Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <Typography variant="body1">
          Switch to the Zoom app for a more reliable connection.
        </Typography>

        {props.userType === 'host' && (
          <>
            <Typography variant="body1">
              Reporting is not yet available for this feature.
            </Typography>

            {/* Due to the icon's weird box size, `flex-start` is better than `center` */}
            <Stack marginTop={2} alignItems="flex-start">
              <Box component="span" color="warning.light">
                <FontAwesomeScalableIcon icon={faLightbulbOn} color="inherit" />
              </Box>
              <Typography variant="body1">
                Reclaim host once you join via the app.
              </Typography>
            </Stack>
          </>
        )}
      </Modal.Content>

      <Modal.Footer>
        <Modal.DismissButton />

        <Button href={props.joinUrl} rel="noreferrer noopener">
          Join via Zoom App
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}
