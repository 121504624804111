import * as React from 'react';

import useSafeDispatch from './safe-dispatch';

type Status = 'idle' | 'loading' | 'success' | 'error';

type State<TData> = {
  status: Status;
  data: TData | undefined;
  error: string | undefined;
};

const defaultInitialState: State<undefined> = {
  status: 'idle',
  data: undefined,
  error: undefined,
};

export default function useAsync<TData = undefined>(
  initialState: Partial<State<TData>> = {}
): {
  status: Status;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: TData | undefined;
  error: string | undefined;
  run: (promise: Promise<TData>) => Promise<TData | Error>;
  reset: () => void;
  setData: (data: TData) => void;
  setError: (error: string | undefined) => void;
} {
  const initialStateRef = React.useRef<State<TData>>({
    ...defaultInitialState,
    ...initialState,
  });

  const reducer = React.useCallback(
    (state: State<TData>, newState: Partial<State<TData>>): State<TData> => ({
      ...state,
      ...newState,
    }),
    []
  );

  const [state, unsafeDispatch] = React.useReducer(
    reducer,
    initialStateRef.current
  );
  const { status, data, error } = state;

  const dispatch = useSafeDispatch(unsafeDispatch);

  const run = React.useCallback(
    (promise: Promise<TData>) => {
      dispatch({ status: 'loading' });
      return promise
        .then((data: TData) => {
          dispatch({ status: 'success', data });
          return data;
        })
        .catch((error: unknown) => {
          const message =
            error instanceof Error
              ? error.message
              : typeof error === 'string'
                ? error
                : 'An unknown error occurred.';
          dispatch({ status: 'error', error: message });
          return error instanceof Error ? error : new Error(message);
        });
    },
    [dispatch]
  );

  const reset = React.useCallback(() => {
    dispatch(initialStateRef.current);
  }, [dispatch]);

  const setData = React.useCallback(
    (data: TData | undefined) => {
      dispatch({ data, status: 'success' });
    },
    [dispatch]
  );

  const setError = React.useCallback(
    (error: string | undefined) => {
      dispatch({ error, status: 'error' });
    },
    [dispatch]
  );

  return {
    status,
    isIdle: status === 'idle',
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    isError: status === 'error',
    data,
    error,
    run,
    reset,
    setData,
    setError,
  };
}
