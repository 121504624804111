import { Button, Typography } from '@mui/material';

import useReportToNewRelic from '#hooks/report-to-new-relic.ts';
import * as Modal from './Modal';

const SUPPORT_EMAIL = 'bestservice@mend.com';

type ErrorProps = {
  error: unknown;
  onClose: () => void;
};

export default function Error({ error, onClose }: ErrorProps): JSX.Element {
  useReportToNewRelic(error);

  return (
    <Modal.Root
      open
      onClose={onClose}
      maxWidth="sm"
      aria-labelledby="error-modal-title"
    >
      <Modal.Header>
        <Modal.Title id="error-modal-title">
          Oops, it looks like something went wrong!
        </Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <Typography paragraph>
          We track these errors automatically. We&apos;re on it.
        </Typography>
        <Typography paragraph>
          Please reach out to{' '}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> if the issue
          persists.
        </Typography>
        <Typography>Sorry for your inconvenience.</Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          Dismiss
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}
