import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import type { Response } from '../types/common';

import axios from 'axios';

import { config } from '../config';
import { useRefreshTokenStore } from '../stores/refresh-token.ts';
import { getAccessToken, setAccessToken } from '../utils/cookies';

const instanceConfigDefaults = {
  baseURL: config.API_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
};

const api = axios.create(instanceConfigDefaults);
const apiWithoutErrorMessageValidation = axios.create(instanceConfigDefaults);
const logging = axios.create({
  ...instanceConfigDefaults,
  baseURL: config.LOGGING_BASE_URL,
});

/**
 * Read and use the token stored in the cookie, this will allow us to always
 * send the latest value.
 */
function useTokenInCookie(config: InternalAxiosRequestConfig) {
  const token = getAccessToken();
  if (token) {
    config.headers['X-Access-Token'] = token;
  }
  return config;
}

// Update the token cookie each time we receive a response
function updateTokenCookie(response: AxiosResponse<Response>) {
  // Skip setting the cookie if the response doesn't have a token
  if (!response?.data?.token) {
    return response;
  }

  setAccessToken(response.data.token);
  return response;
}

/**
 * Update the expiration date saved in the store any time we receive a response
 * with a token.
 */
function updateTokenExpirationDate(response: AxiosResponse<Response>) {
  if (!response?.data?.token) {
    return response;
  }

  try {
    const rawPayload = response.data.token.split('.')[1] as string;
    const payload = JSON.parse(atob(rawPayload)) as { exp: number };
    /**
     * The expiration date comes in seconds so we need to convert it to
     * milliseconds to easily perform operations with date objects. We also
     * substract 60 seconds to ensure we give it plenty of time to be refreshed
     * before it expires. The value of `expiresAt` will roughly be 1 hour and
     * 59 minutes after the token is received based on an expiration of 2 hours.
     */
    const expiresAt = payload.exp * 1_000 - 60_000;
    useRefreshTokenStore.getState().setExpiresAt(expiresAt);
  } catch (error) {
    return response;
  }

  return response;
}

/**
 * Sometimes the API responds with a 200 even if the request fails, we check
 * for that here and throw an error explicitly.
 */
function failOn200WithErrorMessage(response: AxiosResponse<Response>) {
  const errorMessage = Array.isArray(response.data.messages)
    ? response.data.messages.find(
        (message) => message.type === 'Error' && message.id !== 409
      )
    : undefined;

  if (errorMessage) {
    throw new Error(errorMessage.text);
  }

  return response;
}

api.interceptors.request.use(useTokenInCookie);
api.interceptors.response.use(updateTokenCookie);
api.interceptors.response.use(updateTokenExpirationDate);
api.interceptors.response.use(failOn200WithErrorMessage);

apiWithoutErrorMessageValidation.interceptors.request.use(useTokenInCookie);
apiWithoutErrorMessageValidation.interceptors.response.use(updateTokenCookie);
apiWithoutErrorMessageValidation.interceptors.response.use(
  updateTokenExpirationDate
);

export { api, apiWithoutErrorMessageValidation, logging };
