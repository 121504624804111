import type { TextFieldProps } from '@mui/material';

import * as React from 'react';
import { Close, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useAsyncDebounce } from 'react-table';

export type SearchBarProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value: string;
  onChange: (value: string) => void;
  /**
   * Time in ms to trigger the `onChange` callback prop. Useful when we want to
   * perform an async search with a delay while keeping the field responsive.
   */
  onChangeDelay?: number;
};

const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
  function SearchBar(
    {
      type = 'text',
      label = 'Search',
      InputProps,
      value: asyncValue,
      onChange: asyncOnChange,
      onChangeDelay = 0,
      ...props
    },
    forwardedRef
  ): JSX.Element {
    const localInputRef = React.useRef<HTMLInputElement | null>(null);
    const [value, setValue] = React.useState(asyncValue);

    const onAsyncChange = useAsyncDebounce((value: string) => {
      asyncOnChange?.(value);
    }, onChangeDelay);

    /**
     * We have to check for `onChangeDelay` value in order to know which function
     * to call because `useAsyncDebounce` uses a `setTimeout` internally so even
     * if we default to `0` its execution won't be performed synchronously.
     */
    const handleChange = (value: string) => {
      setValue(value);
      if (onChangeDelay > 0) {
        onAsyncChange(value);
      } else {
        // Not really async here huh
        asyncOnChange?.(value);
      }
    };

    return (
      <TextField
        inputRef={(ref: HTMLInputElement | null) => {
          if (typeof forwardedRef === 'function') {
            forwardedRef(ref);
          } else if (forwardedRef !== null) {
            forwardedRef.current = ref;
          }
          localInputRef.current = ref;
        }}
        type={type}
        label={label}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        InputProps={
          InputProps ?? {
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment:
              value !== '' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Clear"
                    onClick={() => {
                      localInputRef.current?.focus();
                      handleChange('');
                    }}
                    edge="end"
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
          }
        }
        {...props}
      />
    );
  }
);

export default SearchBar;
