import { timeZones } from '#components/Autocomplete/TimeZones/TimeZonesAutocomplete.utils';

export function getMappedUserTimezone(timezone: string): string {
  return timeZones.find((tz) => tz.value === timezone)?.label ?? timezone;
}

const timezoneInputValueSuffixes = ['mine', 'provider', 'patient'];

/**
 * Get the timezone from an input value where it's common to append a suffix
 * with a separator to differentiate options with the same timezone.
 */
export function getTimezoneFromInputValueWithSuffix(value: string): string {
  const parts = value.split('-');

  /**
   * Safe to use `as string` because parts will always have at least one value
   * by using a non-empty separator.
   */
  if (
    parts.length === 1 ||
    !timezoneInputValueSuffixes.includes(parts.at(-1) as string)
  ) {
    return value;
  }

  // Case when the timezone itself has {'-'} in its name
  return parts.slice(0, -1).join('-');
}
