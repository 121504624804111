export type Role =
  | 'Guest'
  | 'No Login'
  | 'Video'
  | 'Appointment'
  | 'Kiosk'
  | 'Assessment'
  | 'Lead'
  | 'Patient'
  | 'Ward'
  | 'Associate'
  | 'Staff'
  | 'Provider'
  | 'Org Admin'
  | 'Provider Admin'
  | 'Admin';

const order: [undefined, ...Role[]] = [
  undefined,
  'Guest',
  'No Login',
  'Video',
  'Lead',
  'Appointment',
  'Kiosk',
  'Assessment',
  'Patient',
  'Ward',
  'Associate',
  'Staff',
  'Provider',
  'Org Admin',
  'Provider Admin',
  'Admin',
];
export const PROVIDER_ROLES: Role[] = ['Provider', 'Provider Admin'];

export function roleGreaterOrEquals(
  userRole: Role | undefined,
  role: Role
): boolean {
  return userRole ? order.indexOf(userRole) >= order.indexOf(role) : false;
}

export function isAdminLike(role: Role | undefined): boolean {
  return role ? /admin/i.test(role) : false;
}

/**
 * Check if the role is a provider or a provider admin.
 * @param role
 */
export const isProviderLike = (role: Role | undefined): boolean =>
  !!role && PROVIDER_ROLES.includes(role);
