import type { Theme } from '@mend/mui-theme';
import type { SxProps } from '@mui/material';

import { Button } from '@mui/material';

import * as Modal from './Modal';

type ConfirmationProps = {
  onGoBack: () => void;
  onDiscard: () => void;
};

const footerSx: SxProps<Theme> = {
  borderTop: (theme) => `1px solid ${theme.palette.divider}`,
};

function doNothing() {
  return void 0;
}

export default function Confirmation({
  onGoBack,
  onDiscard,
}: ConfirmationProps): JSX.Element {
  return (
    <Modal.Root
      open
      /**
       * This "disables" the ability to close the modal without changing the
       * prop type to be optional since this would be the only location where
       * it needs to behave this way.
       */
      onClose={doNothing}
      maxWidth="xs"
      aria-labelledby="confirmation-modal-title"
      disableCloseButton
    >
      <Modal.Header>
        <Modal.Title id="confirmation-modal-title">
          Discard unsaved changes?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer sx={footerSx}>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={onGoBack}
        >
          Go back
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={onDiscard}
        >
          Discard
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}
