import type { AutocompleteProps } from '@mui/material';
import type { ListChildComponentProps } from 'react-window';
import type { User } from '#services/api/user';
import type { BaseAutocompleteInputProps } from './Autocomplete.types';

import * as React from 'react';
import { styled } from '@mend/mui-theme';
import {
  Autocomplete,
  Box,
  Chip,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { useQuery } from '@tanstack/react-query';
import { FixedSizeList } from 'react-window';

// import { useAllProviders } from '@/hooks/queries/providers'; // TODO fix
import { allProviders } from '#lib/react-query/queries';
import { removeSpecialCharacters } from '#utils/functions';
import { getFullName } from '#utils/user';
import { getTextFieldProps } from './Autocomplete.utils';
import * as usersAutocompleteUtils from './Users/UsersAutocomplete.utils';

/**
 * Virtualization example taken from mui's docs with some modifications.
 * @see https://mui.com/material-ui/react-autocomplete/#virtualization
 */
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const LISTBOX_PADDING = 8;

function renderRow({ data, index, style }: ListChildComponentProps) {
  // TODO changed exclusively for the port, see comment in default export
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const [props, option, state] = data[index] as [
    React.HTMLAttributes<HTMLLIElement>,
    User,
    { selected: boolean },
  ];

  return (
    <Box
      {...props}
      key={option.id}
      component="li"
      sx={{
        backgroundColor: state.selected
          ? usersAutocompleteUtils.selectedBgColor
          : undefined,
        '&:not(:last-child)': {
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        },
      }}
      style={{
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
      }}
    >
      <Box width="100%">
        <Typography component="div" variant="body2">
          {getFullName(option)}
        </Typography>
        <Typography component="div" variant="caption">
          <Typography
            component="span"
            variant="inherit"
            fontWeight={700}
            color={usersAutocompleteUtils.getRoleColor(option.role)}
          >
            {option.role}
          </Typography>
          {usersAutocompleteUtils.getRoleExtraDetails(option)}
        </Typography>
        <Typography component="div" variant="caption">
          {option.email}
        </Typography>
      </Box>
    </Box>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>(
  function OuterElementType(props, ref) {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  }
);

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  /**
   * Approximate height of each option rendered in the autocomplete, if the
   * design changes, we need to update this value accordingly.
   */
  const itemSize = 73;
  const itemCount = itemData.length;

  const getHeight = () => Math.min(itemCount, 8) * itemSize;

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemData={itemData}
          itemCount={itemCount}
          itemSize={itemSize}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const defaultDataFilter = (options: User[]) => options;

type ProvidersAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<User, Multiple, DisableClearable, false>,
  | 'loading'
  | 'options'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'isOptionEqualToValue'
  | 'renderInput'
  | 'renderOption'
  | 'renderTags'
  | 'freeSolo'
  | 'disableListWrap'
  | 'PopperComponent'
  | 'ListboxComponent'
> &
  BaseAutocompleteInputProps<User>;

/**
 * TODO tech debt, this component was copied from the dashboard for TS-13028,
 * some rules had to be disabled due to differences between the portal's and
 * this project's config. They need to be resolved once this is moved into a
 * unified component.
 *
 * Although we have the `UsersAutocomplete` component that can search for
 * `Providers` and `Provider Admins` only, this component is meant to be used
 * as a replacement in such cases by taking advantage of the full list already
 * loaded with `useAllProviders`, avoiding unnecessary requests.
 *
 * At some point we might have a unique design for the options / chips used in
 * this component but in the meantime it replicates the design of the
 * `Provider` and `Provider Admin` options in the generic `UsersAutocomplete`
 * component for consistency and makes use of its utils as well.
 */
export default function ProvidersAutocomplete<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
>({
  inputName,
  inputLabel = 'Search',
  inputHelperText,
  inputError,
  inputRequired,
  fullWidth = true,
  filterSelectedOptions = false,
  clearOnBlur = false,
  selectOnFocus = true,
  handleHomeEndKeys = true,
  value,
  multiple,
  dataFilter = defaultDataFilter,
  ...props
}: ProvidersAutocompleteProps<Multiple, DisableClearable>): JSX.Element {
  const { data, isLoading } = useQuery(allProviders);

  const providers = React.useMemo(
    () => dataFilter(data ?? []),
    [data, dataFilter]
  );

  return (
    <Autocomplete
      value={value}
      freeSolo={false}
      multiple={multiple}
      fullWidth={fullWidth}
      clearOnBlur={clearOnBlur}
      selectOnFocus={selectOnFocus}
      handleHomeEndKeys={handleHomeEndKeys}
      loading={isLoading}
      options={providers}
      getOptionLabel={getFullName}
      filterSelectedOptions={filterSelectedOptions}
      filterOptions={(options, { inputValue }) => {
        const value = inputValue.trim();
        if (!value) return options;
        const regex = new RegExp(removeSpecialCharacters(value), 'i');
        return options.filter(
          ({
            id,
            firstName,
            lastName,
            specialtyName,
            credentialName,
            email,
            mobile,
          }) =>
            regex.test(
              removeSpecialCharacters(
                `${id} ${firstName} ${lastName} ${specialtyName} ${credentialName} ${email} ${
                  mobile ?? ''
                }`
              )
            )
        );
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...getTextFieldProps({ params, multiple, inputRequired })}
          name={inputName}
          label={inputLabel}
          helperText={inputHelperText}
          error={inputError}
        />
      )}
      renderTags={(options, getTagProps) =>
        options.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return <Chip key={key} {...tagProps} label={getFullName(option)} />;
        })
      }
      /**
       * Disabled because with virtualization we only render a subset of
       * elements and the default wrapping would loop between them only, making
       * it seem as if it was broken.
       */
      disableListWrap
      /**
       * Custom components wrapping the virtualization functionality.
       */
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      /**
       * Instead of returning a component, we return the array of data needed
       * to properly build the virtualized item.
       */
      renderOption={(props, option, state) =>
        [props, option, state] as React.ReactNode
      }
      {...props}
    />
  );
}
