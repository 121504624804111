import type {
  IconButtonProps,
  StackProps,
  TypographyProps,
} from '@mui/material';

import * as React from 'react';
import { styled } from '@mend/mui-theme';
import { IconButton, Stack, Typography } from '@mui/material';

/**
 * Context
 */
const ActionContext = React.createContext(false);
ActionContext.displayName = 'ActionContext';

const ActionProvider: React.FC<{
  disabled: boolean;
  children: React.ReactNode;
}> = ({ disabled, children }) => {
  return (
    <ActionContext.Provider value={disabled}>{children}</ActionContext.Provider>
  );
};

const useActionDisabled = () => React.useContext(ActionContext);

/**
 * UI components
 */
type RootProps = StackProps & {
  disabled?: boolean;
};

export const Root = ({
  disabled = false,
  direction = 'column',
  spacing = 0.5,
  alignItems = 'center',
  ...props
}: RootProps): JSX.Element => (
  <ActionProvider disabled={disabled}>
    <Stack
      direction={direction}
      spacing={spacing}
      alignItems={alignItems}
      {...props}
    />
  </ActionProvider>
);

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  ':disabled, &[aria-disabled="true"]': {
    borderColor: 'transparent !important',
    color: `${theme.palette.grey[400]} !important`,
    backgroundColor: `${theme.palette.grey[200]} !important`,
  },
}));

type ButtonProps = Omit<IconButtonProps, 'disabled'> & {
  href?: string;
};

/**
 * The first spread in the `StuledButton` component is used to get rid of type
 * errors with the `styled` usage.
 */
export const Button = ({
  size = 'small',
  disableFocusRipple = true,
  href,
  ...props
}: ButtonProps): JSX.Element => {
  const disabled = useActionDisabled();
  return (
    <StyledButton
      size={size}
      disabled={disabled}
      disableFocusRipple={disableFocusRipple}
      {...(Boolean(href) && { component: 'a', href })}
      {...props}
    />
  );
};

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

export const Label = ({
  variant = 'caption',
  ...props
}: TypographyProps): JSX.Element => {
  const disabled = useActionDisabled();
  return (
    <StyledLabel
      variant={variant}
      sx={{
        color: (theme) => (disabled ? theme.palette.grey[400] : undefined),
      }}
      {...props}
    />
  );
};
