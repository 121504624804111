import type { CardProps } from '#components/DataTable';

import { Stack } from '@mui/material';

import { Card } from '#components/DataTable';
import { type AppointmentTransferStatus } from '#types/appointment';

export default function TransferStatusCard({
  row: { cells },
  getCellRendererProps,
}: CardProps<AppointmentTransferStatus>): JSX.Element {
  const actionsCell = cells.find(({ column }) => column.id === 'actions');
  const transferStatusInfoCell = cells.find(
    ({ column }) => column.id === 'name'
  );

  return (
    <Card.Root expandable={false}>
      <Card.Content>
        <Stack>
          {actionsCell?.render('Cell', {
            customProps: getCellRendererProps?.(actionsCell),
          })}
          {transferStatusInfoCell?.render('Cell', {
            customProps: getCellRendererProps?.(transferStatusInfoCell),
          })}
        </Stack>
      </Card.Content>
    </Card.Root>
  );
}
