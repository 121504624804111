import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import * as Modal from '#components/Modal';
import useAsync from '#hooks/async';
import { checkOut } from '#services/api/appointment';

type CheckOutModalProps = {
  appointmentId: number;
  onClose: () => void;
};

export default function CheckOutModal(props: CheckOutModalProps) {
  const checkOutQuery = useAsync<Awaited<ReturnType<typeof checkOut>>>();
  const { enqueueSnackbar } = useSnackbar();

  function handleConfirm() {
    void checkOutQuery.run(checkOut(props.appointmentId)).then((result) => {
      if (result instanceof Error) {
        enqueueSnackbar(
          'Could not check out appointment. Please try again later.',
          { variant: 'error' }
        );
        return;
      }
      props.onClose();
    });
  }

  return (
    <Modal.Root
      maxWidth="sm"
      open
      onClose={props.onClose}
      isLoading={checkOutQuery.isLoading}
      aria-labelledby="check-out-appointment-modal-title"
    >
      <Modal.Header>
        <Modal.Title id="check-out-appointment-modal-title">
          Check Out Appointment?
        </Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <Typography variant="body1">
          Checking out this appointment will remove it from your dashboard and
          make the link invalid.
        </Typography>
      </Modal.Content>

      <Modal.Footer>
        <Modal.DismissButton>No</Modal.DismissButton>
        <Button onClick={handleConfirm}>Yes</Button>
      </Modal.Footer>
    </Modal.Root>
  );
}
