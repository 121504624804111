import type { User } from '#services/api/user';
import type { Appointment } from '#types/appointment';

import * as React from 'react';
import { Button } from '@mui/material';

import * as Modal from '#components/Modal';
import { ProviderAvailability } from '#components/ProviderAvailability/ProviderAvailability';

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export type ProviderAvailabilityModalProps = {
  appointment: Appointment;
  isOpen: boolean;
  onDismiss: () => void;
  onApplyProviderSelection?: (user: User, selectedSlot: SlotRange) => void;
};

type SlotRange = {
  endDate: string;
  startDate: string;
  id?: string;
};

const initialRange = {
  endDate: '',
  startDate: '',
  id: '',
};

export default function ProviderAvailabilityModal({
  appointment,
  isOpen,
  onDismiss,
  onApplyProviderSelection,
}: ProviderAvailabilityModalProps): JSX.Element {
  const [selectedSlot, setSelectedSlot] =
    React.useState<SlotRange>(initialRange);
  const [user, setUser] = React.useState<User | null>();
  const [timezone, setTimezone] = React.useState(`${currentTimezone}-mine`);
  const handleSlotSelection = (slot: SlotRange) => setSelectedSlot(slot);
  const handleUserSelection = (user: User) => setUser(user);

  const cellRenderedProps = {
    handleSlotSelection,
    handleUserSelection,
    selectedSlot,
    timezone,
    currentTimezone,
  };

  const handleOnApply = () => {
    if (user) {
      onApplyProviderSelection?.(user, selectedSlot);
    }
    onDismiss();
  };

  return (
    <Modal.Root
      maxWidth="xl"
      aria-labelledby="provider-availability-title"
      open={isOpen}
      onClose={onDismiss}
    >
      <Modal.Header>
        <Modal.Title id="provider-availability-title">
          Select Provider
        </Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <ProviderAvailability
          appointment={appointment}
          getCellRenderedProps={() => cellRenderedProps}
          patientTimezone={appointment.patient.timeZone}
          timezone={timezone}
          onTimezoneChange={setTimezone}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button variant="outlined" onClick={onDismiss}>
          Dismiss
        </Button>
        <Button
          disabled={onApplyProviderSelection && !selectedSlot.startDate}
          color="primary"
          onClick={handleOnApply}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}
