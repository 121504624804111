import type { AutocompleteProps } from '@mui/material';
import type { BaseAutocompleteInputProps } from '../Autocomplete.types';
import type { SlotLength } from './SlogLengthsAutocomplete.types';

import { Autocomplete, TextField } from '@mui/material';

import { getTextFieldProps } from '../Autocomplete.utils';
import { availableSlots } from './SlogLengthsAutocomplete.utils';

type SlotLengthAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<SlotLength, Multiple, DisableClearable, false>,
  | 'loading'
  | 'options'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'isOptionEqualToValue'
  | 'onInputChange'
  | 'renderInput'
  | 'renderOption'
  | 'renderTags'
  | 'freeSolo'
> &
  BaseAutocompleteInputProps<SlotLength>;

export default function SlotLengthsAutocomplete<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
>({
  inputName = 'slot-length',
  inputLabel = 'Time Slot Length',
  inputHelperText,
  inputError,
  inputRequired,
  fullWidth = true,
  filterSelectedOptions = false,
  clearOnBlur = false,
  selectOnFocus = true,
  handleHomeEndKeys = true,
  value,
  multiple,
  ...props
}: SlotLengthAutocompleteProps<Multiple, DisableClearable>): JSX.Element {
  return (
    <Autocomplete
      value={value}
      freeSolo={false}
      multiple={multiple}
      fullWidth={fullWidth}
      clearOnBlur={clearOnBlur}
      selectOnFocus={selectOnFocus}
      handleHomeEndKeys={handleHomeEndKeys}
      options={availableSlots}
      getOptionLabel={(option) => `${option} minutes`}
      filterSelectedOptions={filterSelectedOptions}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...getTextFieldProps({ params, multiple, inputRequired })}
          name={inputName}
          label={inputLabel}
          helperText={inputHelperText}
          error={inputError}
        />
      )}
      {...props}
    />
  );
}
