import type { DateSelectArg, EventDropArg } from '@fullcalendar/react';
import type { AppointmentDateRange } from '#components/BookAgainModal/BookAgainCalendar';

import { addMinutes } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { getTimezoneFromInputValueWithSuffix } from '#utils/timezones.ts';
import usePastEventDropError from './past-event-drop-error';

type CalendarEvents = {
  onEventDropChange: (eventDrop: EventDropArg) => void;
  onDateSelectChange: (selectInfo: DateSelectArg) => void;
};

const getZonedStart = (date: string, timezone: string) =>
  zonedTimeToUtc(new Date(date), getTimezoneFromInputValueWithSuffix(timezone));

export default function useCalendarEvents(
  timezone: string,
  _newAppointmentsRange: AppointmentDateRange[],
  onNewAppointmentsRangeChange: (value: AppointmentDateRange[]) => void,
  appointmentDurationInMinutes: number
): CalendarEvents {
  const pastEventDropError = usePastEventDropError();

  const updateNewAppointmentsRange = (zonedStart: Date) => {
    /**
     * We have to use `startStr` because it has the correct time of the space
     * where the user clicked regardless of the timezone, we then convert it to
     * the correct date with the proper offset.
     */

    onNewAppointmentsRangeChange([
      {
        start: zonedStart.toISOString(),
        end: addMinutes(zonedStart, appointmentDurationInMinutes).toISOString(),
      },
    ]);
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const zonedStart = getZonedStart(selectInfo.startStr, timezone);
    updateNewAppointmentsRange(zonedStart);
    const { calendar } = selectInfo.view;
    calendar.unselect();
  };

  const eventDrop = (eventDrop: EventDropArg) => {
    /**
     * The startStr has a different format, so we need to remove the offset,
     * then convert it to the correct date, so that updateNewAppointmentsRange
     * can use it the same way as handleDateSelect.
     */
    const zonedStart = getZonedStart(
      eventDrop.event.startStr.slice(0, 19),
      timezone
    );
    updateNewAppointmentsRange(zonedStart);
    pastEventDropError({
      eventId: eventDrop.event.id,
      startDate: zonedStart.toISOString(),
    });
  };
  return { onEventDropChange: eventDrop, onDateSelectChange: handleDateSelect };
}
