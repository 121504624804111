// import type {
//   ChatEvent,
//   CustomerData,
//   WidgetState,
// } from '@livechat/widget-react';
import type { ButtonProps } from '@mui/material';

// import type { Metadata } from '#lib/livechat';
// import type { Org, User } from '#services/api/user';
// import type { Properties } from '#utils/properties';

import * as React from 'react';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { styled } from '@mend/mui-theme';
// import {
//   LiveChatWidget,
//   useWidgetChatData,
//   useWidgetCustomerData,
//   useWidgetState,
// } from '@livechat/widget-react';
import { Button, Tooltip } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';
import { alpha } from '@mui/material/styles';

import FontAwesomeScalableIcon from '#components/FontAwesomeScalableIcon';
import Support from '#components/TechSupport/Support.tsx';

// import { useQuery } from '@tanstack/react-query';
// import { format } from 'date-fns';

// import {
//   customIdentityProvider,
//   getChatIssue,
//   getTokenFromCache,
//   LICENSE_ID,
//   removeTokenFromCache,
// } from '#lib/livechat';
// import * as queries from '#lib/react-query/queries';
// import { convertUtcStringToLocalDate } from '#utils/dates';
// import { getReadableLanguageFromCookie } from '#utils/languages';
// import { ALLOW_PATIENT_CANCEL_RESCHEDULE } from '#utils/properties';
// import { getFullName, getUserProfileLink } from '#utils/user';

const TechSupportButton = styled((props: ButtonProps) => (
  <Tooltip title="Chat with Tech Support in a New Tab">
    <Button
      variant="text"
      startIcon={<FontAwesomeScalableIcon icon={faComment} />}
      {...props}
    >
      Tech Support
    </Button>
  </Tooltip>
))(({ theme }) => ({
  color: 'inherit',
  '&:hover, &:focus': {
    /**
     * This is how the buttons calculate the background color for the the hover/focus
     * states of the text variant.
     */
    backgroundColor: alpha(theme.palette.navbar.main, 0.04),
  },
  [`.${buttonClasses.startIcon}`]: {
    /**
     * Font awesome's icons don't have the same width/height so we need to
     * adjust the position for the icon used here.
     */
    marginLeft: -2,
    color: 'inherit',
  },
}));

// const language = getReadableLanguageFromCookie();

// function getMetadata(
//   user: User | undefined,
//   org: Org | undefined,
//   properties: Properties | undefined
// ): Metadata {
//   const dateFormat = 'MM/dd/yyyy hh:mm a';
//
//   /**
//    * TODO
//    * - `browser-*`
//    * - `device-*`
//    * - console
//    */
//   const metadata: Metadata = {
//     currentPage: window.location.href,
//     language,
//     localTime: format(new Date(), dateFormat),
//     console: '',
//   };
//
//   if (user) {
//     metadata.role = user.role;
//     metadata.email = user.email;
//     metadata.profile = getUserProfileLink(user.role, user.id);
//     metadata.lastLogin = format(
//       convertUtcStringToLocalDate(user.lastLogin),
//       dateFormat
//     );
//     metadata.fullName = getFullName(user);
//     metadata.birthDate = user.birthDate;
//     metadata.mobile = user.mobile ?? '';
//     metadata.cancelReschedule = String(
//       properties?.[ALLOW_PATIENT_CANCEL_RESCHEDULE] === 1
//     );
//   } else {
//     metadata.role = 'Not Logged In';
//   }
//
//   if (org) {
//     metadata.org = org.name;
//     metadata.orgId = String(org.id);
//     metadata.orgIdNumerical = String(org.id);
//     metadata.mendNow = org.mendNow === 1 ? 'True' : 'False';
//     metadata.masterOrgId = String(org.masterOrgId);
//     metadata.patientSelfScheduling = String(
//       org.settings.allowPatientBookings === 1
//     );
//   }
//
//   return metadata;
// }

/**
 * TODO
 * Once we have implemented the functionality to log in, we need to make sure
 * we clear the existing token (if any) and close the widget so it has a fresh
 * state upon being reopened.
 */
export default function TechSupport() {
  // const meQuery = useQuery(queries.me);
  // const propertiesQuery = useQuery({
  //   ...queries.properties,
  //   enabled: meQuery.isSuccess,
  // });

  // const [isLiveChatOpen, setIsLiveChatOpen] = React.useState(
  //   () => getTokenFromCache() !== null
  // );

  const [isSupportOpen, setIsSupportOpen] = React.useState(false);

  const handleTechSupportClick = () => {
    // TODO - Remove this line once livechat is supported
    // setIsLiveChatOpen(true);
    setIsSupportOpen(true);
  };

  // const removeChatWidget = () => {
  //   setIsLiveChatOpen(false);
  //   removeTokenFromCache();
  // };
  //
  // const customerData = useWidgetCustomerData();
  // const chatState = useWidgetState();
  // const chatData = useWidgetChatData();
  //
  // const handleVisibilityChanged = (data: Pick<WidgetState, 'visibility'>) => {
  //   if (
  //     data.visibility === 'minimized' &&
  //     customerData?.status === 'browsing'
  //   ) {
  //     removeChatWidget();
  //   }
  // };
  //
  // const [chatIssue, setChatIssue] = React.useState<string | undefined>(
  //   undefined
  // );
  //
  // function handleNewEvent(event: ChatEvent) {
  //   if (event.type !== 'message' || !chatData) return;
  //
  //   getChatIssue(chatData)
  //     .then((issue) => {
  //       if (issue) {
  //         setChatIssue(issue);
  //       }
  //     })
  //     .catch((error: Error) => {
  //       console.error(
  //         `LiveChat: Could not save chatbot custom variables. ${error?.message}`
  //       );
  //     });
  // }
  //
  // function handleCustomerStatusChanged(data: Pick<CustomerData, 'status'>) {
  //   if (data.status === 'browsing' && chatState?.visibility === 'minimized') {
  //     removeChatWidget();
  //   }
  // }

  // function renderLiveChatWidget() {
  //   const metadata = getMetadata(
  //     meQuery.data?.user,
  //     meQuery.data?.org,
  //     propertiesQuery.data
  //   );
  //
  //   if (chatIssue) {
  //     metadata.default_issue = chatIssue;
  //   }
  //
  //   return (
  //     <LiveChatWidget
  //       license={String(LICENSE_ID)}
  //       customerName={metadata.fullName ?? 'Visitor'}
  //       // We want `undefined` if the email is an empty string
  //       customerEmail={metadata.email !== '' ? metadata.email : undefined}
  //       customIdentityProvider={customIdentityProvider}
  //       onVisibilityChanged={handleVisibilityChanged}
  //       onCustomerStatusChanged={handleCustomerStatusChanged}
  //       onNewEvent={handleNewEvent}
  //       visibility="maximized"
  //       sessionVariables={metadata}
  //     />
  //   );
  // }

  return (
    <>
      <TechSupportButton onClick={handleTechSupportClick} />
      {/*{isLiveChatOpen && renderLiveChatWidget()}*/}
      {isSupportOpen && <Support onClose={() => setIsSupportOpen(false)} />}
    </>
  );
}
