import type { Column, IdType, Row } from 'react-table';
import type { Ward } from '#services/api/user';
import type { Appointment } from '#types/appointment';

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { add } from 'date-fns';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { ListDataTable } from '#components/DataTable';
import fuzzySearchMultipleWords from '#lib/react-table';
import { getFullName } from '#utils/user';
import DependentCard from './DependentCard';

// TODO tech debt added for the port
// eslint-disable-next-line react-refresh/only-export-components
export function globalFilter(
  rows: Row<Ward>[],
  _columnIds: IdType<Ward>[],
  globalFilterValue = ''
): Row<Ward>[] {
  return fuzzySearchMultipleWords(
    rows,
    ['original.firstName', 'original.lastName'],
    globalFilterValue
  );
}

const dependentColumns: Column<Ward>[] = [
  {
    Header: 'Name',
    id: 'name',
    accessor: getFullName,
    Filter: () => null,
  },
  {
    id: 'birthDate',
    Header: 'Birthdate',
    accessor: (ward) => {
      const [year, month, day] = ward.birthDate.split('-');
      return `${month}/${day}/${year}`;
    },
    Filter: () => null,
  },
  {
    id: 'gender',
    Header: 'Gender',
    accessor: 'gender',
    Filter: () => null,
  },
];

// TODO tech debt added for the port
// eslint-disable-next-line react-refresh/only-export-components
export const defaultFilters = [
  {
    id: 'actions',
    value: {
      id: 'today',
      start: new Date(),
      end: add(new Date(), { hours: 24 }),
    },
  },
];

const defaultSortBy = [{ id: 'name', desc: true }];

type DependentTableProps = {
  appointment: Appointment;
};

export default function DependentTable({
  appointment,
}: DependentTableProps): JSX.Element {
  const wards = React.useMemo(() => appointment.wards ?? [], [appointment]);

  const wardTableInstance = useTable<Ward>(
    {
      columns: dependentColumns,
      data: wards,
      initialState: {
        pageSize: 5,
        globalFilter: '',
        filters: defaultFilters,
        sortBy: defaultSortBy,
        pageIndex: 0,
      },
      manualFilters: true,
      globalFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Box textAlign="center" width="100%">
      {!wards.length ? (
        <Typography>No Dependents</Typography>
      ) : (
        <ListDataTable<Ward>
          instance={wardTableInstance}
          isEmptyMessage="No users found."
          isRejectedMessage="There was an error loading the users."
          Card={DependentCard}
        />
      )}
    </Box>
  );
}
