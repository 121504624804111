import type { User } from '#components/Autocomplete/Users/UsersAutocomplete.types';
import type { CreateInvitationResponse } from '#services/api/invitation';

import * as React from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import UsersAutocomplete from '#components/Autocomplete/Users/UsersAutocomplete';
import { isExistingUser } from '#components/Autocomplete/Users/UsersAutocomplete.utils';
import CopyButton from '#components/CopyButton/CopyButton';
import useAsync from '#hooks/async';
import { createInvitation } from '#services/api/invitation';
import { WidgetCard } from './WidgetCard';

const toastAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
} as const;

const getStringifiedUsersList = (users: User[]) =>
  users
    .map((user) =>
      isExistingUser(user)
        ? `${user.firstName} ${user.lastName}`
        : user.formattedValue
    )
    .join(', ');

export type InviteModalProps = {
  defaultInvitees?: User | User[];
  defaultMessage?: string;
  url: string;
  /**
   * Get the url to be used in the invitation specific to the user selected, if
   * omitted or `undefined` is returned, `url` will be used.
   */
  getUserUrl?: (user: User) => string | undefined;
  getUserCustomVariables?: (user: User) => Record<string, string | number>;
  onSuccess?: () => void;
};

/**
 * TODO - TECH DEBT
 *
 * This component was originally copied as a modal from the dashboard but it
 * was refactored here to be used in a card, therefore once this is moved to
 * the unified components library, it should be refactored by leveraging
 * composition so that we can split the different jsx/logic appropriately and
 * support different UI variants.
 */
export default function Invite(props: InviteModalProps): JSX.Element {
  const [users, setUsers] = React.useState<User[]>(() => {
    if (!props.defaultInvitees) return [];
    return Array.isArray(props.defaultInvitees)
      ? props.defaultInvitees
      : [props.defaultInvitees];
  });
  const [message, setMessage] = React.useState(props.defaultMessage ?? '');
  const { run, isLoading } =
    useAsync<PromiseSettledResult<CreateInvitationResponse>[]>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleShare() {
    /**
     * Can't create a single `const isExisting = isExistingUser(user);` because
     * the TypeScript compiler throws errors due to how the type guard works.
     */
    const promises = users.map((user) =>
      createInvitation({
        type: 'Invite User',
        url: props.getUserUrl?.(user) ?? props.url,
        fullName: isExistingUser(user)
          ? `${user.firstName} ${user.lastName}`
          : 'Anonymous',
        variables: {
          firstName: isExistingUser(user)
            ? String(user.firstName)
            : 'Anonymous',
          customUserInviteText: message.trim(),
          ...props.getUserCustomVariables?.(user),
        },
        mobile: isExistingUser(user)
          ? user.mobile
            ? String(user.mobile)
            : null
          : user.type === 'phone-number'
            ? user.value
            : undefined,
        email: isExistingUser(user)
          ? user.email
          : user.type === 'email'
            ? user.value
            : undefined,
      })
    );

    void run(
      Promise.allSettled(promises).then((results) => {
        const rejectedUsers: User[] = [];
        const resolvedUsers: User[] = [];
        results.forEach((result, index) => {
          // Safe to do `as User` because `results` maps 1:1 with `users`
          const user = users[index] as User;
          if (result.status === 'rejected') {
            rejectedUsers.push(user);
          } else {
            resolvedUsers.push(user);
          }
        });

        if (rejectedUsers.length === 0) {
          // Everything went well
          enqueueSnackbar('Invitation sent to users.', {
            variant: 'success',
            anchorOrigin: toastAnchorOrigin,
          });
          props.onSuccess?.();
        } else {
          const someUsersWereInvited = resolvedUsers.length > 0;

          if (someUsersWereInvited) {
            enqueueSnackbar(
              `Invitation sent to ${getStringifiedUsersList(resolvedUsers)}.`,
              { variant: 'success', anchorOrigin: toastAnchorOrigin }
            );
          }

          const users = someUsersWereInvited
            ? getStringifiedUsersList(rejectedUsers)
            : 'users';
          enqueueSnackbar(`Unable to invite ${users}.`, {
            variant: 'error',
            persist: true,
            anchorOrigin: toastAnchorOrigin,
            action: (key) => (
              <Button
                disableElevation
                size="small"
                color="error"
                onClick={() => closeSnackbar(key)}
              >
                Dismiss
              </Button>
            ),
          });

          // Remove from state those who were invited
          setUsers(rejectedUsers);
        }

        return results;
      })
    );
  }

  return (
    <WidgetCard title="Invite">
      <Stack direction="column" spacing={2}>
        <Alert severity="warning" variant="standard">
          <AlertTitle color="inherit">
            This communication is not secure!
          </AlertTitle>
          Do not send PHI in a text message
        </Alert>

        <Box textAlign="right">
          <CopyButton value={props.url}>Get shareable link</CopyButton>
        </Box>

        <div>
          <Typography component="p" variant="h6">
            People
          </Typography>
          <Typography variant="body1">
            You can invite multiple users at the same time.
          </Typography>
        </div>

        <UsersAutocomplete
          disablePortal
          id="invite-usersautocomplete"
          multiple
          enableCustomEmail
          enableCustomPhoneNumber
          value={users}
          onChange={(_event, newValue) => setUsers(newValue)}
        />

        <div>
          <Typography component="p" variant="h6">
            Message
          </Typography>
          <Typography variant="body1">
            You can edit the message below: We will send a link to this call
            along with your message.
          </Typography>
          <Typography variant="body1" color="error.main">
            Caution: Any person joining from this link will bypass
            organization-specific consents.
          </Typography>
        </div>

        <TextField
          multiline
          fullWidth
          label="Message"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          rows={4}
        />

        <Button
          disabled={isLoading || users.length === 0 || message.trim() === ''}
          onClick={handleShare}
          fullWidth
        >
          Invite
        </Button>
      </Stack>
    </WidgetCard>
  );
}
