import { z } from 'zod';

const baseUrl = window.location.origin;
const apiBaseUrl = baseUrl.replace('app', 'api');
const portalBaseUrl = baseUrl.replace('app', 'portal');
const loggingBaseUrl = baseUrl.replace('app', 'logging');

const envSchema = z
  .object({
    VITE_API_BASE_URL: z.string().url().catch(apiBaseUrl),
    VITE_PORTAL_BASE_URL: z.string().url().catch(portalBaseUrl),
    VITE_LOGGING_BASE_URL: z.string().url().catch(loggingBaseUrl),
  })
  .transform((data) => ({
    API_BASE_URL: data.VITE_API_BASE_URL,
    PORTAL_BASE_URL: data.VITE_PORTAL_BASE_URL,
    LOGGING_BASE_URL: data.VITE_LOGGING_BASE_URL,
    SUPPORT_EMAIL: 'bestservice@mend.com',
  }));

export const config = envSchema.parse(import.meta.env);
