import type { QueryClient } from '@tanstack/react-query';
import type { LoaderFunctionArgs } from 'react-router-dom';
import type { Properties } from '#services/api/properties.ts';
import type { SidebarConfig } from './Session.utils';

import { config } from '#config';
import * as queries from '#lib/react-query/queries';
import { getZoomAnnotations } from '#services/api/annotation';
import {
  getAppointment,
  getGroupUsers,
  getStatusTypes,
} from '#services/api/appointment';
import {
  getZoomSignature,
  registerZoomUser,
  trackPageLoad,
  unsafeGetDetails,
} from '#services/api/session';
import { getSettings, getUser } from '#services/api/user';
import { useOrgIdStore } from '#stores/org-id.ts';
import { canBeBookedAgain } from '#utils/appointments.ts';
import { getAccessToken } from '#utils/cookies';
import { validateShortCode } from '#utils/languages';
import { getSpeedDials } from '#utils/phone.ts';
import { PERMISSION_STAFF_NO_SHOW, SIDEBAR_CONFIG } from '#utils/properties';
import { isProviderLike, roleGreaterOrEquals } from '#utils/roles';
import { getZoomMappedLanguage } from '#utils/zoom';
import {
  getSearchParamFromJoinUrl,
  getUniqueIdentifier,
  getUserDisplayName,
  InvalidLinkError,
  removeUniqueIdentifier,
} from './Session.utils';

export const loader =
  (queryClient: QueryClient) =>
  async ({ params: { sessionId } }: LoaderFunctionArgs) => {
    if (!sessionId) {
      throw new Error('Invalid Session ID');
    }

    const isUnauthenticated = !getAccessToken();

    /**
     * If there's no access token, or it's invalid, default to `null` so we can
     * continue without throwing an error.
     */
    const [details, me, settings] = await Promise.all([
      unsafeGetDetails(sessionId).then((response) => {
        if (
          response.messages?.[0]?.type === 'Error' &&
          typeof response.messages?.[0]?.text === 'string'
        ) {
          throw new InvalidLinkError(response.messages[0].text);
        }

        // Handle other errors we might receive that appear AFTER the first success message
        const errorMessage = Array.isArray(response.messages)
          ? response.messages.find((message) => message.type === 'Error')
          : undefined;

        if (errorMessage) {
          throw new Error(errorMessage.text ?? 'Something went wrong');
        }

        if (!response.payload.video.session) {
          throw new Error('Missing `session`');
        }
        return response.payload.video;
      }),
      isUnauthenticated
        ? Promise.resolve(null)
        : queryClient.ensureQueryData(queries.me).catch(() => null),
      isUnauthenticated
        ? Promise.resolve(null)
        : getSettings().catch(() => null),
    ] as const);

    useOrgIdStore.getState().setOrgId(details.orgId);

    if (details.vendorName !== 'zoomMeetingsSDK') {
      window.location.replace(`${config.PORTAL_BASE_URL}/video/${sessionId}`);
    }

    trackPageLoad(sessionId);

    const isKioskUser = me?.user.role === 'Kiosk';
    const isStaffPlus = roleGreaterOrEquals(me?.user.role, 'Staff');
    const isProvider = isProviderLike(me?.user.role);

    const [
      signature,
      annotations,
      properties,
      appointment,
      appointmentStatuses,
    ] = await Promise.all([
      getZoomSignature(details.session),
      /**
       * Unauthenticated users when registration is required will get the data
       * from the registration itself, we can skip fetching the annotations in
       * this case.
       */
      !details.requiresRegistration || me
        ? getZoomAnnotations(sessionId)
        : Promise.resolve([]),
      queryClient
        .ensureQueryData(queries.properties(me ? undefined : details.orgId))
        .catch(() => ({}) as Properties),
      isStaffPlus && details.appointmentId
        ? getAppointment(details.appointmentId)
        : Promise.resolve(null),
      isStaffPlus ? getStatusTypes().catch(() => []) : Promise.resolve([]),
    ] as const);

    const appointmentUsers =
      isStaffPlus && appointment
        ? appointment.groupId
          ? await getGroupUsers(appointment.id).catch(() => [])
          : await getUser(appointment.primaryPatientId)
              .then((user) => [user])
              .catch(() => [])
        : [];

    // Build the customerKey for use with Zoom
    if (me) {
      removeUniqueIdentifier();
    }
    const customerKey = JSON.stringify({
      userId: me ? me.user.id : getUniqueIdentifier(),
      // orgId: details.orgId,
      // appointmentId: details.appointmentId,
      // // This adhoc key cannot be inferred and is needed due to the way the API validates webhooks for Opentok
      // adhoc: details.appointmentId ? 0 : 1,
      // isApp: false,
      // host: new URL(config.API_BASE_URL).host,
    });

    const langShortCode = validateShortCode(
      settings?.language.split('_')[0],
      'en'
    );

    const userName = getUserDisplayName(details.isGroupAppointment, me?.user);

    let joinUrl: string | undefined;
    let tk: string | undefined;

    if (details.requiresRegistration && !isStaffPlus) {
      const userId = me ? me.user.id : null;

      /**
       * Previously registered and authenticated users will have the `JoinURL`
       * already stored as an annotation, if this is the case, we can skip the
       * registration request.
       */
      joinUrl = annotations.find(
        (annotation) =>
          annotation.type === 'JoinURL' && annotation.userId === userId
      )?.content;

      if (!joinUrl) {
        const registration = await registerZoomUser(sessionId);
        joinUrl = registration.join_url;
      }

      tk = getSearchParamFromJoinUrl(joinUrl, 'tk');
    } else {
      joinUrl = annotations.find(
        (annotation) => annotation.type === 'JoinURL'
      )?.content;

      if (!joinUrl) throw new Error('Missing `JoinURL`');
    }

    const meetingNumber = String(details.session);

    const zoomIframeSearchParams = new URLSearchParams();
    zoomIframeSearchParams.append('customerKey', customerKey);
    zoomIframeSearchParams.append('meetingNumber', meetingNumber);
    zoomIframeSearchParams.append('sdkKey', String(signature.clientId));
    zoomIframeSearchParams.append('signature', String(signature.signature));
    zoomIframeSearchParams.append('userName', userName);
    zoomIframeSearchParams.append(
      'passWord',
      getSearchParamFromJoinUrl(joinUrl, 'pwd')
    );

    /**
     * `tk` will be `undefined` unless registration is required and user is
     * non-staff+.
     */
    if (tk) {
      zoomIframeSearchParams.append('tk', tk);
    }

    if (details.appointmentId) {
      zoomIframeSearchParams.append('appointmentId', details.appointmentId);
    }

    if (details.zak) {
      zoomIframeSearchParams.append('zak', details.zak);
    }

    const zoomLanguage = getZoomMappedLanguage(langShortCode);
    if (zoomLanguage !== 'en-US') {
      zoomIframeSearchParams.set('lang', zoomLanguage);
    }

    const sidebarConfig = properties[SIDEBAR_CONFIG] as
      | SidebarConfig
      | undefined;

    const sidebarDefaultItem = isStaffPlus
      ? appointment
        ? ('patientInfo' as const)
        : ('invite' as const)
      : undefined;

    const isNoShowEnabled =
      isStaffPlus && (properties[PERMISSION_STAFF_NO_SHOW] ?? 0) === 1;

    const isBookAgainEnabled = canBeBookedAgain({
      user: me?.user,
      org: me?.org,
      appointment,
      properties,
    });

    const visitWith = (details.attendees ?? []).find(
      (attendee) =>
        attendee.role === 'Provider' || attendee.role === 'Provider Admin'
    )?.name;

    const speedDials = isStaffPlus
      ? getSpeedDials(properties, appointment?.provider, appointment?.patient)
      : [];

    return {
      appointment,
      appointmentStatuses,
      customerKey,
      joinUrl,
      isKioskUser,
      isStaffPlus,
      isProvider,
      langShortCode,
      meetingNumber,
      org: me?.org ?? null,
      properties,
      session: details,
      sessionId,
      sidebarConfig,
      sidebarDefaultItem,
      user: me?.user ?? null,
      userName,
      zoomIframeSearchParams: zoomIframeSearchParams.toString(),
      isNoShowEnabled,
      isBookAgainEnabled,
      visitWith,
      speedDials,
      appointmentUsers,
    };
  };

export type LoaderData = Awaited<ReturnType<ReturnType<typeof loader>>>;
