import type { Response } from '#types/common';

import { api } from '../http-clients';

//TODO Map invite object
export type InvitationCreated = {
  id: string | number;
};

export type CreateInvitationPayload = {
  //TODO(Add more known fields)
  type: string;
  url: string;
  fullName: string;
  variables: {
    firstName: string;
    customUserInviteText?: string;
    sessionCode?: string;
  };
  mobile?: string | null;
  email?: string | null;
};

export type CreateInvitationResponse = {
  invitation: InvitationCreated;
  videoSessionCode: string | number;
};

export const createInvitation = (
  invitation: CreateInvitationPayload
): Promise<CreateInvitationResponse> =>
  api
    .post<Response<CreateInvitationResponse>>('/invitation', invitation)
    .then((res) => {
      return {
        invitation: res.data.payload.invitation,
        videoSessionCode: invitation.variables?.sessionCode ?? '',
      };
    });
