import type { TextFieldProps } from '@mui/material';

import { MenuItem, TextField } from '@mui/material';

const options = Array(20)
  .fill(0)
  .map((_, i) => {
    const days = i + 1;
    return {
      id: i,
      days: i < 6 ? days : (days - 6) * 7,
      label:
        i < 6
          ? `${days} ${i === 0 ? 'day' : 'days'}`
          : `${days - 6} ${i === 6 ? 'week' : 'weeks'}`,
    };
  });

type RepeatEveryProps = Omit<
  TextFieldProps,
  'margin' | 'onChange' | 'select' | 'value'
> & {
  value: number;
  onChange: (value: number) => void;
};

export default function RepeatEvery({
  fullWidth = true,
  id = 'repeat-every',
  name = 'repeat-every',
  label = 'Repeat Every',
  onChange,
  ...props
}: RepeatEveryProps): JSX.Element {
  return (
    <TextField
      select
      fullWidth={fullWidth}
      id={id}
      name={name}
      label={label}
      onChange={(e) => onChange(Number(e.target.value))}
      {...props}
    >
      {options.map(({ id, days, label }) => (
        <MenuItem key={id} value={days}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}
