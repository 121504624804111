import type { SxProps } from '@mui/material';

import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export type InformationItem<T extends Record<string, unknown>> = {
  id: string;
  name: string;
  accessor:
    | Extract<keyof T, string | number>
    | ((record: T) => string | number | JSX.Element);
  breakLine?: boolean;
};

export type InformationCardProps<T extends Record<string, unknown>> = {
  title?: string;
  model: T;
  items: InformationItem<T>[];
  paperSx?: SxProps;
  loading?: boolean;
  children?: React.ReactNode;
};

const LoaderWrapper = styled(Box)(() => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  left: 0,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
}));

const DEFAULT_LINE_BREAK = true;

export default function InformationCard<T extends Record<string, unknown>>({
  title,
  model,
  items,
  paperSx,
  loading = false,
  children,
}: InformationCardProps<T>): JSX.Element {
  return (
    <Paper elevation={3} sx={paperSx}>
      <Box py={2} px={3}>
        {title && (
          <Typography component="p" variant="subtitle3" sx={{ pb: 3 }}>
            {title}
          </Typography>
        )}
        <Box position={loading ? 'relative' : 'static'}>
          {items.map(
            ({ id, name, accessor, breakLine = DEFAULT_LINE_BREAK }) => {
              const result =
                typeof accessor === 'string' || typeof accessor === 'number'
                  ? model[accessor]
                  : accessor(model);
              return (
                <Stack
                  key={`card-inf-${id}`}
                  justifyContent="space-between"
                  alignItems={breakLine ? 'flex-start' : 'center'}
                  direction={breakLine ? 'column' : 'row'}
                  spacing={typeof result === 'string' ? 0 : 1}
                  sx={{
                    '&:not(:last-child)': {
                      marginBottom: (theme) => theme.spacing(3),
                    },
                  }}
                >
                  <Typography
                    component="div"
                    variant="subtitle1"
                    fontWeight="600"
                  >
                    {name}
                  </Typography>
                  {typeof result === 'string' ? (
                    <Typography variant="body1">{result}</Typography>
                  ) : (
                    (result as React.ReactNode)
                  )}
                </Stack>
              );
            }
          )}
          {children}
          {loading && (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
