import type { QueryClient } from '@tanstack/react-query';
import type { RouteObject } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import { createBrowserRouter } from 'react-router-dom';

import Home from './Home/Home';
import { loader as homeLoader } from './Home/Home.data';
import { Layout, Error as LayoutError } from './Layout/Layout';
import { loader as layoutLoader } from './Layout/Layout.data';
import NotFound from './NotFound/NotFound';
import { Session, Error as SessionError } from './Session/Session';
import { loader as sessionloader } from './Session/Session.data';

const snackbarProviderAnchorOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
} as const;

const snackbarProviderClasses = {
  containerAnchorOriginBottomLeft: 'clearZoomToolbar',
  containerAnchorOriginBottomCenter: 'clearZoomToolbar',
  containerAnchorOriginBottomRight: 'clearZoomToolbar',
} as const;

/**
 * Get the routes for the application.
 *
 * Even though we always return a static set of routes, we return them from the
 * function because they need to be defined when we create the router itself in
 * our tests after we've defined our http handlers so the loaders/actions work
 * as expected.
 *
 * @see https://stackoverflow.com/a/74185147
 */
export function getRoutes(queryClient: QueryClient): RouteObject[] {
  return [
    {
      path: '/',
      element: <Layout />,
      errorElement: <LayoutError />,
      loader: layoutLoader(queryClient),
      children: [
        {
          index: true,
          element: <Home />,
          loader: homeLoader,
        },
        {
          /**
           * Accept an optional `notificationId` segment so the links from an
           * invitation (email or sms) work appropriately. This prevents the
           * app from throwing a 404 but as of 06/26/2024 we're NOT doing
           * anything with it.
           */
          path: '/sessions/:sessionId/:notificationId?',
          element: (
            <SnackbarProvider
              maxSnack={5}
              disableWindowBlurListener
              anchorOrigin={snackbarProviderAnchorOrigin}
              classes={snackbarProviderClasses}
            >
              <Session />
            </SnackbarProvider>
          ),
          loader: sessionloader(queryClient),
          errorElement: <SessionError />,
          handle: { title: 'Video Visit' },
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ];
}

export const createRouter = (queryClient: QueryClient) =>
  createBrowserRouter(getRoutes(queryClient));
