import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { styled } from '@mend/mui-theme';
import { grey } from '@mend/mui-theme/colors';
import { Button, Stack, Tooltip, Typography } from '@mui/material';

import FontAwesomeScalableIcon from '#components/FontAwesomeScalableIcon.tsx';

const OuterContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const InnerContainer = styled('div')({
  maxWidth: '500px',
  width: '100%',
});

const IconWrapper = styled('span')({
  width: '20px',
  height: '20px',
});

type LabelProps = {
  label: string;
  tooltipText: string;
};

function Label(props: LabelProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      marginTop={3}
      marginBottom={1}
    >
      <Typography variant="subtitle1" component="p">
        {props.label}
      </Typography>

      <Tooltip title={props.tooltipText} placement="right">
        <IconWrapper tabIndex={0}>
          <FontAwesomeScalableIcon icon={faCircleInfo} fontSize="small" />
        </IconWrapper>
      </Tooltip>
    </Stack>
  );
}

type JoinMethodProps = {
  visitWith?: string;
  joinViaAppUrl: string;
  onJoinViaWeb: () => void;
};

export function JoinMethod(props: JoinMethodProps) {
  return (
    <OuterContainer>
      <InnerContainer>
        <Typography variant="h4" component="h2">
          Join video call
        </Typography>
        {props.visitWith ? (
          <Typography variant="body2" color={grey[700]}>
            Visit with: {props.visitWith}
          </Typography>
        ) : null}

        <Label
          label="For Better Connectivity"
          tooltipText="Use this option if you have the Zoom app installed, are on a lower-quality machine, or expect connectivity issues."
        />
        <Button
          href={props.joinViaAppUrl}
          rel="noreferrer noopener"
          size="extra-large"
          fullWidth
        >
          Join via app
        </Button>

        <Label
          label="For Quick and Easy Experience"
          tooltipText="Use this option if you're unsure whether you have the Zoom app installed. If you experience issues, you can switch to the app during the visit."
        />
        <Button size="extra-large" fullWidth onClick={props.onJoinViaWeb}>
          Join via web
        </Button>
      </InnerContainer>
    </OuterContainer>
  );
}
