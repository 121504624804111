import * as React from 'react';

export default function useModal(initialIsOpen = false): {
  isOpen: boolean;
  open: () => void;
  dismiss: () => void;
  toggle: () => void;
  setTo: (value: boolean) => void;
} {
  const [isOpen, setIsOpen] = React.useState(initialIsOpen);
  const open = React.useCallback(() => setIsOpen(true), []);
  const dismiss = React.useCallback(() => setIsOpen(false), []);
  const toggle = React.useCallback(
    () => setIsOpen((prevIsOpen) => !prevIsOpen),
    []
  );
  const setTo = React.useCallback((value: boolean) => setIsOpen(value), []);
  return { isOpen, open, dismiss, toggle, setTo };
}
