import type * as React from 'react';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { format } from 'date-fns';

// Taken directly as is from the package definition
type Params = {
  monthDate: Date;
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  // Custom prop
  isSingle?: boolean;
};

export default function renderCustomHeader({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  isSingle = true,
}: Params): React.ReactNode {
  return (
    <Stack alignItems="center" justifyContent="space-between">
      <IconButton
        size="small"
        aria-label="Previous Month"
        sx={{
          visibility:
            !isSingle && customHeaderCount !== 0 ? 'hidden' : undefined,
        }}
        disabled={prevMonthButtonDisabled}
        onClick={decreaseMonth}
      >
        <ChevronLeft />
      </IconButton>

      <span className="react-datepicker__current-month">
        {format(monthDate, 'MMM yyyy')}
      </span>

      <IconButton
        size="small"
        aria-label="Next Month"
        sx={{
          visibility:
            !isSingle && customHeaderCount !== 1 ? 'hidden' : undefined,
        }}
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
      >
        <ChevronRight />
      </IconButton>
    </Stack>
  );
}
