import type { AutocompleteProps } from '@mui/material';
import type { AppointmentType } from '#types/appointment';
import type { BaseAutocompleteInputProps } from './Autocomplete.types';

import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { matchSorter } from '#lib/match-sorter';
import { appointmentTypes as appointmentTypesQuery } from '#lib/react-query/queries';
import { getTextFieldProps } from './Autocomplete.utils';

const getOptionLabel = (appointmentType: AppointmentType) =>
  `${appointmentType.name} - ${appointmentType.minutes} minutes`;

type AppointmentTypesAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<AppointmentType, Multiple, DisableClearable, false>,
  | 'loading'
  | 'options'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'isOptionEqualToValue'
  | 'onInputChange'
  | 'renderInput'
  | 'renderOption'
  | 'renderTags'
  | 'freeSolo'
> &
  BaseAutocompleteInputProps<AppointmentType>;

const defaultDataFilter = (options: AppointmentType[]) => options;

export default function AppointmentTypesAutocomplete<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
>({
  inputName = 'appointment-type',
  inputLabel = 'Appointment Type',
  inputHelperText,
  inputError,
  inputRequired,
  fullWidth = true,
  filterSelectedOptions = true,
  clearOnBlur = false,
  selectOnFocus = true,
  handleHomeEndKeys = true,
  value,
  multiple,
  dataFilter = defaultDataFilter,
  ...props
}: AppointmentTypesAutocompleteProps<Multiple, DisableClearable>): JSX.Element {
  const { data, isLoading } = useQuery(appointmentTypesQuery());

  const appointmentTypes = React.useMemo(
    () => dataFilter(data ?? []),
    [data, dataFilter]
  );

  return (
    <Autocomplete
      value={value}
      freeSolo={false}
      multiple={multiple}
      fullWidth={fullWidth}
      clearOnBlur={clearOnBlur}
      selectOnFocus={selectOnFocus}
      handleHomeEndKeys={handleHomeEndKeys}
      loading={isLoading}
      options={appointmentTypes}
      getOptionLabel={getOptionLabel}
      filterSelectedOptions={filterSelectedOptions}
      filterOptions={(options, { inputValue }) =>
        matchSorter(options, inputValue, { keys: ['name'] })
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {getOptionLabel(option)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...getTextFieldProps({ params, multiple, inputRequired })}
          name={inputName}
          label={inputLabel}
          helperText={inputHelperText}
          error={inputError}
        />
      )}
      {...props}
    />
  );
}
