import { create } from 'zustand';

import { getCookie, setCookie } from '#utils/cookies.ts';

type OrgIdStore = {
  /**
   * The organization ID.
   */
  orgId: number | undefined;
  /**
   * Set the organization ID.
   */
  setOrgId: (value: number) => void;
};

/**
 * Store used to keep track of the organization ID.
 */
export const useOrgIdStore = create<OrgIdStore>()((set) => ({
  // Initialize the orgId from the 'last-org' cookie if available
  orgId: (() => {
    const cookieValue = getCookie('last-org');
    const parsedValue = cookieValue ? parseInt(cookieValue, 10) : NaN;
    return isNaN(parsedValue) ? undefined : parsedValue;
  })(),

  // Setter that updates the state and stores the value in a cookie
  setOrgId: (value: number) => {
    set({ orgId: value });
    // Store the value in the 'last-org' cookie
    setCookie('last-org', value.toString());
  },
}));
