import type { Properties } from '#services/api/properties.ts';

export const END_ALL_AND_CHECK_OUT_ENABLED = 'video.endAllAndCheckOut.enabled';

export function isEndAllAndCheckOutEnabled(
  properties: Properties | null
): boolean {
  return properties?.[END_ALL_AND_CHECK_OUT_ENABLED] === 1;
}

export const ALLOW_PATIENT_CANCEL_RESCHEDULE =
  'scheduling.patients.allowPatientCancelReschedule';

export const BRANDING_CONFIG = 'settings.branding.config';

export const SIDEBAR_CONFIG = 'video.zoom.sidebar';

export const PERMISSION_STAFF_NO_SHOW = 'permissions.staff.noShow';

export const ALLOW_STAFF_SCHEDULING = 'scheduling.staff.allow';

export const ALLOW_PATIENT_REBOOK = 'settings.scheduling.rebook';

export const ORG_SETTINGS_SPEED_DIALS = 'org.settings.video.speedDials';
