import type {
  AutocompleteRenderInputParams,
  TextFieldProps,
} from '@mui/material';
import type { BaseAutocompleteInputProps } from './Autocomplete.types';

type GetTextFieldPropsOptions<
  Multiple extends boolean | undefined = undefined,
> = Pick<BaseAutocompleteInputProps, 'inputRequired'> & {
  multiple: Multiple;
  params: AutocompleteRenderInputParams;
};

/**
 * Dynamically generate the required attributes for <TextField />.
 *
 * @see https://stackoverflow.com/questions/62645466/how-to-make-autocomplete-field-of-material-ui-required#comment129083754_64537120
 * @see https://github.com/mui/material-ui/issues/20840#issuecomment-659582972
 */
export const getTextFieldProps = <
  Multiple extends boolean | undefined = undefined,
>({
  params,
  multiple,
  inputRequired,
}: GetTextFieldPropsOptions<Multiple>): Partial<TextFieldProps> => ({
  ...params,
  required: multiple ? undefined : inputRequired,
  InputLabelProps: {
    ...params.InputLabelProps,
    required: multiple ? inputRequired : undefined,
  },
});
