import genericErrorUrl from '../assets/images/errors/generic-error.png';
import { config } from '../config';
import RouteError from './RouteError';

export default function GenericRouteError() {
  return (
    <RouteError
      illustrationUrl={genericErrorUrl}
      title="Oh no, something didn't work right!"
      message={
        <span>
          Please try hitting the refresh button. If it&apos;s still not working,
          email us at{' '}
          <a href={`mailto:${config.SUPPORT_EMAIL}`}>{config.SUPPORT_EMAIL}</a>{' '}
          and we&apos;ll help you out.
        </span>
      }
    />
  );
}
