import notFoundUrl from '#assets/images/errors/404.png';
import RouteError from '#routes/RouteError';

export default function NotFound() {
  return (
    <RouteError
      illustrationUrl={notFoundUrl}
      title="404"
      message="Sorry, the page you're looking for couldn't be found."
    />
  );
}
