import type { Status } from '#types/appointment.ts';

import { Box, Chip } from '@mui/material';

interface PatientStatusesProps {
  patientStatuses: Status[];
}

export function PatientStatuses({ patientStatuses }: PatientStatusesProps) {
  return (
    <Box
      component="ul"
      sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 0, m: 0 }}
    >
      {patientStatuses.map(
        ({ id, name, statusProperty: { color, backgroundColor } }) => (
          <Box key={id} component="li" sx={{ listStyleType: 'none' }}>
            <Chip
              size="small"
              label={name}
              sx={{
                color,
                backgroundColor,
                ':hover, :focus': {
                  backgroundColor,
                  backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))',
                },
              }}
            />
          </Box>
        )
      )}
    </Box>
  );
}
