import {
  green_default,
  grey_default,
  mend_teal_default,
  pink_default,
  red_default,
  yellow_default
} from "./chunk-GUE7K7H5.js";

// src/theme.ts
import { createTheme } from "@mui/material/styles";
var fontFamily = ['"Raleway"', "sans-serif"].join(",");
var avatarOverrides = {
  width: 32,
  height: 32,
  fontSize: 16,
  fontWeight: 500
};
var themeOptions = {
  palette: {
    primary: {
      main: mend_teal_default[700]
    },
    secondary: {
      main: pink_default[1e3]
    },
    navbar: {
      /**
       * Must be kept in sync with primary color in case it doesn't get
       * overridden.
       */
      main: mend_teal_default[700]
    },
    success: {
      main: green_default[900]
    },
    error: {
      main: red_default[600]
    },
    warning: {
      main: yellow_default[700]
    }
  },
  typography: {
    fontFamily,
    h1: { fontWeight: 500, color: grey_default[900] },
    h2: { fontWeight: 500, color: grey_default[900] },
    h3: { fontWeight: 500, color: grey_default[900] },
    h4: { fontWeight: 500, color: grey_default[900] },
    h5: { fontWeight: 500, color: grey_default[900] },
    h6: { fontWeight: 500, color: grey_default[900] },
    subtitle1: { fontWeight: 500, color: grey_default[800] },
    subtitle2: { fontWeight: 500, color: grey_default[800] },
    subtitle3: {
      fontFamily,
      fontWeight: 700,
      fontSize: "1.125rem",
      lineHeight: 1.6,
      color: grey_default[800]
    },
    body1: { fontWeight: 500, color: grey_default[800] },
    body2: { fontWeight: 500, color: grey_default[800] },
    button: { fontWeight: 600 },
    caption: { fontWeight: 500, color: grey_default[800] },
    captionLight: {
      /**
       * Since this is a custom variant we need to define all properties,
       * everything but the color was copied from the "caption" variant.
       */
      fontFamily,
      fontWeight: 500,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      color: grey_default[600]
    },
    overline: { fontWeight: 500, color: grey_default[600] }
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: (props) => ({
          ...avatarOverrides,
          backgroundColor: props.theme.palette.primary.main,
          color: props.theme.palette.primary.contrastText
        })
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: (props) => ({
          ...avatarOverrides,
          backgroundColor: props.theme.palette.primary.main,
          color: props.theme.palette.primary.contrastText
        })
      }
    },
    MuiAlert: {
      defaultProps: {
        variant: "filled"
      }
    },
    MuiButton: {
      variants: [
        {
          props: { size: "extra-large" },
          style: {
            padding: "12px 32px",
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.009375em"
            // 0.15px
          }
        },
        {
          props: { size: "extra-large", variant: "outlined" },
          style: {
            padding: "11px 31px"
            // To account for border in this variant
          }
        }
      ],
      defaultProps: {
        variant: "contained",
        color: "primary"
      },
      styleOverrides: {
        root: {
          textTransform: "capitalize"
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small"
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: red_default[600]
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        size: "small"
      }
    },
    MuiStack: {
      defaultProps: {
        direction: "row",
        spacing: 1
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 12,
          color: grey_default[800]
        }
      }
    },
    MuiTablePagination: {
      defaultProps: {
        showFirstButton: true,
        showLastButton: true
      },
      // Override BS+Main
      styleOverrides: {
        selectLabel: {
          marginBottom: 0
        },
        displayedRows: {
          marginBottom: 0
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: "small"
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: grey_default[600],
          color: "white",
          padding: 6,
          fontSize: 12
        },
        arrow: {
          color: grey_default[600],
          borderColor: grey_default[600]
        }
      }
    }
  }
};
var baseTheme = createTheme(themeOptions);
var theme = createTheme(baseTheme, {
  palette: {
    navbar: baseTheme.palette.augmentColor({
      color: { main: baseTheme.palette.navbar.main },
      name: "navbar"
    })
  }
});

// src/styled.ts
import { createStyled } from "@mui/system";
var styled = createStyled({ defaultTheme: theme });
export {
  styled,
  theme,
  themeOptions
};
