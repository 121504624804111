import { Stack, Typography } from '@mui/material';

import DynamicAvatar from '#components/DynamicAvatar';

export type ProviderInfoProps = {
  name: string;
  showAvatar?: boolean;
  specialty?: string | null;
  credentials?: string | null;
  avatar?: React.ReactElement;
  avatarUrl?: string;
  children?: React.ReactNode;
};

/**
 * Although not exactly a cell, it is indeed a component that needs to wrap the
 * cell content because different "models" like Assessments/Appointments have a
 * different structure for the `original` property of the row, therefore
 * we need to pass the info based on these different structures.
 */
export default function ProviderInfo({
  name,
  showAvatar = true,
  specialty,
  credentials,
  avatar,
  avatarUrl,
  children,
}: ProviderInfoProps): JSX.Element {
  const info = [];
  if (specialty) info.push(specialty);
  if (credentials) info.push(credentials);

  return (
    <Stack alignItems="center">
      {showAvatar ? (
        avatar ? (
          avatar
        ) : (
          <DynamicAvatar alt={name} src={avatarUrl} />
        )
      ) : null}

      <div>
        <Typography
          variant="inherit"
          sx={{ wordBreak: 'normal', overflowWrap: 'anywhere' }}
        >
          {name}
        </Typography>
        {info.length > 0 && (
          <Typography display="block" variant="caption">
            {info.join(', ')}
          </Typography>
        )}
        {children ? (
          <Typography display="block" variant="caption">
            {children}
          </Typography>
        ) : null}
      </div>
    </Stack>
  );
}
