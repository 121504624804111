import { addDays, addMinutes } from 'date-fns';
import Cookies from 'js-cookie';

const TOKEN_COOKIE = 'X-Access-Token';

const cookieDomain = window.location.hostname.split('.').slice(-2).join('.');
const defaultExpiration = addDays(new Date(), 30);

export function setCookie(
  cookieName: string,
  cookieValue: string,
  expires: Date | number = defaultExpiration
): void {
  Cookies.set(cookieName, cookieValue, {
    expires: expires,
    domain: cookieDomain,
    sameSite: 'None',
    secure: true,
  });
}

export function getCookie(cookieName: string, defaultValue: string): string;
export function getCookie(
  cookieName: string,
  defaultValue?: string
): string | undefined;

export function getCookie(
  cookieName: string,
  defaultValue?: string
): string | undefined {
  return Cookies.get(cookieName) ?? defaultValue;
}

export function removeCookie(cookieName: string): void {
  Cookies.remove(cookieName, { domain: cookieDomain });
}

export function getAccessToken() {
  return getCookie(TOKEN_COOKIE);
}

export function setAccessToken(token: string) {
  setCookie(TOKEN_COOKIE, token, addMinutes(new Date(), 60));
}
