import * as React from 'react';

export default function useSafeDispatch<T>(
  dispatch: React.Dispatch<T>
): (action: T) => void {
  const mounted = React.useRef(false);

  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return React.useCallback(
    (action) => (mounted.current ? dispatch(action) : void 0),
    [dispatch]
  );
}
