import type { TextFieldProps } from '@mui/material';

import { TextField } from '@mui/material';

const defaultInputLabelProps: TextFieldProps['InputLabelProps'] = {
  shrink: true,
};

type SymptomsType = Omit<
  TextFieldProps,
  'margin' | 'onChange' | 'select' | 'value'
> & {
  value: string;
  onChange: (value: string) => void;
};

export default function Symptoms({
  id = 'symptoms',
  name = 'symptoms',
  label = 'Symptoms',
  placeholder = 'No symptoms entered.',
  onChange,
  multiline = true,
  fullWidth = true,
  rows = 3,
  InputLabelProps = defaultInputLabelProps,
  ...props
}: SymptomsType): JSX.Element {
  return (
    <TextField
      id={id}
      name={name}
      label={label}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      multiline={multiline}
      fullWidth={fullWidth}
      rows={rows}
      InputLabelProps={InputLabelProps}
      {...props}
    />
  );
}
