import type { AvatarProps } from '@mui/material';

import * as React from 'react';
import { Avatar } from '@mui/material';

function formatNameForTextAvatar(name: string): string {
  return name
    .split(' ')
    .slice(0, 2)
    .map((word) => word[0])
    .join('');
}

/**
 * Simple wrapper for the Avatar component that displays the image if available,
 * else take the `alt` assuming it's a name (since that's what avatars are for)
 * and use it to get the text avatar.
 */
const DynamicAvatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  function DynamicAvatar({ alt, src, children, ...props }, forwardedRef) {
    return (
      <Avatar ref={forwardedRef} alt={alt} src={src} {...props}>
        {children
          ? children
          : alt && !src
            ? formatNameForTextAvatar(alt)
            : null}
      </Avatar>
    );
  }
);

export default DynamicAvatar;
//
