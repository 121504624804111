import type { TooltipProps } from '@mui/material';

import { Tooltip } from '@mui/material';

type CopyTooltipProps = Omit<
  TooltipProps,
  | 'title'
  | 'open'
  | 'disableFocusListener'
  | 'disableHoverListener'
  | 'disableTouchListener'
  | 'onOpen'
  | 'onClose'
> & {
  title?: NonNullable<React.ReactNode>;
  open: boolean;
};

export default function CopyTooltip({
  title = 'Copied',
  ...props
}: CopyTooltipProps): JSX.Element {
  return <Tooltip title={title} {...props} />;
}
