import type { SlotLength } from '#components/Autocomplete/SlotLengths/SlogLengthsAutocomplete.types';
import type { SlotRange } from '#components/TransferVisitModal/utils';
import type { CreateAppointmentParams } from '#services/api/appointment';
import type { Properties } from '#services/api/properties';
import type { Address, User } from '#services/api/user';
import type { Appointment, AppointmentType } from '#types/appointment';
import type { AppointmentDateRange } from '../BookAgainCalendar';

import * as React from 'react';
import { Button } from '@mui/material';
import { differenceInMinutes, isPast } from 'date-fns';

import useCalendarEvents from '#components/Calendar/hooks/calendar-events';
import * as Modal from '#components/Modal/Modal';
import { isInPerson } from '#utils/appointments';
import { convertUtcStringToLocalDate } from '#utils/dates';
import { roleGreaterOrEquals } from '#utils/roles';
import BookAgainCalendar from '../BookAgainCalendar';

export type CalendarProps = {
  appointment: Appointment;
  appointmentType: AppointmentType;
  onAppointmentTypeChange: (value: AppointmentType) => void;
  currentProviderSelected: User;
  onBookAgainAppointment: (
    newAppointment?: CreateAppointmentParams | null
  ) => void;
  onGetBookAgainAppointmentParams: () => CreateAppointmentParams | null;
  onUserDataChange: (value: User) => void;
  recurring: boolean;
  onRecurringChange: (value: boolean) => void;
  onGoToFollowUp: () => void;
  timezone: string;
  onTimezoneChange: (value: string) => void;
  newAppointmentsRange: AppointmentDateRange[];
  onNewAppointmentsRangeChange: (value: AppointmentDateRange[]) => void;
  onLocationAddressChange: (value: Address | null) => void;
  locationAddress: Address | null;
  user?: User;
  properties?: Properties;
};

export default function Calendar({
  appointment,
  appointmentType,
  onAppointmentTypeChange,
  currentProviderSelected,
  onUserDataChange,
  onBookAgainAppointment,
  onGetBookAgainAppointmentParams,
  recurring,
  onRecurringChange,
  onGoToFollowUp,
  timezone,
  onTimezoneChange,
  locationAddress,
  onLocationAddressChange,
  newAppointmentsRange,
  onNewAppointmentsRangeChange,
  user,
  properties,
}: CalendarProps): JSX.Element {
  const isStaffOrGreater = roleGreaterOrEquals(user?.role, 'Staff');

  const calendarEvents = useCalendarEvents(
    timezone,
    newAppointmentsRange,
    onNewAppointmentsRangeChange,
    appointmentType.minutes
  );

  const [slotLength, setSlotLength] = React.useState(() => {
    const difference = differenceInMinutes(
      new Date(appointment.endDate),
      new Date(appointment.startDate)
    );
    if (difference > 60) {
      return '60';
    }
    return String(difference) as SlotLength;
  });

  const onApplyProviderSelection = (
    user: User,
    { startDate, endDate }: SlotRange
  ) => {
    onUserDataChange(user);
    onNewAppointmentsRangeChange([
      {
        start: convertUtcStringToLocalDate(startDate).toISOString(),
        end: convertUtcStringToLocalDate(endDate).toISOString(),
      },
    ]);
    onGoToFollowUp();
  };

  const handleRemoveEvent = () => {
    onNewAppointmentsRangeChange([]);
    onRecurringChange(false);
  };

  const isNewAppointmentCreated = newAppointmentsRange.length > 0;
  const isLocationRequired = isInPerson(appointmentType?.type);

  const isPastError =
    // TODO tech debt added `!` for port
    isNewAppointmentCreated && isPast(new Date(newAppointmentsRange[0]!.start));

  return (
    <>
      <Modal.Content>
        <BookAgainCalendar
          newAppointmentsRange={newAppointmentsRange}
          onEventDropChange={calendarEvents.onEventDropChange}
          onDateSelectChange={calendarEvents.onDateSelectChange}
          patientTimezone={appointment.patient?.timeZone}
          providerTimezone={currentProviderSelected.timeZone}
          timezone={timezone}
          onTimezoneChange={onTimezoneChange}
          appointment={appointment}
          currentSlotLength={slotLength}
          onSlotLengthChange={setSlotLength}
          selectedProvider={currentProviderSelected}
          onSelectedProviderChange={(user) => onUserDataChange(user)}
          selectedProviderId={currentProviderSelected.id}
          appointmentType={appointmentType}
          onAppointmentTypeChange={onAppointmentTypeChange}
          onApplyProviderSelection={onApplyProviderSelection}
          locationAddress={locationAddress}
          onLocationAddressChange={onLocationAddressChange}
          isLocationRequired={isLocationRequired}
          properties={properties}
        />
      </Modal.Content>
      <Modal.Footer>
        <Modal.DismissButton />
        <Button
          variant="outlined"
          onClick={handleRemoveEvent}
          disabled={!isNewAppointmentCreated}
        >
          Clear Time
        </Button>
        <Button
          disabled={
            !isNewAppointmentCreated ||
            (isPastError && !isStaffOrGreater) ||
            (isLocationRequired && !locationAddress)
          }
          onClick={() =>
            recurring
              ? onBookAgainAppointment(onGetBookAgainAppointmentParams())
              : onGoToFollowUp()
          }
        >
          {recurring ? 'Confirm' : 'Continue'}
        </Button>
      </Modal.Footer>
    </>
  );
}
