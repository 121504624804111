import type { IconButtonProps, TypographyProps } from '@mui/material';

import * as React from 'react';
import { ContentCopy, OpenInNew } from '@mui/icons-material';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import CopyTooltip from '#components/CopyTooltip/CopyTooltip.tsx';
import useCopyTooltip from '#components/CopyTooltip/use-copy-tooltip.ts';
import useAsync from '#hooks/async.ts';
import { parsePhoneNumber } from '#utils/phone.ts';

const AugmentedLinkContext = React.createContext<{
  href: string;
  tagId?: string;
}>({ href: '' });
AugmentedLinkContext.displayName = 'AugmentedLinkContext';

const useAugmentedLinkContext = () => React.useContext(AugmentedLinkContext);

type RootProps = {
  href: string | number;
  tagId?: string;
  children: React.ReactNode;
};

export function Root({ href, tagId, children }: RootProps): JSX.Element {
  const value = React.useMemo(
    () => ({ href: String(href), tagId }),
    [href, tagId]
  );

  return (
    <AugmentedLinkContext.Provider value={value}>
      <Stack spacing={0.5} alignItems="center">
        {children}
      </Stack>
    </AugmentedLinkContext.Provider>
  );
}

const isHrefTelOrMail = (href: string) =>
  href.startsWith('tel:') || href.startsWith('mailto:');

type LabelProps = {
  text?: string | number;
  asLink?: boolean;
  variant?: TypographyProps['variant'];
};

const textEllipsisSx = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
} as const;

export function Label({
  text,
  asLink = false,
  variant = 'body2',
}: LabelProps): JSX.Element {
  const { href, tagId } = useAugmentedLinkContext();

  const isTelOrMail = isHrefTelOrMail(href);
  const target = isTelOrMail ? undefined : '_blank';
  const rel = isTelOrMail ? undefined : 'noreferrer';

  /**
   * Use text if passed, otherwise try to infer and format the href value.
   */
  let actualText = text;
  if (actualText === undefined) {
    if (!isTelOrMail) {
      actualText = href;
    } else {
      const valueFromHref = href.split(':')[1];
      actualText =
        valueFromHref && href.startsWith('tel:')
          ? parsePhoneNumber(valueFromHref)?.formatNational() ?? valueFromHref
          : valueFromHref;
    }
  }

  return (
    <>
      {asLink || isTelOrMail ? (
        <Box {...textEllipsisSx}>
          <Link
            href={href}
            target={target}
            rel={rel}
            data-tag-id={tagId ? `${tagId}-link` : undefined}
            color="info.main"
            underline="none"
            variant={variant}
          >
            {actualText}
          </Link>
        </Box>
      ) : (
        <Typography variant={variant} {...textEllipsisSx}>
          {actualText}
        </Typography>
      )}
    </>
  );
}

type ButtonProps = Omit<IconButtonProps, 'size' | 'color'> & {
  href?: string;
  target?: string;
};

const StyledIconButton = styled(IconButton)({
  '& svg': {
    width: 20,
    height: 20,
  },
});

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    return <StyledIconButton ref={ref} size="small" color="info" {...props} />;
  }
);

export function OpenInNewTab(): JSX.Element {
  const { href, tagId } = useAugmentedLinkContext();

  return (
    <Button
      href={href}
      target="_blank"
      data-tag-id={tagId ? `${tagId}-go-to-page` : undefined}
    >
      <OpenInNew color="info" />
    </Button>
  );
}

type CopyProps = {
  value?: string | number;
};

export function Copy({ value }: CopyProps): JSX.Element {
  const { run } = useAsync<void>();
  const { isOpen, open } = useCopyTooltip();
  const { href, tagId } = useAugmentedLinkContext();

  return (
    <CopyTooltip open={isOpen}>
      <Button
        onClick={() => {
          void run(
            navigator.clipboard.writeText(
              value !== undefined
                ? String(value)
                : isHrefTelOrMail(href) && href
                  ? href.split(':')[1] || ''
                  : href || ''
            )
          ).then(open);
        }}
        data-tag-id={tagId ? `${tagId}-copy` : undefined}
      >
        <ContentCopy color="info" />
      </Button>
    </CopyTooltip>
  );
}
