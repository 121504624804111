import type { BackgroundEvent, Status } from '#types/appointment';
import type { Response } from '#types/common';
import type { Role } from '#utils/roles';
import type { PhoneNumber } from './phone';

import { removeCookie, setCookie } from '#utils/cookies';
import { syncLanguageCookie } from '#utils/languages';
import { api } from '../http-clients';

type AvailableSlots = {
  calendarEventId: number;
  category: string | null;
  endDate: string;
  locationId: number | null;
  slotLength: number;
  startDate: string;
  userAvailableSlotId: number;
};

export type Gender = 'Male' | 'Female' | 'Unknown' | 'Other';

export type User = {
  id: number;
  role: Role;
  firstName: string | number;
  middleName: string | number | null;
  lastName: string | number;
  timeZone: string;
  availableSlots?: AvailableSlots[];
  birthDate: string; // Format is YYYY-MM-DD
  credentialName: string | null;
  specialtyName: string | null;
  thumbnail: string | null; // TODO
  email: string;
  lastLogin: string;
  mobile: string | null;
  contact: string;
  chartId: string | number | null;
  showInAppointmentFlow: number;
  gender: Gender;
  restrictedBooking?: number;
  settings: {
    language: string;
  };
  addresses: Address[] | null;
  patientStatuses: {
    active: Record<string, Status[]> | null;
  };
  externalEmrId: string | null;
  connectorId: string | null;
  guardianUserIds: number[];
};

export type Ward = Pick<
  User,
  | 'birthDate'
  | 'chartId'
  | 'firstName'
  | 'gender'
  | 'id'
  | 'lastName'
  | 'middleName'
>;

export type Address = {
  city: string | number | null;
  color: string;
  deprecated: 0 | 1;
  id: number;
  importName: string;
  locationName: string | number | null;
  locationNameOnly: 0 | 1;
  postal: string | number | null;
  state: string | number | null;
  street: string | number | null;
  street2: string | number | null;
  type: string; // Confirm (enum?)
  phoneNumber?: PhoneNumber;
};

export type Org = {
  id: number;
  name: string;
  masterOrgId: number | null;
  mendNow: number;
  formattedPhone: string | number | null;
  settings: {
    allowPatientBookings: 0 | 1;
    logoUri: string | null;
  };
  addresses: Address[];
};

type GetMeResponsePayload = {
  user: User;
  org: Org;
};

/**
 * Save the logo (if present) in a cookie with the same format as in the
 * portal, otherwise remove the cookie.
 */
function updateLogoCookie(logoUri: Org['settings']['logoUri']): void {
  if (logoUri) {
    setCookie('last-logo', `/file/${logoUri}`);
  } else {
    removeCookie('last-logo');
  }
}

export function getMe(): Promise<GetMeResponsePayload> {
  return api
    .get<Response<GetMeResponsePayload>>('/user/me')
    .then((response) => {
      updateLogoCookie(response.data.payload.org.settings.logoUri);
      return response.data.payload;
    });
}

export type UserSettings = {
  language: string;
};

export function getSettings(): Promise<UserSettings> {
  return api
    .get<Response<{ userSettings: UserSettings }>>('/user/settings')
    .then((res) => res.data.payload.userSettings)
    .then((settings) => {
      syncLanguageCookie(settings.language);
      return settings;
    });
}

export type GetProvidersParams = {
  showInAppointmentFlow?: 1 | 0;
  providerAvailability?: 1 | 0;
  onlyAvailable?: 1 | 0;
  appointmentTypeId?: number;
  eventStartDate?: string;
  eventEndDate?: string;
  patientAge?: number;
  excludeUsers?: number[];
  search?: string;
  activeDisplayedProviders?: 1 | 0;
  inactiveDisplayedProviders?: 1 | 0;
  limit?: number;
  order?: string;
};

export type GetProvidersResponse = {
  providers: User[];
  isPageable?: boolean;
  limit?: number | null;
  page?: number | null;
  totalItems?: number;
  totalPages?: number;
};

export function getProviders(params?: GetProvidersParams): Promise<User[]> {
  return api
    .get<Response<GetProvidersResponse>>('/provider', {
      params,
    })
    .then((res) => res.data.payload.providers);
}

export type GetUsersParams = {
  id?: number;
  role?: User['role'][];
  excludeUsers?: number[];
  search?: string;
  page?: number;
  limit?: number;
  order?: string;
  includeGroups?: 0 | 1;
  includeTemporary?: 0 | 1;
};

export type GroupUser = {
  created: string;
  deleted: 0 | 1;
  groupId: number;
  id: number;
  invitationId: number | null;
  updated: string | null;
  user: User;
  userId: number;
};

export type Group = {
  description: string | null;
  groupScheduleId: number | null;
  id: number;
  name: string;
  orgId: number;
  users: GroupUser[];
};

export type GetUsersResponse = {
  groups?: Group[];
  users: User[];
  isPageable?: boolean;
  limit?: number | null;
  page?: number | null;
  totalItems?: number;
  totalPages?: number;
};

export function getUsers(
  params?: GetUsersParams,
  signal?: AbortSignal
): Promise<GetUsersResponse> {
  return api
    .get<Response<GetUsersResponse>>('/user', {
      params,
      signal,
    })
    .then((res) => res.data.payload);
}

export function getUser(userId: User['id']): Promise<User> {
  return api
    .get<Response<{ user: User }>>(`/user/${userId}`)
    .then((res) => res.data.payload.user);
}

type GetProviderUnavailabilityParams = {
  startDate: string;
  endDate: string;
  timeZone: string;
  appointmentTypeId?: number;
  addressId?: number;
};

export type GetProviderUnavailabilityResponse = {
  events: BackgroundEvent[];
};

export function getProviderUnavailability(
  providerId: number,
  params?: GetProviderUnavailabilityParams
): Promise<BackgroundEvent[]> {
  return api
    .get<
      Response<GetProviderUnavailabilityResponse>
    >(`/provider/${providerId}/unavailableSlots`, { params })
    .then((res) => res.data.payload.events);
}
