import type { SlotRange } from '#components/TransferVisitModal/utils';
import type { User } from '#services/api/user';
import type { Appointment } from '#types/appointment';

import { AddOutlined } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';

import ProvidersAutocomplete from '#components/Autocomplete/ProvidersAutocomplete';
import ProviderAvailabilityModal from '#components/ProviderAvailability/ProviderAvailabilityModal';
import useModal from '#hooks/modal';

type ProvidersProps = {
  selectedProvider: User;
  onSelectedProviderChange: (value: User) => void;
  appointment: Appointment;
  onApplyProviderSelection: (user: User, selectedSlot: SlotRange) => void;
};

export default function Provider({
  selectedProvider,
  onSelectedProviderChange,
  appointment,
  onApplyProviderSelection,
}: ProvidersProps): JSX.Element {
  const showProviderAvailabilityModal = useModal();
  const { open: openProviderAvailability } = showProviderAvailabilityModal;

  return (
    <Stack width="100%" mb={2} direction="column" alignItems="flex-end">
      <ProvidersAutocomplete
        disablePortal
        value={selectedProvider}
        onChange={(_event, newValue) =>
          onSelectedProviderChange &&
          newValue &&
          onSelectedProviderChange(newValue)
        }
      />
      <Button
        variant="text"
        color="info"
        onClick={openProviderAvailability}
        sx={{ marginTop: '-16px' }}
        startIcon={<AddOutlined />}
      >
        Provider Availability
      </Button>
      {showProviderAvailabilityModal.isOpen && appointment && (
        <ProviderAvailabilityModal
          isOpen
          appointment={appointment}
          onDismiss={showProviderAvailabilityModal.dismiss}
          onApplyProviderSelection={onApplyProviderSelection}
        />
      )}
    </Stack>
  );
}
