import type { CreateAppointmentParams } from '#services/api/appointment';
import type { Address, User } from '#services/api/user';
import type { Appointment, AppointmentType } from '#types/appointment';
import type { Response } from '#types/common';
import type { AppointmentDateRange } from './BookAgainCalendar';

import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { addMinutes } from 'date-fns';
import { useSnackbar } from 'notistack';

import useAppointment from '#hooks/appointment';
import useAsync from '#hooks/async';
import useSymptoms from '#hooks/symptoms';
import { me, properties as propertiesQuery } from '#lib/react-query/queries';
import { createAppointment } from '#services/api/appointment';
import { getUser } from '#services/api/user';
import { getSymptoms, isInPerson } from '#utils/appointments';
import { currentTimezone } from '#utils/dates';
import * as Modal from '../Modal/Modal';
import CalendarView from './Views/Calendar.tsx';
import FollowUpView from './Views/FollowUp/FollowUp.tsx';

export type BookAgainModalProps = {
  appointment: Appointment;
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
};

/**
 * TODO This is tech-debt, it was brought it as part of TS-13028.
 */
export default function BookAgainModal({
  appointment,
  isOpen,
  onDismiss,
  onSuccess,
}: BookAgainModalProps): JSX.Element {
  const [view, setView] = React.useState<'calendar' | 'follow-up'>('calendar');
  const [timezone, setTimezone] = React.useState(`${currentTimezone}-mine`);

  const { symptoms, onSymptomsChange, initFromAppointment } = useSymptoms();

  const { appointment: completeAppointment } = useAppointment(
    appointment.id,
    initFromAppointment
  );

  const { data: { user, org } = {} } = useQuery(me);
  const { data: properties } = useQuery(propertiesQuery());
  const { role } = user ?? {};

  /**
   * Fetch full details of the provider in order to get the time zone, this
   * could be avoided if the /flat endpoint included the property in the
   * provider object.
   */
  const providerQuery = useAsync<User>({
    // TODO tech debt `as unknown as User` added for the port
    data: { ...appointment.provider, timeZone: 'UTC' } as unknown as User,
  });
  const { run: providerQueryRun } = providerQuery;

  /**
   * We use `as User` because we have all the data initialized above defaulting
   * to a generic time zone until the request below resolves and gives us the
   * actual time zone. This is needed because we know that `providerQuery.data`
   * won't ever be `null`.
   */
  const currentProviderSelected = providerQuery.data as User;

  React.useEffect(() => {
    // TODO tech debt added `void` for port
    void providerQueryRun(getUser(appointment.provider.id));
  }, [providerQueryRun, appointment.provider.id]);

  const isPatientRole = role === 'Patient';
  const { enqueueSnackbar } = useSnackbar();

  /**
   * TODO tech debt not a problem here but we had to remove the non-null
   * assertion operator coming from the portal.
   */
  const [appointmentType, setAppointmentType] = React.useState(
    appointment.appointmentType!
  );

  const [newAppointmentsRange, setNewAppointmentsRange] = React.useState<
    AppointmentDateRange[]
  >([]);

  const [recurring, setRecurring] = React.useState(false);

  const [locationAddress, setLocationAddress] = React.useState(() =>
    appointment.addressId
      ? org?.addresses.find(({ id }) => id === appointment.addressId) ?? null
      : null
  );

  const { run: createAppointmentsRun, isLoading: createAppointmentsIsPending } =
    useAsync<Response[]>();

  const getBookAgainAppointmentParams = (): CreateAppointmentParams | null => {
    if (
      !currentProviderSelected ||
      newAppointmentsRange.length === 0 ||
      !appointmentType ||
      !completeAppointment
    )
      return null;

    return {
      appointmentTypeId: appointmentType.id,
      providerId: currentProviderSelected.id,
      patientId: appointment.patientId,
      // TODO tech debt the `!` operator was added due to different config
      startDate: newAppointmentsRange[0]!.start,
      endDate: newAppointmentsRange[0]!.end,
      approved: 1,
      wards: appointment.wards,
      type: appointmentType,
      symptoms: getSymptoms(completeAppointment, symptoms),
      recurring: recurring ? 1 : 0,
      ...(locationAddress &&
        isInPerson(appointmentType.type) && {
          addressId: Number(locationAddress.id),
        }),
    };
  };

  const bookAgainAppointment = (
    newAppointment?: CreateAppointmentParams | null
  ) => {
    if (!newAppointment) return;

    const recurringAppointments = newAppointmentsRange.map(
      ({ start, end }) => ({
        ...newAppointment,
        startDate: start,
        endDate: end,
      })
    );

    const promises = (
      !recurringAppointments.length ? [newAppointment] : recurringAppointments
    ).map(createAppointment);

    // TODO tech debt added `void` for port
    void createAppointmentsRun(
      Promise.all(promises).then((res) => {
        onSuccess();
        onDismiss();
        return res;
      })
    ).then((res) => {
      const message =
        res instanceof Error
          ? res.message
          : 'Appointment(s) were successfully created!';
      enqueueSnackbar(message, {
        variant: res instanceof Error ? 'error' : 'success',
      });
    });
  };

  const latestLocationAddressSelectedRef = React.useRef<Address | null>(null);

  const handleAppointmentTypeChange = (newAppointmentType: AppointmentType) => {
    setAppointmentType(newAppointmentType);

    /**
     * Update the existing appointment events whenever the appointment type
     * duration changes.
     */
    if (newAppointmentType.minutes !== appointmentType.minutes) {
      setNewAppointmentsRange(
        newAppointmentsRange.map((newAppointmentRange) => ({
          ...newAppointmentRange,
          end: addMinutes(
            new Date(newAppointmentRange.start),
            newAppointmentType.minutes
          ).toISOString(),
        }))
      );
    }

    /**
     * Clear out the location if the appointment is not in person while keeping
     * a reference to the last selected value in case they select an in person
     * type again at some point.
     */
    if (!isInPerson(newAppointmentType.type)) {
      if (locationAddress) {
        latestLocationAddressSelectedRef.current = locationAddress;
      }
      setLocationAddress(null);
    } else {
      if (!locationAddress && latestLocationAddressSelectedRef.current) {
        setLocationAddress(latestLocationAddressSelectedRef.current);
      }
    }
  };

  /**
   * TODO tech debt, these two flags were added as part of the port to replace
   * the individual state variables even though we could get away with just the
   * view state variable, this was done to reduce the number of changes as part
   * of the port.
   */
  const showCalendar = view === 'calendar';
  const showFollowUp = !showCalendar;

  /**
   * TODO
   * Can't properly calculate the logic with the calendar since some of the
   * state lives inside it.
   */
  const requiresConfirmation =
    (showCalendar &&
      (appointment.providerId !== currentProviderSelected.id ||
        appointment.appointmentType?.id !== appointmentType.id ||
        appointment.addressId !== (locationAddress?.id ?? null))) ||
    showFollowUp;

  return (
    <Modal.Root
      maxWidth={showCalendar || showFollowUp ? 'xl' : 'xs'}
      fullHeight={showCalendar || showFollowUp}
      aria-labelledby="book-again-modal-title"
      aria-describedby="book-again-modal-description"
      isLoading={createAppointmentsIsPending}
      open={isOpen}
      onClose={onDismiss}
      requiresConfirmation={requiresConfirmation}
    >
      <Modal.Header>
        <Modal.Title id="book-again-modal-title">
          {showCalendar || showFollowUp
            ? 'Follow Up'
            : !isPatientRole && !showCalendar && !showFollowUp
              ? 'Self Schedule or Patient Schedule?'
              : 'Schedule Now or Set Reminder?'}
        </Modal.Title>
        {showFollowUp && (
          <Modal.Description id="book-again-modal-description">
            Please verify the appointment information, then click “Schedule
            Follow Up”
          </Modal.Description>
        )}
      </Modal.Header>
      {showCalendar ? (
        <CalendarView
          appointment={appointment}
          user={user}
          properties={properties}
          currentProviderSelected={currentProviderSelected}
          onBookAgainAppointment={bookAgainAppointment}
          onGetBookAgainAppointmentParams={getBookAgainAppointmentParams}
          onUserDataChange={providerQuery.setData}
          recurring={recurring}
          onRecurringChange={setRecurring}
          onGoToFollowUp={() => setView('follow-up')}
          timezone={timezone}
          onTimezoneChange={setTimezone}
          locationAddress={locationAddress}
          onLocationAddressChange={setLocationAddress}
          newAppointmentsRange={newAppointmentsRange}
          onNewAppointmentsRangeChange={setNewAppointmentsRange}
          appointmentType={appointmentType}
          onAppointmentTypeChange={handleAppointmentTypeChange}
        />
      ) : (
        <FollowUpView
          newAppointmentsRange={newAppointmentsRange}
          onNewAppointmentsRangeChange={setNewAppointmentsRange}
          createAppointmentsIsPending={createAppointmentsIsPending}
          onBookAgainAppointment={bookAgainAppointment}
          onGetBookAgainAppointmentParams={getBookAgainAppointmentParams}
          onGoToCalendar={() => setView('calendar')}
          currentProviderSelected={currentProviderSelected}
          recurring={recurring}
          onRecurringChange={setRecurring}
          symptoms={symptoms}
          onSymptomsChange={onSymptomsChange}
          /**
           * We update the `appointmentType` property of the "original"
           * appointment to reflect the latest selected value without having to
           * pass it as prop.
           */
          appointment={{ ...appointment, appointmentType }}
          properties={properties}
          user={user}
        />
      )}
    </Modal.Root>
  );
}
