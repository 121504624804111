// Taken from portal@public/dev/settings/timezones.json
// The commented timezones will be needed when we have update users functionality in React,
// uncomment will be easier than looking for missing timezones
export const timeZones = [
  //   {
  //       "region": "US",
  //       "value": "America/Detroit",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Indiana/Indianapolis",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Indiana/Marengo",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Indiana/Petersburg",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Indiana/Vevay",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Indiana/Vincennes",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Indiana/Winamac",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Kentucky/Louisville",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Kentucky/Monticello",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  {
    region: 'US',
    value: 'America/New_York',
    defaultValue: 'America/New_York',
    label: 'Eastern Time',
  },
  //   {
  //       "region": "US",
  //       "value": "America/Pangnirtung",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Thunder_Bay",
  //       "defaultValue": "America/New_York",
  //       "label": "Eastern Time"
  //   },
  {
    region: 'US',
    value: 'America/Los_Angeles',
    defaultValue: 'America/Los_Angeles',
    label: 'Pacific Time',
  },
  {
    region: 'US',
    value: 'America/Chicago',
    defaultValue: 'America/Chicago',
    label: 'Central Time',
  },
  //   {
  //       "region": "US",
  //       "value": "America/Indiana/Knox",
  //       "defaultValue": "America/Chicago",
  //       "label": "Central Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Indiana/Tell_City",
  //       "defaultValue": "America/Chicago",
  //       "label": "Central Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Menominee",
  //       "defaultValue": "America/Chicago",
  //       "label": "Central Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/North_Dakota/Beulah",
  //       "defaultValue": "America/Chicago",
  //       "label": "Central Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/North_Dakota/Center",
  //       "defaultValue": "America/Chicago",
  //       "label": "Central Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/North_Dakota/New_Salem",
  //       "defaultValue": "America/Chicago",
  //       "label": "Central Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Boise",
  //       "defaultValue": "America/Denver",
  //       "label": "Mountain Time"
  //   },
  {
    region: 'US',
    value: 'America/Denver',
    defaultValue: 'America/Denver',
    label: 'Mountain Time',
  },
  {
    region: 'US',
    value: 'America/Phoenix',
    defaultValue: 'America/Phoenix',
    label: 'Mountain Time (AZ)',
  },
  {
    region: 'US',
    value: 'Pacific/Honolulu',
    defaultValue: 'Pacific/Honolulu',
    label: 'Hawaii-Aleutian Time',
  },
  //   {
  //       "region": "US",
  //       "value": "Pacific/Johnston",
  //       "defaultValue": "Pacific/Honolulu",
  //       "label": "Hawaii-Aleutian Time"
  //   },
  {
    region: 'US',
    value: 'America/Adak',
    defaultValue: 'America/Adak',
    label: 'Hawaii-Aleutian Time (Adak)',
  },
  {
    region: 'US',
    value: 'America/Anchorage',
    defaultValue: 'America/Anchorage',
    label: 'Alaska Time',
  },
  //   {
  //       "region": "US",
  //       "value": "America/Juneau",
  //       "defaultValue": "America/Anchorage",
  //       "label": "Alaska Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Metlakatla",
  //       "defaultValue": "America/Anchorage",
  //       "label": "Alaska Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Nome",
  //       "defaultValue": "America/Anchorage",
  //       "label": "Alaska Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Sitka",
  //       "defaultValue": "America/Anchorage",
  //       "label": "Alaska Time"
  //   },
  //   {
  //       "region": "US",
  //       "value": "America/Yakutat",
  //       "defaultValue": "America/Anchorage",
  //       "label": "Alaska Time"
  //   },
  {
    region: 'America (non-US)',
    value: 'America/Anguilla',
    defaultValue: 'America/Anguilla',
    label: 'Anguilla',
  },
  {
    region: 'America (non-US)',
    value: 'America/Antigua',
    defaultValue: 'America/Antigua',
    label: 'Antigua',
  },
  {
    region: 'America (non-US)',
    value: 'America/Araguaina',
    defaultValue: 'America/Araguaina',
    label: 'Araguaina',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/Buenos_Aires',
    defaultValue: 'America/Argentina/Buenos_Aires',
    label: 'Argentina/Buenos_Aires',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/Catamarca',
    defaultValue: 'America/Argentina/Catamarca',
    label: 'Argentina/Catamarca',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/Cordoba',
    defaultValue: 'America/Argentina/Cordoba',
    label: 'Argentina/Cordoba',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/Jujuy',
    defaultValue: 'America/Argentina/Jujuy',
    label: 'Argentina/Jujuy',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/La_Rioja',
    defaultValue: 'America/Argentina/La_Rioja',
    label: 'Argentina/La_Rioja',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/Mendoza',
    defaultValue: 'America/Argentina/Mendoza',
    label: 'Argentina/Mendoza',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/Rio_Gallegos',
    defaultValue: 'America/Argentina/Rio_Gallegos',
    label: 'Argentina/Rio_Gallegos',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/Salta',
    defaultValue: 'America/Argentina/Salta',
    label: 'Argentina/Salta',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/San_Juan',
    defaultValue: 'America/Argentina/San_Juan',
    label: 'Argentina/San_Juan',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/San_Luis',
    defaultValue: 'America/Argentina/San_Luis',
    label: 'Argentina/San_Luis',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/Tucuman',
    defaultValue: 'America/Argentina/Tucuman',
    label: 'Argentina/Tucuman',
  },
  {
    region: 'America (non-US)',
    value: 'America/Argentina/Ushuaia',
    defaultValue: 'America/Argentina/Ushuaia',
    label: 'Argentina/Ushuaia',
  },
  {
    region: 'America (non-US)',
    value: 'America/Aruba',
    defaultValue: 'America/Aruba',
    label: 'Aruba',
  },
  {
    region: 'America (non-US)',
    value: 'America/Asuncion',
    defaultValue: 'America/Asuncion',
    label: 'Asuncion',
  },
  {
    region: 'America (non-US)',
    value: 'America/Atikokan',
    defaultValue: 'America/Atikokan',
    label: 'Atikokan',
  },
  {
    region: 'America (non-US)',
    value: 'America/Bahia',
    defaultValue: 'America/Bahia',
    label: 'Bahia',
  },
  {
    region: 'America (non-US)',
    value: 'America/Bahia_Banderas',
    defaultValue: 'America/Bahia_Banderas',
    label: 'Bahia_Banderas',
  },
  {
    region: 'America (non-US)',
    value: 'America/Barbados',
    defaultValue: 'America/Barbados',
    label: 'Barbados',
  },
  {
    region: 'America (non-US)',
    value: 'America/Belem',
    defaultValue: 'America/Belem',
    label: 'Belem',
  },
  {
    region: 'America (non-US)',
    value: 'America/Belize',
    defaultValue: 'America/Belize',
    label: 'Belize',
  },
  {
    region: 'America (non-US)',
    value: 'America/Blanc-Sablon',
    defaultValue: 'America/Blanc-Sablon',
    label: 'Blanc-Sablon',
  },
  {
    region: 'America (non-US)',
    value: 'America/Boa_Vista',
    defaultValue: 'America/Boa_Vista',
    label: 'Boa_Vista',
  },
  {
    region: 'America (non-US)',
    value: 'America/Bogota',
    defaultValue: 'America/Bogota',
    label: 'Bogota',
  },
  {
    region: 'America (non-US)',
    value: 'America/Cambridge_Bay',
    defaultValue: 'America/Cambridge_Bay',
    label: 'Cambridge_Bay',
  },
  {
    region: 'America (non-US)',
    value: 'America/Campo_Grande',
    defaultValue: 'America/Campo_Grande',
    label: 'Campo_Grande',
  },
  {
    region: 'America (non-US)',
    value: 'America/Cancun',
    defaultValue: 'America/Cancun',
    label: 'Cancun',
  },
  {
    region: 'America (non-US)',
    value: 'America/Caracas',
    defaultValue: 'America/Caracas',
    label: 'Caracas',
  },
  {
    region: 'America (non-US)',
    value: 'America/Cayenne',
    defaultValue: 'America/Cayenne',
    label: 'Cayenne',
  },
  {
    region: 'America (non-US)',
    value: 'America/Cayman',
    defaultValue: 'America/Cayman',
    label: 'Cayman',
  },
  {
    region: 'America (non-US)',
    value: 'America/Chihuahua',
    defaultValue: 'America/Chihuahua',
    label: 'Chihuahua',
  },
  {
    region: 'America (non-US)',
    value: 'America/Costa_Rica',
    defaultValue: 'America/Costa_Rica',
    label: 'Costa_Rica',
  },
  {
    region: 'America (non-US)',
    value: 'America/Creston',
    defaultValue: 'America/Creston',
    label: 'Creston',
  },
  {
    region: 'America (non-US)',
    value: 'America/Cuiaba',
    defaultValue: 'America/Cuiaba',
    label: 'Cuiaba',
  },
  {
    region: 'America (non-US)',
    value: 'America/Curacao',
    defaultValue: 'America/Curacao',
    label: 'Curacao',
  },
  {
    region: 'America (non-US)',
    value: 'America/Danmarkshavn',
    defaultValue: 'America/Danmarkshavn',
    label: 'Danmarkshavn',
  },
  {
    region: 'America (non-US)',
    value: 'America/Dawson',
    defaultValue: 'America/Dawson',
    label: 'Dawson',
  },
  {
    region: 'America (non-US)',
    value: 'America/Dawson_Creek',
    defaultValue: 'America/Dawson_Creek',
    label: 'Dawson_Creek',
  },
  {
    region: 'America (non-US)',
    value: 'America/Dominica',
    defaultValue: 'America/Dominica',
    label: 'Dominica',
  },
  {
    region: 'America (non-US)',
    value: 'America/Edmonton',
    defaultValue: 'America/Edmonton',
    label: 'Edmonton',
  },
  {
    region: 'America (non-US)',
    value: 'America/Eirunepe',
    defaultValue: 'America/Eirunepe',
    label: 'Eirunepe',
  },
  {
    region: 'America (non-US)',
    value: 'America/El_Salvador',
    defaultValue: 'America/El_Salvador',
    label: 'El_Salvador',
  },
  {
    region: 'America (non-US)',
    value: 'America/Fort_Nelson',
    defaultValue: 'America/Fort_Nelson',
    label: 'Fort_Nelson',
  },
  {
    region: 'America (non-US)',
    value: 'America/Fortaleza',
    defaultValue: 'America/Fortaleza',
    label: 'Fortaleza',
  },
  {
    region: 'America (non-US)',
    value: 'America/Glace_Bay',
    defaultValue: 'America/Glace_Bay',
    label: 'Glace_Bay',
  },
  {
    region: 'America (non-US)',
    value: 'America/Godthab',
    defaultValue: 'America/Godthab',
    label: 'Godthab',
  },
  {
    region: 'America (non-US)',
    value: 'America/Goose_Bay',
    defaultValue: 'America/Goose_Bay',
    label: 'Goose_Bay',
  },
  {
    region: 'America (non-US)',
    value: 'America/Grand_Turk',
    defaultValue: 'America/Grand_Turk',
    label: 'Grand_Turk',
  },
  {
    region: 'America (non-US)',
    value: 'America/Grenada',
    defaultValue: 'America/Grenada',
    label: 'Grenada',
  },
  {
    region: 'America (non-US)',
    value: 'America/Guadeloupe',
    defaultValue: 'America/Guadeloupe',
    label: 'Guadeloupe',
  },
  {
    region: 'America (non-US)',
    value: 'America/Guatemala',
    defaultValue: 'America/Guatemala',
    label: 'Guatemala',
  },
  {
    region: 'America (non-US)',
    value: 'America/Guayaquil',
    defaultValue: 'America/Guayaquil',
    label: 'Guayaquil',
  },
  {
    region: 'America (non-US)',
    value: 'America/Guyana',
    defaultValue: 'America/Guyana',
    label: 'Guyana',
  },
  {
    region: 'America (non-US)',
    value: 'America/Halifax',
    defaultValue: 'America/Halifax',
    label: 'Halifax',
  },
  {
    region: 'America (non-US)',
    value: 'America/Havana',
    defaultValue: 'America/Havana',
    label: 'Havana',
  },
  {
    region: 'America (non-US)',
    value: 'America/Hermosillo',
    defaultValue: 'America/Hermosillo',
    label: 'Hermosillo',
  },
  {
    region: 'America (non-US)',
    value: 'America/Inuvik',
    defaultValue: 'America/Inuvik',
    label: 'Inuvik',
  },
  {
    region: 'America (non-US)',
    value: 'America/Iqaluit',
    defaultValue: 'America/Iqaluit',
    label: 'Iqaluit',
  },
  {
    region: 'America (non-US)',
    value: 'America/Jamaica',
    defaultValue: 'America/Jamaica',
    label: 'Jamaica',
  },
  {
    region: 'America (non-US)',
    value: 'America/Kralendijk',
    defaultValue: 'America/Kralendijk',
    label: 'Kralendijk',
  },
  {
    region: 'America (non-US)',
    value: 'America/La_Paz',
    defaultValue: 'America/La_Paz',
    label: 'La_Paz',
  },
  {
    region: 'America (non-US)',
    value: 'America/Lima',
    defaultValue: 'America/Lima',
    label: 'Lima',
  },
  {
    region: 'America (non-US)',
    value: 'America/Lower_Princes',
    defaultValue: 'America/Lower_Princes',
    label: 'Lower_Princes',
  },
  {
    region: 'America (non-US)',
    value: 'America/Maceio',
    defaultValue: 'America/Maceio',
    label: 'Maceio',
  },
  {
    region: 'America (non-US)',
    value: 'America/Managua',
    defaultValue: 'America/Managua',
    label: 'Managua',
  },
  {
    region: 'America (non-US)',
    value: 'America/Manaus',
    defaultValue: 'America/Manaus',
    label: 'Manaus',
  },
  {
    region: 'America (non-US)',
    value: 'America/Marigot',
    defaultValue: 'America/Marigot',
    label: 'Marigot',
  },
  {
    region: 'America (non-US)',
    value: 'America/Martinique',
    defaultValue: 'America/Martinique',
    label: 'Martinique',
  },
  {
    region: 'America (non-US)',
    value: 'America/Matamoros',
    defaultValue: 'America/Matamoros',
    label: 'Matamoros',
  },
  {
    region: 'America (non-US)',
    value: 'America/Mazatlan',
    defaultValue: 'America/Mazatlan',
    label: 'Mazatlan',
  },
  {
    region: 'America (non-US)',
    value: 'America/Merida',
    defaultValue: 'America/Merida',
    label: 'Merida',
  },
  {
    region: 'America (non-US)',
    value: 'America/Mexico_City',
    defaultValue: 'America/Mexico_City',
    label: 'Mexico_City',
  },
  {
    region: 'America (non-US)',
    value: 'America/Miquelon',
    defaultValue: 'America/Miquelon',
    label: 'Miquelon',
  },
  {
    region: 'America (non-US)',
    value: 'America/Moncton',
    defaultValue: 'America/Moncton',
    label: 'Moncton',
  },
  {
    region: 'America (non-US)',
    value: 'America/Monterrey',
    defaultValue: 'America/Monterrey',
    label: 'Monterrey',
  },
  {
    region: 'America (non-US)',
    value: 'America/Montevideo',
    defaultValue: 'America/Montevideo',
    label: 'Montevideo',
  },
  {
    region: 'America (non-US)',
    value: 'America/Montreal',
    defaultValue: 'America/Montreal',
    label: 'Montreal',
  },
  {
    region: 'America (non-US)',
    value: 'America/Montserrat',
    defaultValue: 'America/Montserrat',
    label: 'Montserrat',
  },
  {
    region: 'America (non-US)',
    value: 'America/Nassau',
    defaultValue: 'America/Nassau',
    label: 'Nassau',
  },
  {
    region: 'America (non-US)',
    value: 'America/Noronha',
    defaultValue: 'America/Noronha',
    label: 'Noronha',
  },
  {
    region: 'America (non-US)',
    value: 'America/Ojinaga',
    defaultValue: 'America/Ojinaga',
    label: 'Ojinaga',
  },
  {
    region: 'America (non-US)',
    value: 'America/Panama',
    defaultValue: 'America/Panama',
    label: 'Panama',
  },
  {
    region: 'America (non-US)',
    value: 'America/Paramaribo',
    defaultValue: 'America/Paramaribo',
    label: 'Paramaribo',
  },
  {
    region: 'America (non-US)',
    value: 'America/Port-au-Prince',
    defaultValue: 'America/Port-au-Prince',
    label: 'Port-au-Prince',
  },
  {
    region: 'America (non-US)',
    value: 'America/Port_of_Spain',
    defaultValue: 'America/Port_of_Spain',
    label: 'Port_of_Spain',
  },
  {
    region: 'America (non-US)',
    value: 'America/Porto_Velho',
    defaultValue: 'America/Porto_Velho',
    label: 'Porto_Velho',
  },
  {
    region: 'America (non-US)',
    value: 'America/Puerto_Rico',
    defaultValue: 'America/Puerto_Rico',
    label: 'Puerto_Rico',
  },
  {
    region: 'America (non-US)',
    value: 'America/Punta_Arenas',
    defaultValue: 'America/Punta_Arenas',
    label: 'Punta_Arenas',
  },
  {
    region: 'America (non-US)',
    value: 'America/Rainy_River',
    defaultValue: 'America/Rainy_River',
    label: 'Rainy_River',
  },
  {
    region: 'America (non-US)',
    value: 'America/Rankin_Inlet',
    defaultValue: 'America/Rankin_Inlet',
    label: 'Rankin_Inlet',
  },
  {
    region: 'America (non-US)',
    value: 'America/Recife',
    defaultValue: 'America/Recife',
    label: 'Recife',
  },
  {
    region: 'America (non-US)',
    value: 'America/Regina',
    defaultValue: 'America/Regina',
    label: 'Regina',
  },
  {
    region: 'America (non-US)',
    value: 'America/Resolute',
    defaultValue: 'America/Resolute',
    label: 'Resolute',
  },
  {
    region: 'America (non-US)',
    value: 'America/Rio_Branco',
    defaultValue: 'America/Rio_Branco',
    label: 'Rio_Branco',
  },
  {
    region: 'America (non-US)',
    value: 'America/Santarem',
    defaultValue: 'America/Santarem',
    label: 'Santarem',
  },
  {
    region: 'America (non-US)',
    value: 'America/Santiago',
    defaultValue: 'America/Santiago',
    label: 'Santiago',
  },
  {
    region: 'America (non-US)',
    value: 'America/Santo_Domingo',
    defaultValue: 'America/Santo_Domingo',
    label: 'Santo_Domingo',
  },
  {
    region: 'America (non-US)',
    value: 'America/Sao_Paulo',
    defaultValue: 'America/Sao_Paulo',
    label: 'Sao_Paulo',
  },
  {
    region: 'America (non-US)',
    value: 'America/Scoresbysund',
    defaultValue: 'America/Scoresbysund',
    label: 'Scoresbysund',
  },
  {
    region: 'America (non-US)',
    value: 'America/St_Barthelemy',
    defaultValue: 'America/St_Barthelemy',
    label: 'St_Barthelemy',
  },
  {
    region: 'America (non-US)',
    value: 'America/St_Johns',
    defaultValue: 'America/St_Johns',
    label: 'St_Johns',
  },
  {
    region: 'America (non-US)',
    value: 'America/St_Kitts',
    defaultValue: 'America/St_Kitts',
    label: 'St_Kitts',
  },
  {
    region: 'America (non-US)',
    value: 'America/St_Lucia',
    defaultValue: 'America/St_Lucia',
    label: 'St_Lucia',
  },
  {
    region: 'America (non-US)',
    value: 'America/St_Thomas',
    defaultValue: 'America/St_Thomas',
    label: 'St_Thomas',
  },
  {
    region: 'America (non-US)',
    value: 'America/St_Vincent',
    defaultValue: 'America/St_Vincent',
    label: 'St_Vincent',
  },
  {
    region: 'America (non-US)',
    value: 'America/Swift_Current',
    defaultValue: 'America/Swift_Current',
    label: 'Swift_Current',
  },
  {
    region: 'America (non-US)',
    value: 'America/Tegucigalpa',
    defaultValue: 'America/Tegucigalpa',
    label: 'Tegucigalpa',
  },
  {
    region: 'America (non-US)',
    value: 'America/Thule',
    defaultValue: 'America/Thule',
    label: 'Thule',
  },
  {
    region: 'America (non-US)',
    value: 'America/Tijuana',
    defaultValue: 'America/Tijuana',
    label: 'Tijuana',
  },
  {
    region: 'America (non-US)',
    value: 'America/Toronto',
    defaultValue: 'America/Toronto',
    label: 'Toronto',
  },
  {
    region: 'America (non-US)',
    value: 'America/Tortola',
    defaultValue: 'America/Tortola',
    label: 'Tortola',
  },
  {
    region: 'America (non-US)',
    value: 'America/Vancouver',
    defaultValue: 'America/Vancouver',
    label: 'Vancouver',
  },
  {
    region: 'America (non-US)',
    value: 'America/Whitehorse',
    defaultValue: 'America/Whitehorse',
    label: 'Whitehorse',
  },
  {
    region: 'America (non-US)',
    value: 'America/Winnipeg',
    defaultValue: 'America/Winnipeg',
    label: 'Winnipeg',
  },
  {
    region: 'America (non-US)',
    value: 'America/Yellowknife',
    defaultValue: 'America/Yellowknife',
    label: 'Yellowknife',
  },
  {
    region: 'Europe',
    value: 'Europe/Amsterdam',
    defaultValue: 'Europe/Amsterdam',
    label: 'Amsterdam',
  },
  {
    region: 'Europe',
    value: 'Europe/Andorra',
    defaultValue: 'Europe/Andorra',
    label: 'Andorra',
  },
  {
    region: 'Europe',
    value: 'Europe/Astrakhan',
    defaultValue: 'Europe/Astrakhan',
    label: 'Astrakhan',
  },
  {
    region: 'Europe',
    value: 'Europe/Athens',
    defaultValue: 'Europe/Athens',
    label: 'Athens',
  },
  {
    region: 'Europe',
    value: 'Europe/Belgrade',
    defaultValue: 'Europe/Belgrade',
    label: 'Belgrade',
  },
  {
    region: 'Europe',
    value: 'Europe/Berlin',
    defaultValue: 'Europe/Berlin',
    label: 'Berlin',
  },
  {
    region: 'Europe',
    value: 'Europe/Bratislava',
    defaultValue: 'Europe/Bratislava',
    label: 'Bratislava',
  },
  {
    region: 'Europe',
    value: 'Europe/Brussels',
    defaultValue: 'Europe/Brussels',
    label: 'Brussels',
  },
  {
    region: 'Europe',
    value: 'Europe/Bucharest',
    defaultValue: 'Europe/Bucharest',
    label: 'Bucharest',
  },
  {
    region: 'Europe',
    value: 'Europe/Budapest',
    defaultValue: 'Europe/Budapest',
    label: 'Budapest',
  },
  {
    region: 'Europe',
    value: 'Europe/Busingen',
    defaultValue: 'Europe/Busingen',
    label: 'Busingen',
  },
  {
    region: 'Europe',
    value: 'Europe/Chisinau',
    defaultValue: 'Europe/Chisinau',
    label: 'Chisinau',
  },
  {
    region: 'Europe',
    value: 'Europe/Copenhagen',
    defaultValue: 'Europe/Copenhagen',
    label: 'Copenhagen',
  },
  {
    region: 'Europe',
    value: 'Europe/Dublin',
    defaultValue: 'Europe/Dublin',
    label: 'Dublin',
  },
  {
    region: 'Europe',
    value: 'Europe/Gibraltar',
    defaultValue: 'Europe/Gibraltar',
    label: 'Gibraltar',
  },
  {
    region: 'Europe',
    value: 'Europe/Guernsey',
    defaultValue: 'Europe/Guernsey',
    label: 'Guernsey',
  },
  {
    region: 'Europe',
    value: 'Europe/Helsinki',
    defaultValue: 'Europe/Helsinki',
    label: 'Helsinki',
  },
  {
    region: 'Europe',
    value: 'Europe/Isle_of_Man',
    defaultValue: 'Europe/Isle_of_Man',
    label: 'Isle_of_Man',
  },
  {
    region: 'Europe',
    value: 'Europe/Istanbul',
    defaultValue: 'Europe/Istanbul',
    label: 'Istanbul',
  },
  {
    region: 'Europe',
    value: 'Europe/Jersey',
    defaultValue: 'Europe/Jersey',
    label: 'Jersey',
  },
  {
    region: 'Europe',
    value: 'Europe/Kaliningrad',
    defaultValue: 'Europe/Kaliningrad',
    label: 'Kaliningrad',
  },
  {
    region: 'Europe',
    value: 'Europe/Kiev',
    defaultValue: 'Europe/Kiev',
    label: 'Kiev',
  },
  {
    region: 'Europe',
    value: 'Europe/Kirov',
    defaultValue: 'Europe/Kirov',
    label: 'Kirov',
  },
  {
    region: 'Europe',
    value: 'Europe/Lisbon',
    defaultValue: 'Europe/Lisbon',
    label: 'Lisbon',
  },
  {
    region: 'Europe',
    value: 'Europe/Ljubljana',
    defaultValue: 'Europe/Ljubljana',
    label: 'Ljubljana',
  },
  {
    region: 'Europe',
    value: 'Europe/London',
    defaultValue: 'Europe/London',
    label: 'London',
  },
  {
    region: 'Europe',
    value: 'Europe/Luxembourg',
    defaultValue: 'Europe/Luxembourg',
    label: 'Luxembourg',
  },
  {
    region: 'Europe',
    value: 'Europe/Madrid',
    defaultValue: 'Europe/Madrid',
    label: 'Madrid',
  },
  {
    region: 'Europe',
    value: 'Europe/Malta',
    defaultValue: 'Europe/Malta',
    label: 'Malta',
  },
  {
    region: 'Europe',
    value: 'Europe/Mariehamn',
    defaultValue: 'Europe/Mariehamn',
    label: 'Mariehamn',
  },
  {
    region: 'Europe',
    value: 'Europe/Minsk',
    defaultValue: 'Europe/Minsk',
    label: 'Minsk',
  },
  {
    region: 'Europe',
    value: 'Europe/Monaco',
    defaultValue: 'Europe/Monaco',
    label: 'Monaco',
  },
  {
    region: 'Europe',
    value: 'Europe/Moscow',
    defaultValue: 'Europe/Moscow',
    label: 'Moscow',
  },
  {
    region: 'Europe',
    value: 'Europe/Oslo',
    defaultValue: 'Europe/Oslo',
    label: 'Oslo',
  },
  {
    region: 'Europe',
    value: 'Europe/Paris',
    defaultValue: 'Europe/Paris',
    label: 'Paris',
  },
  {
    region: 'Europe',
    value: 'Europe/Podgorica',
    defaultValue: 'Europe/Podgorica',
    label: 'Podgorica',
  },
  {
    region: 'Europe',
    value: 'Europe/Prague',
    defaultValue: 'Europe/Prague',
    label: 'Prague',
  },
  {
    region: 'Europe',
    value: 'Europe/Riga',
    defaultValue: 'Europe/Riga',
    label: 'Riga',
  },
  {
    region: 'Europe',
    value: 'Europe/Rome',
    defaultValue: 'Europe/Rome',
    label: 'Rome',
  },
  {
    region: 'Europe',
    value: 'Europe/Samara',
    defaultValue: 'Europe/Samara',
    label: 'Samara',
  },
  {
    region: 'Europe',
    value: 'Europe/San_Marino',
    defaultValue: 'Europe/San_Marino',
    label: 'San_Marino',
  },
  {
    region: 'Europe',
    value: 'Europe/Sarajevo',
    defaultValue: 'Europe/Sarajevo',
    label: 'Sarajevo',
  },
  {
    region: 'Europe',
    value: 'Europe/Saratov',
    defaultValue: 'Europe/Saratov',
    label: 'Saratov',
  },
  {
    region: 'Europe',
    value: 'Europe/Simferopol',
    defaultValue: 'Europe/Simferopol',
    label: 'Simferopol',
  },
  {
    region: 'Europe',
    value: 'Europe/Skopje',
    defaultValue: 'Europe/Skopje',
    label: 'Skopje',
  },
  {
    region: 'Europe',
    value: 'Europe/Sofia',
    defaultValue: 'Europe/Sofia',
    label: 'Sofia',
  },
  {
    region: 'Europe',
    value: 'Europe/Stockholm',
    defaultValue: 'Europe/Stockholm',
    label: 'Stockholm',
  },
  {
    region: 'Europe',
    value: 'Europe/Tallinn',
    defaultValue: 'Europe/Tallinn',
    label: 'Tallinn',
  },
  {
    region: 'Europe',
    value: 'Europe/Tirane',
    defaultValue: 'Europe/Tirane',
    label: 'Tirane',
  },
  {
    region: 'Europe',
    value: 'Europe/Ulyanovsk',
    defaultValue: 'Europe/Ulyanovsk',
    label: 'Ulyanovsk',
  },
  {
    region: 'Europe',
    value: 'Europe/Uzhgorod',
    defaultValue: 'Europe/Uzhgorod',
    label: 'Uzhgorod',
  },
  {
    region: 'Europe',
    value: 'Europe/Vaduz',
    defaultValue: 'Europe/Vaduz',
    label: 'Vaduz',
  },
  {
    region: 'Europe',
    value: 'Europe/Vatican',
    defaultValue: 'Europe/Vatican',
    label: 'Vatican',
  },
  {
    region: 'Europe',
    value: 'Europe/Vienna',
    defaultValue: 'Europe/Vienna',
    label: 'Vienna',
  },
  {
    region: 'Europe',
    value: 'Europe/Vilnius',
    defaultValue: 'Europe/Vilnius',
    label: 'Vilnius',
  },
  {
    region: 'Europe',
    value: 'Europe/Volgograd',
    defaultValue: 'Europe/Volgograd',
    label: 'Volgograd',
  },
  {
    region: 'Europe',
    value: 'Europe/Warsaw',
    defaultValue: 'Europe/Warsaw',
    label: 'Warsaw',
  },
  {
    region: 'Europe',
    value: 'Europe/Zagreb',
    defaultValue: 'Europe/Zagreb',
    label: 'Zagreb',
  },
  {
    region: 'Europe',
    value: 'Europe/Zaporozhye',
    defaultValue: 'Europe/Zaporozhye',
    label: 'Zaporozhye',
  },
  {
    region: 'Europe',
    value: 'Europe/Zurich',
    defaultValue: 'Europe/Zurich',
    label: 'Zurich',
  },
  {
    region: 'Africa',
    value: 'Africa/Abidjan',
    defaultValue: 'Africa/Abidjan',
    label: 'Abidjan',
  },
  {
    region: 'Africa',
    value: 'Africa/Accra',
    defaultValue: 'Africa/Accra',
    label: 'Accra',
  },
  {
    region: 'Africa',
    value: 'Africa/Addis_Ababa',
    defaultValue: 'Africa/Addis_Ababa',
    label: 'Addis_Ababa',
  },
  {
    region: 'Africa',
    value: 'Africa/Algiers',
    defaultValue: 'Africa/Algiers',
    label: 'Algiers',
  },
  {
    region: 'Africa',
    value: 'Africa/Asmara',
    defaultValue: 'Africa/Asmara',
    label: 'Asmara',
  },
  {
    region: 'Africa',
    value: 'Africa/Bamako',
    defaultValue: 'Africa/Bamako',
    label: 'Bamako',
  },
  {
    region: 'Africa',
    value: 'Africa/Bangui',
    defaultValue: 'Africa/Bangui',
    label: 'Bangui',
  },
  {
    region: 'Africa',
    value: 'Africa/Banjul',
    defaultValue: 'Africa/Banjul',
    label: 'Banjul',
  },
  {
    region: 'Africa',
    value: 'Africa/Bissau',
    defaultValue: 'Africa/Bissau',
    label: 'Bissau',
  },
  {
    region: 'Africa',
    value: 'Africa/Blantyre',
    defaultValue: 'Africa/Blantyre',
    label: 'Blantyre',
  },
  {
    region: 'Africa',
    value: 'Africa/Brazzaville',
    defaultValue: 'Africa/Brazzaville',
    label: 'Brazzaville',
  },
  {
    region: 'Africa',
    value: 'Africa/Bujumbura',
    defaultValue: 'Africa/Bujumbura',
    label: 'Bujumbura',
  },
  {
    region: 'Africa',
    value: 'Africa/Cairo',
    defaultValue: 'Africa/Cairo',
    label: 'Cairo',
  },
  {
    region: 'Africa',
    value: 'Africa/Casablanca',
    defaultValue: 'Africa/Casablanca',
    label: 'Casablanca',
  },
  {
    region: 'Africa',
    value: 'Africa/Ceuta',
    defaultValue: 'Africa/Ceuta',
    label: 'Ceuta',
  },
  {
    region: 'Africa',
    value: 'Africa/Conakry',
    defaultValue: 'Africa/Conakry',
    label: 'Conakry',
  },
  {
    region: 'Africa',
    value: 'Africa/Dakar',
    defaultValue: 'Africa/Dakar',
    label: 'Dakar',
  },
  {
    region: 'Africa',
    value: 'Africa/Dar_es_Salaam',
    defaultValue: 'Africa/Dar_es_Salaam',
    label: 'Dar_es_Salaam',
  },
  {
    region: 'Africa',
    value: 'Africa/Djibouti',
    defaultValue: 'Africa/Djibouti',
    label: 'Djibouti',
  },
  {
    region: 'Africa',
    value: 'Africa/Douala',
    defaultValue: 'Africa/Douala',
    label: 'Douala',
  },
  {
    region: 'Africa',
    value: 'Africa/El_Aaiun',
    defaultValue: 'Africa/El_Aaiun',
    label: 'El_Aaiun',
  },
  {
    region: 'Africa',
    value: 'Africa/Freetown',
    defaultValue: 'Africa/Freetown',
    label: 'Freetown',
  },
  {
    region: 'Africa',
    value: 'Africa/Gaborone',
    defaultValue: 'Africa/Gaborone',
    label: 'Gaborone',
  },
  {
    region: 'Africa',
    value: 'Africa/Harare',
    defaultValue: 'Africa/Harare',
    label: 'Harare',
  },
  {
    region: 'Africa',
    value: 'Africa/Johannesburg',
    defaultValue: 'Africa/Johannesburg',
    label: 'Johannesburg',
  },
  {
    region: 'Africa',
    value: 'Africa/Juba',
    defaultValue: 'Africa/Juba',
    label: 'Juba',
  },
  {
    region: 'Africa',
    value: 'Africa/Kampala',
    defaultValue: 'Africa/Kampala',
    label: 'Kampala',
  },
  {
    region: 'Africa',
    value: 'Africa/Khartoum',
    defaultValue: 'Africa/Khartoum',
    label: 'Khartoum',
  },
  {
    region: 'Africa',
    value: 'Africa/Kigali',
    defaultValue: 'Africa/Kigali',
    label: 'Kigali',
  },
  {
    region: 'Africa',
    value: 'Africa/Kinshasa',
    defaultValue: 'Africa/Kinshasa',
    label: 'Kinshasa',
  },
  {
    region: 'Africa',
    value: 'Africa/Lagos',
    defaultValue: 'Africa/Lagos',
    label: 'Lagos',
  },
  {
    region: 'Africa',
    value: 'Africa/Libreville',
    defaultValue: 'Africa/Libreville',
    label: 'Libreville',
  },
  {
    region: 'Africa',
    value: 'Africa/Lome',
    defaultValue: 'Africa/Lome',
    label: 'Lome',
  },
  {
    region: 'Africa',
    value: 'Africa/Luanda',
    defaultValue: 'Africa/Luanda',
    label: 'Luanda',
  },
  {
    region: 'Africa',
    value: 'Africa/Lubumbashi',
    defaultValue: 'Africa/Lubumbashi',
    label: 'Lubumbashi',
  },
  {
    region: 'Africa',
    value: 'Africa/Lusaka',
    defaultValue: 'Africa/Lusaka',
    label: 'Lusaka',
  },
  {
    region: 'Africa',
    value: 'Africa/Malabo',
    defaultValue: 'Africa/Malabo',
    label: 'Malabo',
  },
  {
    region: 'Africa',
    value: 'Africa/Maputo',
    defaultValue: 'Africa/Maputo',
    label: 'Maputo',
  },
  {
    region: 'Africa',
    value: 'Africa/Maseru',
    defaultValue: 'Africa/Maseru',
    label: 'Maseru',
  },
  {
    region: 'Africa',
    value: 'Africa/Mbabane',
    defaultValue: 'Africa/Mbabane',
    label: 'Mbabane',
  },
  {
    region: 'Africa',
    value: 'Africa/Mogadishu',
    defaultValue: 'Africa/Mogadishu',
    label: 'Mogadishu',
  },
  {
    region: 'Africa',
    value: 'Africa/Monrovia',
    defaultValue: 'Africa/Monrovia',
    label: 'Monrovia',
  },
  {
    region: 'Africa',
    value: 'Africa/Nairobi',
    defaultValue: 'Africa/Nairobi',
    label: 'Nairobi',
  },
  {
    region: 'Africa',
    value: 'Africa/Ndjamena',
    defaultValue: 'Africa/Ndjamena',
    label: 'Ndjamena',
  },
  {
    region: 'Africa',
    value: 'Africa/Niamey',
    defaultValue: 'Africa/Niamey',
    label: 'Niamey',
  },
  {
    region: 'Africa',
    value: 'Africa/Nouakchott',
    defaultValue: 'Africa/Nouakchott',
    label: 'Nouakchott',
  },
  {
    region: 'Africa',
    value: 'Africa/Ouagadougou',
    defaultValue: 'Africa/Ouagadougou',
    label: 'Ouagadougou',
  },
  {
    region: 'Africa',
    value: 'Africa/Porto-Novo',
    defaultValue: 'Africa/Porto-Novo',
    label: 'Porto-Novo',
  },
  {
    region: 'Africa',
    value: 'Africa/Sao_Tome',
    defaultValue: 'Africa/Sao_Tome',
    label: 'Sao_Tome',
  },
  {
    region: 'Africa',
    value: 'Africa/Tripoli',
    defaultValue: 'Africa/Tripoli',
    label: 'Tripoli',
  },
  {
    region: 'Africa',
    value: 'Africa/Tunis',
    defaultValue: 'Africa/Tunis',
    label: 'Tunis',
  },
  {
    region: 'Africa',
    value: 'Africa/Windhoek',
    defaultValue: 'Africa/Windhoek',
    label: 'Windhoek',
  },
  {
    region: 'Australia',
    value: 'Australia/Adelaide',
    defaultValue: 'Australia/Adelaide',
    label: 'Adelaide',
  },
  {
    region: 'Australia',
    value: 'Australia/Brisbane',
    defaultValue: 'Australia/Brisbane',
    label: 'Brisbane',
  },
  {
    region: 'Australia',
    value: 'Australia/Broken_Hill',
    defaultValue: 'Australia/Broken_Hill',
    label: 'Broken_Hill',
  },
  {
    region: 'Australia',
    value: 'Australia/Currie',
    defaultValue: 'Australia/Currie',
    label: 'Currie',
  },
  {
    region: 'Australia',
    value: 'Australia/Darwin',
    defaultValue: 'Australia/Darwin',
    label: 'Darwin',
  },
  {
    region: 'Australia',
    value: 'Australia/Eucla',
    defaultValue: 'Australia/Eucla',
    label: 'Eucla',
  },
  {
    region: 'Australia',
    value: 'Australia/Hobart',
    defaultValue: 'Australia/Hobart',
    label: 'Hobart',
  },
  {
    region: 'Australia',
    value: 'Australia/Lindeman',
    defaultValue: 'Australia/Lindeman',
    label: 'Lindeman',
  },
  {
    region: 'Australia',
    value: 'Australia/Lord_Howe',
    defaultValue: 'Australia/Lord_Howe',
    label: 'Lord_Howe',
  },
  {
    region: 'Australia',
    value: 'Australia/Melbourne',
    defaultValue: 'Australia/Melbourne',
    label: 'Melbourne',
  },
  {
    region: 'Australia',
    value: 'Australia/Perth',
    defaultValue: 'Australia/Perth',
    label: 'Perth',
  },
  {
    region: 'Australia',
    value: 'Australia/Sydney',
    defaultValue: 'Australia/Sydney',
    label: 'Sydney',
  },
  {
    region: 'Asia',
    value: 'Asia/Manila',
    defaultValue: 'Asia/Manila',
    label: 'Manila',
  },
  {
    region: 'Asia',
    value: 'Asia/Kabul',
    defaultValue: 'Asia/Kabul',
    label: 'Kabul',
  },
  {
    region: 'Asia',
    value: 'Asia/Seoul',
    defaultValue: 'Asia/Seoul',
    label: 'Seoul',
  },
] as const;
