import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import * as Modal from '#components/Modal';
import useAsync from '#hooks/async';
import { noShow } from '#services/api/appointment';

type NoShowModalProps = {
  appointmentId: number;
  onClose: () => void;
  onSuccess: () => void;
};

export default function NoShowModal(props: NoShowModalProps) {
  const noShowQuery = useAsync<Awaited<ReturnType<typeof noShow>>>();
  const { enqueueSnackbar } = useSnackbar();

  function handleConfirm() {
    void noShowQuery.run(noShow(props.appointmentId)).then((result) => {
      if (result instanceof Error) {
        enqueueSnackbar(
          'Could not mark the appointment as no show. Please try again later.',
          { variant: 'error' }
        );
      } else {
        props.onSuccess();
      }
      props.onClose();
    });
  }

  return (
    <Modal.Root
      maxWidth="sm"
      open
      onClose={props.onClose}
      isLoading={noShowQuery.isLoading}
      aria-labelledby="no-show-appointment-modal-title"
    >
      <Modal.Header>
        <Modal.Title id="no-show-appointment-modal-title">
          Confirm No Show
        </Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <Typography variant="body1">
          Are you sure you want to mark this patient as a no show? This will
          close the current visit and check out the appointment.
        </Typography>
      </Modal.Content>

      <Modal.Footer>
        <Modal.DismissButton />
        <Button onClick={handleConfirm}>Mark No Show</Button>
      </Modal.Footer>
    </Modal.Root>
  );
}
