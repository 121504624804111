import type { Theme } from '@mend/mui-theme';

import * as React from 'react';
import { Circle } from '@mui/icons-material';
import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { InView } from 'react-intersection-observer';

type ContextValue = {
  active: string | null;
  setPanelAsActive: (id: string) => void;
  gotoPanel: (id: string) => void;
};

const SideScrollContext = React.createContext<ContextValue | null>(null);
SideScrollContext.displayName = 'SideScrollContext';

const useSideScroll = () => React.useContext(SideScrollContext)!;

type RootProps = {
  children: React.ReactNode;
};

const getPanelSelector = (id: string) => `[data-sidescroll-panel="${id}"]`;

export function Root({ children }: RootProps): JSX.Element {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [active, setActive] = React.useState<string | null>(null);

  const value = React.useMemo(
    () => ({
      active,
      setPanelAsActive: (id: string) => setActive(id),
      gotoPanel: (id: string) => {
        containerRef?.current
          ?.querySelector(getPanelSelector(id))
          ?.scrollIntoView();
      },
    }),
    [active]
  );

  return (
    <SideScrollContext.Provider value={value}>
      <Stack
        ref={containerRef}
        spacing={2}
        alignItems="flex-start"
        height="100%"
      >
        {children}
      </Stack>
    </SideScrollContext.Provider>
  );
}

type ListProps = {
  children: React.ReactNode;
};

export function List({ children }: ListProps): JSX.Element | null {
  const shouldRender = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <MuiList sx={{ height: '100%', overflow: 'auto' }}>{children}</MuiList>
  );
}

type ListItemProps = {
  id: string;
  label: string;
};

export function ListItem({ id, label }: ListItemProps): JSX.Element {
  const { active, gotoPanel } = useSideScroll();
  const isItemActive = active === id;

  return (
    <MuiListItem disablePadding>
      <MuiListItemButton
        sx={{ color: isItemActive ? 'primary.main' : undefined }}
        onClick={() => gotoPanel(id)}
      >
        <MuiListItemIcon sx={{ minWidth: 20, color: 'inherit' }}>
          {isItemActive && <Circle sx={{ fontSize: 10 }} />}
        </MuiListItemIcon>
        <MuiListItemText
          primary={label}
          primaryTypographyProps={{ color: 'inherit' }}
        />
      </MuiListItemButton>
    </MuiListItem>
  );
}

type PanelsProps = {
  children: React.ReactNode;
};

export function Panels({ children }: PanelsProps): JSX.Element {
  return (
    <Stack
      direction="column"
      spacing={3}
      height="100%"
      width="100%"
      overflow="auto"
      padding={1}
    >
      {children}
    </Stack>
  );
}

type PanelProps = {
  id: string;
  children: React.ReactNode;
};

export function Panel({ id, children }: PanelProps): JSX.Element {
  const { setPanelAsActive } = useSideScroll();

  return (
    <InView
      as="section"
      threshold={1}
      rootMargin="0%"
      onChange={(inView) => {
        if (inView) {
          setPanelAsActive(id);
        }
      }}
      data-sidescroll-panel={id}
    >
      {children}
    </InView>
  );
}
