import { logging } from './http-clients';

type LoggingServiceResponse = { message: string };

export function logZoomData<
  TData extends {
    vendorSessionId: string;
    sessionUniqueId: string;
    user_data: {
      userId: string;
      userName: string;
      orgId: string;
      videoCode: string;
      appointmentId: string;
    };
  },
>(data: TData): Promise<LoggingServiceResponse> {
  return logging
    .post<LoggingServiceResponse>('/video/session', { ...data, vendor: 'Zoom' })
    .then((response) => response.data);
}
