import * as React from 'react';
import { isPast } from 'date-fns';

import useCalendarError from './calendar-error';

type EventDropInfo = {
  eventId: string;
  startDate: string;
};
export default function usePastEventDropError(): ({
  eventId,
  startDate,
}: EventDropInfo) => void {
  const calendarError = useCalendarError();
  return React.useCallback(
    ({ eventId, startDate }: EventDropInfo) => {
      if (eventId === 'new-appointment' && isPast(new Date(startDate))) {
        calendarError('past');
      }
    },
    [calendarError]
  );
}
