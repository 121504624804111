import type { MatchSorterOptions } from 'match-sorter';

import { matchSorter as msMatchSorter } from 'match-sorter';

/**
 * The default value for `threshold` is `MATCHES`, we bump it up to the next
 * ranking criteria as the default in order to avoid matching elements with
 * characters in the same order as the search criteria but with other
 * characters between them.
 *
 * @see https://github.com/kentcdodds/match-sorter#this-solution
 * @see https://github.com/kentcdodds/match-sorter#threshold-number
 */
export const matchSorter = <ItemType = string>(
  items: ReadonlyArray<ItemType>,
  value: string,
  options: MatchSorterOptions<ItemType> = {}
): ItemType[] =>
  msMatchSorter(items, value, {
    threshold: msMatchSorter.rankings.ACRONYM,
    ...options,
  });
