import type { SnackbarKey } from 'notistack';

import * as React from 'react';
import { useSnackbar } from 'notistack';

type Error = 'past' | 'overlap' | 'patient';

export default function useCalendarError(): (error: Error) => SnackbarKey {
  const { enqueueSnackbar } = useSnackbar();

  return React.useCallback(
    (error: Error) => {
      const sharedPhrase = 'Please select another time.';
      const errors = {
        overlap: `Overlapping appointments are not permitted. ${sharedPhrase}`,
        patient: `Unavailable slot. ${sharedPhrase}`,
        past: `You have selected a time in the past!`,
      };
      const text = errors[error];
      return enqueueSnackbar(text, { variant: 'error' });
    },
    [enqueueSnackbar]
  );
}
