import type { DateSelectArg, EventDropArg } from '@fullcalendar/react';
import type { Theme } from '@mend/mui-theme';
import type { SlotLength } from '#components/Autocomplete/SlotLengths/SlogLengthsAutocomplete.types';
import type { Properties } from '#services/api/properties';
import type { Address, User } from '#services/api/user';
import type { Appointment, AppointmentType } from '#types/appointment';

import { Box, Stack, useMediaQuery } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import AppointmentTypesAutocomplete from '#components/Autocomplete/AppointmentTypesAutocomplete';
import LocationsAutocomplete from '#components/Autocomplete/LocationsAutocomplete';
import SlotLengthsAutocomplete from '#components/Autocomplete/SlotLengths/SlotLengthsAutocomplete';
import Calendar from '#components/Calendar/Calendar';
import ProviderCalendarInput from '#components/Calendar/CalendarInputs/Provider';
import TimezoneCalendarInput from '#components/Calendar/CalendarInputs/Timezone';
import { type SlotRange } from '#components/TransferVisitModal/utils';
import useAppointmentScheduleEventSources from '#hooks/appointments-schedule-event-sources';
import { me } from '#lib/react-query/queries';
import { getTimezoneFromInputValueWithSuffix } from '#utils/timezones.ts';

const availableAppointmentTypes: AppointmentType['type'][] = [
  'Video',
  'In Office',
  'Office Video',
  'Phone',
];

const filterAppointmentTypes = (appointmentTypes: AppointmentType[]) =>
  appointmentTypes.filter((appointmentType) =>
    availableAppointmentTypes.includes(appointmentType.type)
  );

export type AppointmentDateRange = {
  start: string; // ISO string of the date
  end: string; // Same as start
};

type BookAgainCalendarProps = {
  newAppointmentsRange: AppointmentDateRange[];
  appointment: Appointment;
  onDateSelectChange: (selectInfo: DateSelectArg) => void;
  onEventDropChange: (eventDrop: EventDropArg) => void;
  patientTimezone: string;
  providerTimezone: string;
  timezone: string;
  onTimezoneChange: (timezone: string) => void;
  currentSlotLength: SlotLength;
  onSlotLengthChange: (value: SlotLength) => void;
  selectedProviderId: number;
  selectedProvider: User;
  onSelectedProviderChange: (value: User) => void;
  appointmentType: AppointmentType;
  onAppointmentTypeChange: (value: AppointmentType) => void;
  onApplyProviderSelection: (user: User, selectedSlot: SlotRange) => void;
  isLocationRequired: boolean;
  onLocationAddressChange: (value: Address | null) => void;
  locationAddress: Address | null;
  properties?: Properties;
};

export default function BookAgainCalendar({
  newAppointmentsRange,
  appointment,
  onDateSelectChange,
  onEventDropChange,
  patientTimezone,
  providerTimezone,
  timezone,
  onTimezoneChange,
  currentSlotLength,
  onSlotLengthChange,
  selectedProviderId,
  selectedProvider,
  onSelectedProviderChange,
  appointmentType,
  onAppointmentTypeChange,
  onApplyProviderSelection,
  locationAddress,
  onLocationAddressChange,
  isLocationRequired,
  properties,
}: BookAgainCalendarProps): JSX.Element {
  const { data: { user } = {} } = useQuery(me);

  const isPatient = user?.role === 'Patient';

  const { eventSources } = useAppointmentScheduleEventSources({
    newAppointmentsRange,
    selectedProviderId,
    selectedAppointmentTypeId: appointmentType?.id,
    selectedLocationId: locationAddress?.id,
  });

  const isSmallPlus = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm')
  );

  return (
    <>
      {!isPatient && (
        <Stack alignItems="center" direction="column">
          <ProviderCalendarInput
            selectedProvider={selectedProvider}
            onSelectedProviderChange={onSelectedProviderChange}
            appointment={appointment}
            onApplyProviderSelection={onApplyProviderSelection}
          />
          <Stack
            width="100%"
            alignItems="flex-start"
            direction={isSmallPlus ? 'row' : 'column'}
            spacing={2}
          >
            <AppointmentTypesAutocomplete
              disablePortal
              disableClearable
              value={appointmentType}
              onChange={(_event, value) => onAppointmentTypeChange(value)}
              dataFilter={filterAppointmentTypes}
            />
            <LocationsAutocomplete
              disablePortal
              value={locationAddress}
              onChange={(_event, value) => onLocationAddressChange(value)}
              inputHelperText={
                !isLocationRequired
                  ? 'Not required for this appointment type'
                  : !locationAddress
                    ? 'Required'
                    : ' '
              }
              inputError={!locationAddress && isLocationRequired}
              disabled={!isLocationRequired}
            />
            <SlotLengthsAutocomplete
              disableClearable
              value={currentSlotLength}
              onChange={(_event, value) => onSlotLengthChange(value)}
            />
          </Stack>
          <TimezoneCalendarInput
            timezone={timezone}
            onTimezoneChange={onTimezoneChange}
            patientTimezone={patientTimezone}
            providerTimezone={providerTimezone}
          />
        </Stack>
      )}
      <Box width="100%">
        <Calendar
          handleDateSelect={onDateSelectChange}
          eventDrop={onEventDropChange}
          timezone={getTimezoneFromInputValueWithSuffix(timezone)}
          currentSlotLength={currentSlotLength}
          eventSources={eventSources}
          user={user}
          properties={properties}
        />
      </Box>
    </>
  );
}
