import type { DateClickArg } from '@fullcalendar/interaction';
import type {
  DateSelectArg,
  DatesSetArg,
  DurationInput,
  EventApi,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventInput,
  EventSourceInput,
  OverlapFunc,
} from '@fullcalendar/react';
import type FullCalendarApi from '@fullcalendar/react';
import type { Properties } from '#services/api/properties';
import type { User } from '#services/api/user';

import * as React from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isPast } from 'date-fns';

import { formatUTCStringWithTimezone } from '#utils/dates';
import { roleGreaterOrEquals } from '#utils/roles';
import useCalendarError from './hooks/calendar-error';

const TooltipTypography = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: 12,
  paddingBottom: theme.spacing(1),
}));

type CalendarProps = {
  handleEvents?: (events: EventApi[]) => void;
  eventSources: EventSourceInput[];
  handleDateSelect: (selectInfo: DateSelectArg) => void;
  eventDrop: (eventDrop: EventDropArg) => void;
  timezone: string;
  currentSlotLength: string;
  properties?: Properties;
  user?: User;
  weekendsVisible?: boolean;
  handleDateSet?: (dateSetInfo: DatesSetArg) => void;
  eventResizableFromStart?: boolean;
  eventDurationEditable?: boolean;
  handleEventClick?: (eventClick: EventClickArg) => void;
  slotDuration?: DurationInput | Duration | null;
  initialEvents?: EventInput[];
  selectMirror?: boolean;
  selectOverlap?: boolean | OverlapFunc;
  eventOverlap?: boolean | OverlapFunc;
};

export default function Calendar({
  weekendsVisible = true,
  eventDurationEditable = false,
  eventResizableFromStart = false,
  selectMirror = false,
  selectOverlap,
  eventOverlap,
  eventDrop,
  initialEvents,
  handleDateSelect,
  handleEventClick,
  handleEvents,
  eventSources,
  handleDateSet,
  timezone,
  currentSlotLength,
  user,
  properties,
}: CalendarProps): JSX.Element {
  const isStaffOrGreater = roleGreaterOrEquals(user?.role, 'Staff');
  const calendarRef = React.useRef<FullCalendarApi | null>(null);
  const dragEventRef = React.useRef(false);
  const overlapStaffEventRef = React.useRef(false);
  const overlapPatientEventRef = React.useRef(false);
  const isOverlapPrevented = properties?.['org.scheduling.prevent.overlapping'];
  const calendarError = useCalendarError();

  const renderInnerContent = (innerProps: EventContentArg) => {
    return (
      <div>
        <Box fontWeight={700}>{innerProps.event.title}</Box>
        <Box>
          {innerProps.event.title !== 'Unavailable' &&
            `${formatUTCStringWithTimezone(
              innerProps.event.startStr,
              timezone,
              'hh:mm aaa'
            )} - ${formatUTCStringWithTimezone(
              innerProps.event.endStr,
              timezone,
              'hh:mm aaa'
            )}`}
        </Box>
      </div>
    );
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    const title = (
      <Box p={2}>
        <TooltipTypography fontWeight={700}>
          Additional Information:
        </TooltipTypography>
        <TooltipTypography>
          Start Date:{' '}
          {formatUTCStringWithTimezone(
            eventInfo.event.startStr,
            timezone,
            'hh:mm aaa'
          )}
        </TooltipTypography>
        <TooltipTypography>
          End Date:{' '}
          {formatUTCStringWithTimezone(
            eventInfo.event.endStr,
            timezone,
            'hh:mm aaa'
          )}
        </TooltipTypography>
      </Box>
    );
    return (
      <Tooltip placement="right" title={title}>
        {renderInnerContent(eventInfo)}
      </Tooltip>
    );
  };

  const handleDateClick = (dateClickInfo: DateClickArg) => {
    if (isPast(new Date(dateClickInfo.dateStr))) {
      calendarError('past');
    }
  };

  const calendarHandleDateSelect = (selectInfo: DateSelectArg) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi?.view?.type === 'dayGridMonth') {
        calendarApi.changeView('timeGridDay', selectInfo.start);
        return;
      }
    }

    handleDateSelect(selectInfo);
  };

  const defaultEventOverlap = (event: EventApi) => {
    const backgroundEvent = event.title === 'Unavailable';
    if (backgroundEvent) {
      overlapPatientEventRef.current = true;
    } else {
      overlapStaffEventRef.current = true;
    }
    return isStaffOrGreater && (backgroundEvent || !isOverlapPrevented);
  };

  /**
   * TODO tech debt:
   * - Removed the height prop
   * - Removed the calendar wrapper
   * - Added the contentHeight prop because without it the calendar wasn't
   *   showing up. This caused some slight UI/scroll changes between this
   *   implementation and the portal's, addressed via styles in
   *   /src/styles/fullcalendar (also copied from the portal).
   */
  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[interactionPlugin, dayGridPlugin, listPlugin, timeGridPlugin]}
      headerToolbar={{
        left: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title',
        right: 'today prev,next',
      }}
      initialView="timeGridWeek"
      timeZone={timezone}
      weekends={weekendsVisible}
      initialEvents={initialEvents}
      /*By default timed-events are displayed as a dot, without bg color */
      eventDisplay="block"
      editable
      eventResizableFromStart={eventResizableFromStart}
      eventDurationEditable={eventDurationEditable}
      eventDrop={eventDrop}
      eventDragStop={() => {
        dragEventRef.current = true;
        if (
          overlapStaffEventRef.current &&
          dragEventRef.current &&
          isOverlapPrevented
        ) {
          calendarError('overlap');
          overlapStaffEventRef.current = false;
        } else if (
          !isStaffOrGreater &&
          dragEventRef.current &&
          overlapPatientEventRef.current
        ) {
          calendarError('patient');
          overlapPatientEventRef.current = false;
        }
      }}
      selectable
      lazyFetching
      selectOverlap={selectOverlap ?? defaultEventOverlap}
      eventOverlap={eventOverlap ?? defaultEventOverlap}
      /*Draws a placeholder event in selection*/
      selectMirror={selectMirror}
      select={calendarHandleDateSelect}
      eventContent={renderEventContent}
      eventClick={handleEventClick}
      dateClick={handleDateClick}
      datesSet={handleDateSet}
      eventSources={eventSources}
      eventsSet={handleEvents}
      allDaySlot={false}
      slotDuration={`00:${
        currentSlotLength === '5'
          ? `${currentSlotLength.padStart(2, '0')}`
          : currentSlotLength
      }:00`}
      stickyHeaderDates
      contentHeight="auto"
    />
  );
}
