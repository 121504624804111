import type { Row } from 'react-table';
import type { SharedTableProps } from './Table';

import * as React from 'react';
import { styled } from '@mend/mui-theme';
import { Box, Typography } from '@mui/material';

const OuterGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  // Dynamically change between 1-2 columns based on the size of the grid
  gridTemplateColumns: `repeat(auto-fit, minmax(min(${
    theme.breakpoints.values.sm / 2 // 300
  }px, 100%), 1fr))`,
  alignItems: 'start',
  rowGap: theme.spacing(1),
  columnGap: theme.spacing(2),
}));

const InnerGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  /**
   * We use `0` so it has a **defined** min width instead of an auto min width
   * to prevent the children from overflowing the grid.
   */
  gridTemplateColumns: 'min-content minmax(0, 1fr)',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const defaultTruncatedColumnIds: string[] = [];

export type SubRowProps<
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
> = Pick<
  SharedTableProps<T, P>,
  'truncatedColumnsIds' | 'getCellRendererProps'
> & {
  row: Row<T>;
  children?: React.ReactNode;
};

export function SubRow<
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
>({
  row: { allCells },
  getCellRendererProps,
  truncatedColumnsIds = defaultTruncatedColumnIds,
  children,
}: SubRowProps<T, P>): JSX.Element {
  const truncatedCells = React.useMemo(
    () =>
      allCells.filter((cell) => truncatedColumnsIds.includes(cell.column.id)),
    [allCells, truncatedColumnsIds]
  );

  const groups = React.useMemo(() => {
    if (truncatedCells.length === 0) return [];
    const halfIndex = Math.ceil(truncatedCells.length / 2);
    return [
      truncatedCells.slice(0, halfIndex),
      truncatedCells.slice(halfIndex),
    ];
  }, [truncatedCells]);

  return (
    <>
      <OuterGrid>
        {groups.map((group, index) => (
          // Making an exception for the key here
          <InnerGrid key={index}>
            {group.map((cell) => (
              <React.Fragment key={cell.column.id}>
                <Typography
                  component="span"
                  variant="inherit"
                  whiteSpace="nowrap"
                >
                  {cell.render('Header')}
                </Typography>
                <Typography component="span" variant="inherit">
                  {cell.render('Cell', {
                    customProps: {
                      ...getCellRendererProps?.(cell),
                      isColumnTruncated: true,
                    },
                  })}
                </Typography>
              </React.Fragment>
            ))}
          </InnerGrid>
        ))}
      </OuterGrid>

      {children}
    </>
  );
}

export default SubRow;
