import type { BoxProps } from '@mui/material';

import { Box } from '@mui/material';

/*
 * TODO update with mui when released
 * This might be temporary,
 * since the TabPanel MUI component,
 * is still in BETA.
 * */

type PanelProps = Omit<BoxProps, 'role' | 'hidden'> & {
  value: number | string;
  index: number | string;
};

export function TabPanel({
  children,
  value,
  index,
  ...props
}: PanelProps): JSX.Element {
  return (
    <Box role="tabpanel" hidden={value !== index} {...props}>
      {value === index ? children : null}
    </Box>
  );
}

export default TabPanel;
