import type { EventSourceInput } from '@fullcalendar/react';

import * as React from 'react';
import { green } from '@mend/mui-theme/colors';
import { format } from 'date-fns';

import { type AppointmentDateRange } from '#components/BookAgainModal/BookAgainCalendar';
import {
  createEventsFromBackgroundEvents,
  createEventsFromSchedule,
} from '#components/Calendar/utils';
import { getProviderSchedule } from '#services/api/appointment';
import { getProviderUnavailability } from '#services/api/user';
import { formatUTCStringWithTimezone } from '#utils/dates';

export default function useAppointmentScheduleEventSources({
  newAppointmentsRange,
  selectedProviderId,
  selectedAppointmentTypeId,
  selectedLocationId,
}: {
  newAppointmentsRange: AppointmentDateRange[];
  selectedProviderId: number;
  selectedAppointmentTypeId?: number;
  selectedLocationId?: number;
}): {
  eventSources: EventSourceInput[];
} {
  const newAppointmentsEventSource = React.useMemo<EventSourceInput>(
    () => ({
      id: 'new-appointments',
      events: ({ timeZone }, successCallback) => {
        successCallback(
          newAppointmentsRange.map(({ start, end }) => ({
            id: 'new-appointment',
            title: 'New Appointment',
            color: green[900],
            start: formatUTCStringWithTimezone(start, timeZone),
            end: formatUTCStringWithTimezone(end, timeZone),
          }))
        );
      },
    }),
    [newAppointmentsRange]
  );

  const unavailabilityEventSource = React.useMemo<EventSourceInput>(
    () => ({
      id: 'unavailability',
      events: ({ start, end, timeZone }, successCallback, failureCallback) => {
        getProviderUnavailability(selectedProviderId, {
          startDate: format(start, 'yyyy-MM-dd'),
          endDate: format(end, 'yyyy-MM-dd'),
          timeZone,
          appointmentTypeId: selectedAppointmentTypeId,
          addressId: selectedLocationId,
        })
          .then((res) => {
            successCallback(createEventsFromBackgroundEvents(res));
          })
          .catch(failureCallback);
      },
    }),
    [selectedAppointmentTypeId, selectedLocationId, selectedProviderId]
  );

  const providerScheduleEventSource = React.useMemo<EventSourceInput>(
    () => ({
      id: 'providerSchedule',
      events: ({ start, end, timeZone }, successCallback, failureCallback) => {
        getProviderSchedule({
          startDate: format(start, 'yyyy-MM-dd'),
          endDate: format(end, 'yyyy-MM-dd'),
          deleted: 0,
          canceled: 0,
          excludeTypes: 'Assessment,Messaging',
          providerId: selectedProviderId,
          limit: 700,
        })
          .then((rawEvents) => {
            const ids = rawEvents.map(({ id }) => id);
            const uniqueEvents = rawEvents.filter(
              ({ id }, index) => ids.indexOf(id) === index
            );

            successCallback(createEventsFromSchedule(uniqueEvents, timeZone));
          })
          .catch(failureCallback);
      },
      eventColor: green[900],
    }),
    [selectedProviderId]
  );

  const eventSources = React.useMemo<EventSourceInput[]>(
    () => [
      newAppointmentsEventSource,
      unavailabilityEventSource,
      providerScheduleEventSource,
    ],
    [
      newAppointmentsEventSource,
      unavailabilityEventSource,
      providerScheduleEventSource,
    ]
  );

  return { eventSources };
}
