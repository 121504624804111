import * as React from 'react';

import { api } from '#services/http-clients.ts';
import { useRefreshTokenStore } from '#stores/refresh-token.ts';

/**
 * Dummy component to keep alive the session by "polling" an access token when
 * needed. We perform this logic here instead of in the `Session` component to
 * avoid re-rendering it any time the expiration date changes.
 */
export function KeepAlive() {
  const expiresAt = useRefreshTokenStore((state) => state.expiresAt);

  React.useEffect(() => {
    if (!expiresAt) return;

    const timeoutId = window.setTimeout(() => {
      /**
       * Ignore, we just need to refresh the token without doing anything with
       * the response.
       */
      api.get('/user/me').catch(() => void 0);
    }, expiresAt - Date.now());

    return () => window.clearTimeout(timeoutId);
  }, [expiresAt]);

  return null;
}
