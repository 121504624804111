import type { ReactDatePickerProps } from 'react-datepicker';
import type { DateRange, Range } from '#types/dates';

import * as React from 'react';
import { styled } from '@mend/mui-theme';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { Button, Popover, Tooltip } from '@mui/material';
import { format, min } from 'date-fns';
import DatePicker from 'react-datepicker';

import useAnchorElement from '#hooks/anchor-element';
import { allRangesList, defaultRanges, getDatesByRange } from '#utils/dates';
import renderCustomHeader from '../custom-header/render-custom-header';
import styles from './DateRangePicker.module.css';

/**
 * Styles taken from a small search bar input.
 */
const Trigger = styled(Button)(() => ({
  height: '40px',
  padding: '7px 14px',
  fontWeight: 500,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  color: 'rgba(0, 0, 0, 0.87)',
  '&:hover': {
    borderColor: 'rgba(0, 0, 0, 0.87)',
  },
}));

const formatRange = (start: Date, end: Date, dateFormat: string) =>
  `${format(start, dateFormat)} - ${format(end, dateFormat)}`;

// Add more "native" ReactDatePickerProps as needed
export type DateRangePickerProps = Pick<
  ReactDatePickerProps,
  'minDate' | 'maxDate' | 'locale' | 'disabled'
> & {
  range: DateRange;
  ranges?: Range[];
  dateFormat?: string;
  onRangeChange: (range: DateRange) => void;
  /**
   * Receives the start date of the current range selection as a parameter in
   * order to return another date that could serve as the `maxDate`.
   *
   * Needs to have a stable reference.
   */
  getMaxDateDuringSelection?: (start: Date) => Date;
};

// TODO replace implementation with mui's once stable
export default function DateRangePicker({
  range: controlledRange,
  ranges = defaultRanges,
  disabled = false,
  dateFormat = 'MM/dd/yyyy',
  onRangeChange,
  getMaxDateDuringSelection,
  minDate,
  maxDate,
  locale,
}: DateRangePickerProps): JSX.Element {
  const { anchor, setAnchor, unsetAnchor, open } = useAnchorElement();
  const [range, setRange] = React.useState<DateRange>({
    id: 'custom',
    start: null,
    end: null,
  });
  const cancelButtonRef = React.useRef<HTMLButtonElement>(null);

  /**
   * In case we have both a `maxDate` and a `getMaxDateDuringSelection`, return
   * the earliest date.
   */
  const actualMaxDate = React.useMemo(() => {
    const { id, start, end } = range;
    if (id === 'custom' && start && !end && getMaxDateDuringSelection) {
      const filteredDates = [getMaxDateDuringSelection(start), maxDate].filter(
        Boolean
      ) as Date[];
      return min(filteredDates);
    }
    return maxDate;
  }, [range, getMaxDateDuringSelection, maxDate]);

  const filteredRangesList = React.useMemo(
    () => allRangesList.filter(({ id }) => ranges.includes(id)),
    [ranges]
  );

  React.useEffect(() => {
    setRange({ ...controlledRange });
  }, [controlledRange]);

  const derivedRange = React.useMemo<Omit<DateRange, 'id'>>(() => {
    const { id, ...dates } = controlledRange;
    if (id === 'custom') {
      return dates;
    }
    const [start, end] = getDatesByRange(id);
    return { start, end };
  }, [controlledRange]);

  // const handleOpenAutoFocus = (event: Event) => {
  //   if (range.id === 'custom') {
  //     event.preventDefault();
  //     cancelButtonRef.current?.focus();
  //   }
  // };

  const handleRangeClick = (id: Range): void => {
    const [start, end] = getDatesByRange(id);
    if (id !== 'custom') {
      onRangeChange({ id, start, end });
      unsetAnchor();
    } else {
      setRange({ id, start, end });
    }
  };

  const handleDatePickerChange = ([start, end]: [
    Date | null,
    Date | null,
  ]): void => {
    setRange({ id: 'custom', start, end });
  };

  const handleDatePickerApply = (): void => {
    onRangeChange(range);
    unsetAnchor();
  };

  return (
    <>
      <Tooltip title="Select Date Range">
        <span>
          <Trigger
            disableElevation
            type="button"
            variant="outlined"
            disabled={disabled}
            onClick={setAnchor}
            startIcon={<CalendarTodayOutlined />}
            data-testid="custom-date-range-picker-trigger"
            data-tag-id="date-range"
          >
            {!derivedRange.start || !derivedRange.end
              ? 'No dates selected'
              : formatRange(derivedRange.start, derivedRange.end, dateFormat)}
          </Trigger>
        </span>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={unsetAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div
          role="dialog"
          aria-modal="true"
          aria-label="Date Range Picker"
          className={styles.wrapper}
        >
          <ul className={styles.ranges}>
            {filteredRangesList.map(({ id, label }) => (
              <li key={id} className={styles.range}>
                <Button
                  fullWidth
                  variant={range.id === id ? 'contained' : 'text'}
                  sx={{ whiteSpace: 'nowrap', justifyContent: 'flex-start' }}
                  onClick={() => handleRangeClick(id)}
                >
                  {label}
                </Button>
              </li>
            ))}
          </ul>

          {range.id === 'custom' && (
            <>
              <div
                className={styles.calendars}
                data-testid="custom-range-calendars"
              >
                <DatePicker
                  calendarClassName="custom-date-range-picker"
                  monthsShown={2}
                  selected={range.start}
                  startDate={range.start}
                  endDate={range.end}
                  onChange={handleDatePickerChange}
                  minDate={minDate}
                  maxDate={actualMaxDate}
                  locale={locale}
                  renderCustomHeader={(params) =>
                    renderCustomHeader({ ...params, isSingle: false })
                  }
                  selectsRange
                  inline
                />
              </div>

              <div className={styles.footer}>
                {range.start && range.end && (
                  <span data-testid="custom-range-preview">
                    {formatRange(range.start, range.end, dateFormat)}
                  </span>
                )}
                <div className={styles.footerActions}>
                  <Button
                    ref={cancelButtonRef}
                    autoFocus
                    variant="outlined"
                    onClick={unsetAnchor}
                  >
                    Dismiss
                  </Button>
                  <Button
                    disabled={!range.start || !range.end}
                    onClick={handleDatePickerApply}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Popover>
    </>
  );
}
