import type { IconButtonProps } from '@mui/material';

import { CloseOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';

type SnackbarCloseButtonProps = Pick<IconButtonProps, 'onClick'>;

export function SnackbarCloseButton({ onClick }: SnackbarCloseButtonProps) {
  return (
    <IconButton
      size="small"
      aria-label="Close"
      color="inherit"
      onClick={onClick}
    >
      <CloseOutlined fontSize="small" />
    </IconButton>
  );
}
