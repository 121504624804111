import type { User } from '#services/api/user.ts';
import type { Appointment } from '#types/appointment.ts';

import { grey } from '@mend/mui-theme/colors';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Stack,
} from '@mui/material';
import { format } from 'date-fns';

import { WidgetCard } from '#routes/Session/WidgetCard.tsx';
import { convertUtcStringToLocalDate } from '#utils/dates.ts';
import * as Fields from './Fields.tsx';
import { UserInformation } from './UserInformation.tsx';

interface PatientInformationProps {
  appointment: Appointment;
  users: User[];
}

export function InformationPanel({
  appointment,
  users,
}: PatientInformationProps) {
  const subject =
    users.length === 1
      ? `${users[0]!.firstName} ${users[0]!.lastName} (${users[0]!.role})`
      : appointment.group?.name || 'Group';

  return (
    <WidgetCard
      title={subject}
      fullHeight
      data-testid="infoPanel"
      subHeaderSlot={
        <Stack>
          <Chip
            size="small"
            label={`${format(
              convertUtcStringToLocalDate(appointment.startDate),
              'h:mm'
            )} - ${format(
              convertUtcStringToLocalDate(appointment.endDate),
              'h:mm a'
            )}`}
            sx={{
              color: 'white',
              backgroundColor: grey[600],
            }}
          />

          <Chip
            size="small"
            label={appointment.appointmentType?.name}
            sx={{
              color: '#050505',
              backgroundColor: grey['A100'],
            }}
          />
        </Stack>
      }
    >
      {appointment.groupId ? (
        <>
          <Fields.Container sx={{ mb: 3 }}>
            <Fields.Provider provider={appointment.provider} />
            <Fields.AppointmentID id={appointment.id} />
            <Fields.DirectVideoLink />
          </Fields.Container>

          {users.map((user) => (
            <Accordion key={user.id} disableGutters>
              <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                {`${user.firstName} ${user.lastName}`}
              </AccordionSummary>
              <AccordionDetails>
                <UserInformation user={user} appointment={appointment} />
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ) : (
        <UserInformation user={users[0]!} appointment={appointment} />
      )}
    </WidgetCard>
  );
}
