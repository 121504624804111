import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { currentTimezone } from '#utils/dates';
import {
  getMappedUserTimezone,
  getTimezoneFromInputValueWithSuffix,
} from '#utils/timezones.ts';

const TimezoneFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'start',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  padding: `${theme.spacing(3)} 0`,
}));

type SlotLengthProps = {
  timezone: string;
  onTimezoneChange: (timezone: string) => void;
  patientTimezone: string;
  providerTimezone: string;
};

export default function Timezone({
  timezone,
  onTimezoneChange,
  patientTimezone,
  providerTimezone,
}: SlotLengthProps): JSX.Element {
  return (
    <TimezoneFormControl fullWidth>
      <FormLabel data-testid="timezone">
        <Stack>
          <Typography>Timezone:</Typography>
          <Typography color="primary.main">
            {getMappedUserTimezone(
              getTimezoneFromInputValueWithSuffix(timezone)
            )}
          </Typography>
        </Stack>
      </FormLabel>
      <RadioGroup
        row
        value={timezone}
        onChange={(event) => onTimezoneChange(event.target.value)}
        aria-label="Timezones"
      >
        <FormControlLabel
          value={`${currentTimezone}-mine`}
          control={<Radio />}
          label="Your current timezone"
        />
        <FormControlLabel
          value={`${patientTimezone}-patient`}
          control={<Radio />}
          label="Patient timezone"
        />
        <FormControlLabel
          value={`${providerTimezone}-provider`}
          control={<Radio />}
          label="Provider timezone"
        />
      </RadioGroup>
    </TimezoneFormControl>
  );
}
