import type { Appointment } from '#types/appointment.ts';
import type { Range } from '#types/dates';

import {
  addDays,
  addHours,
  format,
  isMatch,
  isValid,
  isWeekend,
  isWithinInterval,
  lastDayOfMonth,
  startOfMonth,
  subDays,
  subHours,
  subMonths,
} from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

export function convertUtcStringToLocalDate(
  dateString: string | undefined
): Date {
  if (!dateString || !isValid(new Date(dateString))) {
    return new Date();
  }
  return isMatch(dateString, 'yyyy-MM-dd HH:mm:ss')
    ? new Date(`${dateString.replace(' ', 'T')}Z`)
    : new Date(dateString);
}

export function getDatesByRange(id: Range): [Date | null, Date | null] {
  const today = new Date();
  if (id === 'today') {
    return [today, today];
  } else if (id === 'tomorrow') {
    const tomorrow = addDays(today, 1);
    return [tomorrow, tomorrow];
  } else if (id === 'yesterday') {
    const yesterday = subDays(today, 1);
    return [yesterday, yesterday];
  } else if (id === 'last-7') {
    return [subDays(today, 6), today];
  } else if (id === 'next-7') {
    return [today, addDays(today, 6)];
  } else if (id === 'last-30') {
    return [subDays(today, 29), today];
  } else if (id === 'this-month') {
    return [startOfMonth(today), lastDayOfMonth(today)];
  } else if (id === 'last-month') {
    const todayLastMonth = subMonths(today, 1);
    return [startOfMonth(todayLastMonth), lastDayOfMonth(todayLastMonth)];
  } else {
    // Custom
    return [null, null];
  }
}

type RangeListItem = {
  id: Range;
  label: string;
};

export const allRangesList: RangeListItem[] = [
  { id: 'today', label: 'Today' },
  { id: 'tomorrow', label: 'Tomorrow' },
  { id: 'yesterday', label: 'Yesterday' },
  { id: 'last-7', label: 'Last 7 Days' },
  { id: 'next-7', label: 'Next 7 Days' },
  { id: 'last-30', label: 'Last 30 Days' },
  { id: 'this-month', label: 'This Month' },
  { id: 'last-month', label: 'Last Month' },
  { id: 'custom', label: 'Custom Range' },
];

// TODO update once default list is defined
export const defaultRanges = allRangesList.map(({ id }) => id);

export const formatUTCStrToDayTime = (
  strDate?: string,
  firstValue: 'day' | 'time' = 'day'
): string => {
  if (!strDate) return '';
  const [time, day] = format(
    convertUtcStringToLocalDate(strDate),
    'h:mmaaa MM/dd/yy'
  ).split(' ') as [string, string];

  return firstValue === 'day' ? `${day} ${time}` : `${time} ${day}`;
};

export const formatUTCStringWithTimezone = (
  timeString: string,
  timezone: string,
  format = "yyyy-MM-dd'T'HH:mm:ssXXX"
): string => {
  return formatInTimeZone(
    convertUtcStringToLocalDate(timeString),
    timezone,
    format
  );
};

export const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function isWithinDayTime(
  startTime: string,
  endTime: string,
  current: Date = new Date()
) {
  function getDateWithProperTime(time: string): Date {
    const [hours, minutes] = time.split(':') as [string, string];
    const date = new Date();
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  const start = getDateWithProperTime(startTime);
  const end = getDateWithProperTime(endTime);
  const currentTime = current.getTime();

  return currentTime >= start.getTime() && currentTime <= end.getTime();
}

type Time = string; // Format is HH:mm

type Day = {
  day: number; // 1 - Monday / 7 - Sunday
  display: string;
  start: Time;
  end: Time;
};

const SCHEDULE_EVERYDAY = 0;
const SCHEDULE_WEEKDAYS = 1;
const SCHEDULE_CUSTOM = 2;

export type Schedule =
  | {
      day: 0 | 1; // 0 = Everyday, 1 = Weekdays
      start: Time;
      end: Time;
    }
  | {
      day: 2; // 2 = Custom
      days: Day[];
    };

export function isWithinSchedule(schedule: Schedule): boolean {
  const now = new Date();
  if (
    schedule.day === SCHEDULE_EVERYDAY ||
    schedule.day === SCHEDULE_WEEKDAYS
  ) {
    if (schedule.day === SCHEDULE_WEEKDAYS && isWeekend(now)) {
      return false;
    }
    return isWithinDayTime(schedule.start, schedule.end, now);
  } else if (schedule.day === SCHEDULE_CUSTOM) {
    /**
     * `.getDay()` returns the number of the day in the week starting with 0
     * for Sunday and 6 for Saturday, in the properties we store the schedule
     * starting with 1 for Monday and 7 for Sunday so we have to reorder the
     * elements to access the schedule of the day based on the value returned
     * by `getDay()`.
     */
    const orderedDays = [
      ...schedule.days.slice(-1),
      ...schedule.days.slice(0, -1),
    ];
    const todaySchedule = orderedDays[now.getDay()];
    if (!todaySchedule) {
      return false;
    }
    return isWithinDayTime(todaySchedule.start, todaySchedule.end);
  } else {
    // Should never be reached but just in case...
    return true;
  }
}

export function isAppointmentWithinRange(
  appointment: Appointment,
  hoursBeforeNow: number,
  hoursAfterNow: number
): boolean {
  const now = new Date();

  const startTimeThreshold = subHours(now, hoursBeforeNow);
  const endTimeThreshold = addHours(now, hoursAfterNow);

  const appointmentStart = utcToZonedTime(
    new Date(appointment.startDate),
    currentTimezone
  );
  const appointmentEnd = utcToZonedTime(
    new Date(appointment.endDate),
    currentTimezone
  );

  return (
    isWithinInterval(appointmentStart, {
      start: startTimeThreshold,
      end: endTimeThreshold,
    }) &&
    isWithinInterval(appointmentEnd, {
      start: startTimeThreshold,
      end: endTimeThreshold,
    })
  );
}
