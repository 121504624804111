import type { Row } from 'react-table';

import { matchSorter } from './match-sorter';

/**
 * Taken directly from match-sorter's docs.
 * @see https://github.com/kentcdodds/match-sorter#match-many-words-across-multiple-fields-table-filtering
 */
export default function fuzzySearchMultipleWords<
  T extends Record<string, unknown>,
>(rows: Row<T>[], keys: string[], text: string): Row<T>[] {
  if (!text || !text.length) return rows;

  const terms = text.split(' ');
  if (!terms) return rows;

  // reduceRight will mean sorting is done by score for the _first_ entered word.
  return terms.reduceRight(
    (results, term) => matchSorter(results, term, { keys }),
    rows
  );
}
