import { create } from 'zustand';

type RefreshTokenStore = {
  /**
   * The expiration date of the access token in milliseconds.
   */
  expiresAt: number | null;
  /**
   * Set the expiration date of the access token in milliseconds.
   */
  setExpiresAt: (value: number) => void;
};

/**
 * Store used to keep track of the expiration date of the access token.
 */
export const useRefreshTokenStore = create<RefreshTokenStore>()((set) => ({
  expiresAt: null,
  setExpiresAt: (value) => set({ expiresAt: value }),
}));
