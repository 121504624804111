import { getCookie, removeCookie, setCookie } from './cookies';

const supportedLanguages = [
  { code: 'ar_AE', shortCode: 'ar', displayName: 'Arabic' },
  { code: 'zh_CN', shortCode: 'zh', displayName: 'Chinese' },
  { code: 'en_US', shortCode: 'en', displayName: 'English' },
  { code: 'fr_FR', shortCode: 'fr', displayName: 'French' },
  { code: 'ht_HT', shortCode: 'ht', displayName: 'Haitian Creole' },
  { code: 'ne_NP', shortCode: 'ne', displayName: 'Nepali' },
  { code: 'pt_PT', shortCode: 'pt', displayName: 'Portuguese' },
  { code: 'es_ES', shortCode: 'es', displayName: 'Spanish' },
] as const;

export type MendLanguageShortCode =
  (typeof supportedLanguages)[number]['shortCode'];

const LANG_COOKIE = 'langPref';

/**
 * Validates the short code is one of the supported languages.
 */
export function validateShortCode(
  shortCode: string | undefined,
  defaultShortCode: MendLanguageShortCode
): MendLanguageShortCode;

export function validateShortCode(
  shortCode: string | undefined,
  defaultShortCode?: MendLanguageShortCode
): MendLanguageShortCode | undefined;

export function validateShortCode(
  shortCode: string | undefined,
  defaultShortCode?: MendLanguageShortCode
): MendLanguageShortCode | undefined {
  if (!shortCode) return defaultShortCode;
  return supportedLanguages.some((lang) => lang.shortCode === shortCode)
    ? (shortCode as MendLanguageShortCode)
    : defaultShortCode;
}

export function syncLanguageCookie(code: string): void {
  const shortCode =
    supportedLanguages.find((lang) => lang.code === code)?.shortCode ?? 'en';
  const langPref = validateShortCode(getCookie(LANG_COOKIE));

  if (shortCode === 'en') {
    if (!langPref) return;
    removeCookie(LANG_COOKIE);
    /**
     * `return` not strictly necessary but it helps narrowing the type of
     * `shortCode` in the next if statement.
     */
    return window.location.reload();
  }

  if (!langPref || langPref !== shortCode) {
    setCookie(LANG_COOKIE, shortCode);
    window.location.reload();
  }
}

export function getReadableLanguageFromCookie(
  cookieName = LANG_COOKIE
): (typeof supportedLanguages)[number]['displayName'] {
  const langPref = getCookie(cookieName);
  return (
    supportedLanguages.find((lang) => lang.shortCode === langPref)
      ?.displayName ?? 'English'
  );
}
