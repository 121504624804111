import type { TextFieldProps } from '@mui/material';

import { MenuItem, TextField } from '@mui/material';

type RepeatProps = Omit<
  TextFieldProps,
  'margin' | 'onChange' | 'select' | 'value'
> & {
  value: number;
  onChange: (value: number) => void;
};

const options = Array.from({ length: 30 }, (_, i) => i + 1);

export default function Repeat({
  fullWidth = true,
  id = 'repeat',
  name = 'repeat',
  label = 'Repeat',
  onChange,
  ...props
}: RepeatProps): JSX.Element {
  return (
    <TextField
      select
      fullWidth={fullWidth}
      id={id}
      name={name}
      label={label}
      onChange={(e) => onChange(Number(e.target.value))}
      {...props}
    >
      {options.map((number) => (
        <MenuItem key={number} value={number}>
          {`${number === 1 ? `${number} Time` : `${number} Times`}`}
        </MenuItem>
      ))}
    </TextField>
  );
}
